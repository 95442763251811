import React from "react";
import annieVideo from "../../assets/videos/4D. Headache.mp4"; // Make sure to replace this with the actual path to your video

const AnnieHelpPage = () => {
  return (
    <div className="flex flex-col items-center p-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-4">Annie Needs Your Help</h2>
      <p className="text-xl mb-6 text-center">
        Play the video to find out which body part is hurting Annie and how she is feeling.
      </p>

      {/* Video Player */}
      <video
        src={annieVideo}
        controls
        className="w-full max-w-md h-auto rounded-lg shadow-lg"
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default AnnieHelpPage;
