import React from "react";
import drReily from "../../assets/Characters/Riley.png"; // Doctor image
import pointingImage from "../../assets/Characters/pointing.png"; 
import bodyMapImage from "../../assets/Characters/bodyMap.png"; 

const HealthSuperheros2 = () => {
  return (
    <div className="flex flex-col items-center p-8 max-w-4xl mx-auto">
      <div className="flex flex-col lg:flex-row items-center gap-8">
        {/* Left Section - Doctor Image */}
        <div className="flex justify-center lg:w-1/3">
          <img
            src={drReily}
            alt="Dr. Riley"
            className="w-full max-w-xs rounded-lg shadow-md"
          />
        </div>

        {/* Right Section - Information */}
        <div className="lg:w-2/3 text-center lg:text-left font-gillSans">
          <h2 className="text-3xl font-bold mb-4">Health Superheroes</h2>
          <p className="text-xl mb-4">
            When your body part hurts you can show the doctor by:
          </p>
          <ol className="list-decimal text-xl pl-6 space-y-3">
            <li>Pointing to the body part that hurts.</li>
            <li>Or using the Body Map to show where it hurts.</li>
          </ol>
          
          {/* Images for Options */}
          <div className="flex justify-center lg:justify-start gap-4 mt-4">
            <img
              src={pointingImage}
              alt="Point to the body part"
              className="w-56 h-auto cursor-pointer rounded-md shadow-md hover:scale-105 transition-transform duration-200"
            />
            <img
              src={bodyMapImage}
              alt="Use the Body Map"
              className="w-56 h-auto cursor-pointer rounded-md shadow-md hover:scale-105 transition-transform duration-200"
            />
          </div>
          
          <p className="text-xl mt-4">
            The doctor might touch your body part that hurts to <br/> find out why it hurts.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HealthSuperheros2;
