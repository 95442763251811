import React from "react";
import coinIcon from "../../assets/Icons/Coin.png"; // Coin icon path
import doctorOfficeVideo from "../../assets/videos/DoctorsOffice.mp4"; // Video path

const ExploreDoctorOffice = () => {
  return (
    <div className="flex flex-col items-center p-8 max-w-4xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between w-full items-center mb-4">
        <h2 className="text-3xl font-bold">Exploring the Doctor’s Office</h2>
        <div className="flex items-center space-x-2">          
          <img src={coinIcon} alt="Coin Icon" className="w-12 ml-4 heartbeat" />
        </div>
      </div>

      {/* Video Section */}
      <div className="w-full flex justify-center">
        <video
          src={doctorOfficeVideo}
          controls
          className="w-full max-w-xl rounded-lg shadow-lg"
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default ExploreDoctorOffice;
