import React from "react";
import Captain from "../../../assets/cptCharlie.png";

const End = () => {
  return (
    <>
      <div className=" relative w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <div className=" flex flex-row items-center justify-evenly mt-10">
          <div>
            <img src={Captain} className=" w-72" alt="captain" />
          </div>
          <div>
            <h2 className="text-3xl font-bold text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-5xl font-gillSans">
              Excellent Job!
            </h2>
            <ol className=" font-gillSans text-center text-base">
              <li className="mt-5 text-xl">Well done on filling up your pain diary.</li>
              <li className="mt-5 text-xl">
                Remember to come back tomorrow to fill in your pain diary.
              </li>
              <li className="mt-5 text-xl">See you tomorrow!</li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default End;
