import React from "react";
import mission1 from "../../../assets/Pain Badges/mission1.png";
import mission2 from "../../../assets/Pain Badges/mission2.png";
import mission3 from "../../../assets/Pain Badges/mission3.png";
import mission4 from "../../../assets/Pain Badges/mission4.png";
import mission5 from "../../../assets/Pain Badges/mission5.png";
import mission6 from "../../../assets/Pain Badges/mission6.png";
import mission7 from "../../../assets/Pain Badges/mission7.png";
import mission8 from "../../../assets/Pain Badges/mission8.png";
import mission9 from "../../../assets/Pain Badges/mission9.png";
import mission10 from "../../../assets/Pain Badges/mission10.png";
import mission11 from "../../../assets/Pain Badges/mission11.png";
import mission12 from "../../../assets/Pain Badges/mission12.png";
import mission13 from "../../../assets/Pain Badges/mission13.png";
import copingChampion from "../../../assets/Pain Badges/Coping Champion.png";
import docVisitChampion from "../../../assets/Pain Badges/Doctor Visit Champion.png";

const PainBadges = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-5xl bg-transparent md:w-[90%] lg:h-96 xl:h-1/2 fadeIn">
      <div>
        <h2 className="text-xl font-bold text-center text-[#267e8c] md:text-2xl lg:text-4xl font-gillSans">
          Pain Badges
        </h2>
        <div className="text-gray-700 text-base mt-10 font-gillSans">
          <div className="flex mt-2 justify-between gap-4 flex-wrap">
            <div className="flex items-center flex-col w-28">
              <img
                src={mission1}
                alt="mission1"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 text-center font-gillSans">
                Body Parts Expert
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission2}
                alt="mission2"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Body Map Expert
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission3}
                alt="mission3"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Communicating Pain Master
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission4}
                alt="mission4"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Pain Scale Champion
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission5}
                alt="master-at-arms"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Pain Communication Expert
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission6}
                alt="mission6"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Doctor Talk Expert
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission7}
                alt="mission7"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Belly Breathing Champion
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission8}
                alt="third-mate"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Mastering Attention Champion
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission9}
                alt="second-mate"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                WICDTFB Champion
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission10}
                alt="first-mate"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Special Skills Champion
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission11}
                alt="quarter-master"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Brave Patient Expert
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission12}
                alt="mission12"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">Captain</span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={copingChampion}
                alt="coping champion"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Coping Champion
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={mission13}
                alt="mission13"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Feeling Better Master
              </span>
            </div>
            <div className="flex items-center flex-col w-28">
              <img
                src={docVisitChampion}
                alt="doc visit champion"
                className="hover:scale-110 transform transition duration-300 ease-in-out "
              />
              <span className="mt-2 text-center font-gillSans">
                Doctor Visit Champion
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainBadges;
