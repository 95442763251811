import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Admin from "./pages/Auxiliary Pages/Admin/AdminDashboard";
import Information from "./pages/Auxiliary Pages/Information/Information";
import PainDiary from "./pages/Auxiliary Pages/Pain Diary/PainDiary";
import Parents from "./pages/Auxiliary Pages/Parents/Parents";
import Resources from "./pages/Auxiliary Pages/Resources/Resources";
import ErrorBoundary from "./pages/ErrorBoundary";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Map from "./pages/Map";
import Mission1 from "./pages/mission1/mission1";
import Mission10 from "./pages/mission10/mission10";
import Mission11 from "./pages/mission11/mission11";
import Mission12 from "./pages/mission12/mission12";
import Mission2 from "./pages/mission2/mission2";
import Mission3 from "./pages/mission3/mission3";
import Mission4 from "./pages/mission4/mission4";
import Mission5 from "./pages/mission5/mission5";
import Mission6 from "./pages/mission6/mission6";
import Mission7 from "./pages/mission7/mission7";
import Mission8 from "./pages/mission8/mission8";
import Mission9 from "./pages/mission9/mission9";
import SignUp from "./pages/SignUp";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/home",
    element: <HomePage />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/pain-diary",
    element: <PainDiary />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/map",
    element: <Map />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/information",
    element: <Information />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/resources",
    element: <Resources />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/admin",
    element: <Admin />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/parents",
    element: <Parents />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  // Declaring routes for each missions component
  {
    path: "/mission1",
    element: <Mission1 />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/mission2",
    element: <Mission2 />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/mission3",
    element: <Mission3 />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/mission4",
    element: <Mission4 />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/mission5",
    element: <Mission5 />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/mission6",
    element: <Mission6 />,
    errorElement: <ErrorPage errorCode="500" errorMessage="Something went wrong" />,
  },
  {
    path: "/mission7",
    element: <Mission7 />,
  },
  {
    path: "/mission8",
    element: <Mission8 />,
  },
  {
    path: "/mission9",
    element: <Mission9 />,
  },
  {
    path: "/mission10",
    element: <Mission10 />,
  },
  {
    path: "/mission11",
    element: <Mission11 />,
  },
  {
    path: "/mission12",
    element: <Mission12 />,
  },
  {
    path: "*",
    element: <ErrorPage errorCode="404" errorMessage="Page not found" />,
  },
]);

function App() {
  return (
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
}

export default App;
