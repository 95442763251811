import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import PainDiaryBoard from "../../assets/Boards/painDiaryBoard.png";

const EndMission3 = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20 ml-5">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Mission this week
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans">
        <div>
          <p className=" mt-5 lg:text-lg">
            Captain Charlie has set you a mission for the week. Every day you are asked to fill in your pain diary.            
          </p>
          <div>
            <img src={PainDiaryBoard} className="ml-14 w-24" />
          </div>
          <p className=" ml-2 lg:text-lg">
            If a body part hurts click on body part picture. If no body part
            hurt click on the red button.{" "}
            <button
              className="bg-red-500 text-white cursor-default text-base font-bold py-2 px-4 rounded-lg mt-4 mr-8"
            >
              I had no pain this week
            </button>
          </p>
          <p className=" ml-2 mt-4 lg:text-lg">
            Captain Charlie has an extra job for you to do. Find out what it is.
          </p>
        </div>
        <div>
          <img src={cptCharlie} alt="cptCharlie" className="w-9/12 mt-20" />
        </div>
      </div>
    </div>
  );
};

export default EndMission3;
