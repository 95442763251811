import React from "react";

const ChronicPain = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Chronic Pain
      </h2>
      <div className="text-base mx-5 font-gillSans">
        <ul>
          <li className=" mt-8 ">
            Chronic pain is pain that lasts for 3 months or more.
          </li>
          <li className=" mt-8 ">
            It can make doing things like taking part in school or everyday
            functioning quite difficult.
          </li>
          <li className=" mt-8 ">
            Current research has reported that autistic children showed higher
            rates of pain compared to their peer.
          </li>
          <li className=" mt-8 ">
            Chronic pain is an important healthcare problem in Ireland, Europe
            and across the world.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChronicPain;
