import React from "react";
import painScale from "../../assets/pain scale/Pain scale.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const LearningToCommunicate = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Learning to Communicate
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl">
          <li className="mt-4">
            Pointing to different faces helps your mum/dad or teacher know when
            you are hurting.
          </li>
          <li className="mt-4">
            When you show them, it hurts, they can help you feel better.
          </li>
          <li className="mt-4">
            Using a pain scale helps your mum and dad to know if you need to go
            to the doctors and what they can do to help
          </li>
          <img src={painScale} alt="painScale" className=" w-96 ml-39 mt-6" />
        </ol>
      </div>
    </div>
  );
};

export default LearningToCommunicate;
