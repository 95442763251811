import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import PainDiaryBoard from "../../assets/Boards/painDiaryBoard.png";

const KeepUp = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="text-gray-700 text-base flex flex-row font-gillSans">
        <div>
          <img src={cptCharlie} alt="cptCharlie" className=" w-80" />
        </div>
        <div>
          <ol>
            <li className="mt-10 lg:text-xl">
              Keep up the good work on filling in your pain diary every day.
            </li>
            <li className="mt-10 lg:text-xl">
              It's very important to do this every day!
            </li>
            <li>
              <img src={PainDiaryBoard} className="ml-14 w-32" />
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default KeepUp;
