import React, { useState, useEffect } from "react";
import stomachPain from "../../assets/pain scale/Point to stomach.png";
import bodyImage from "../../assets/pain scale/Point to map.png";
import iPad from "../../assets/iPad.png";
import runningAway from "../../assets/Characters/runningAway.png";
import painScale from "../../assets/pain scale/Pain scale.png";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const FinalQuiz = ({
  carrier,
  setTask4Completed,
  handleSpeech,
  submitted,
  setSubmitted,
}) => {
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [result, setResult] = useState(null);
  const [incorrectAttempts, setincorrectAttempts] = useState(0); // State for incorrect attempts

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  useEffect(() => {
    if (result === "correct") {
      handleSubmit();
      audioPlay();
      sessionStorage.setItem("pQ3Completed", "true");
    }
  }, [result, carrier]);

  const handleImageClick = (index) => {
    setSelectedIndices((prev) => {
      const newSelection = prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index].slice(0, 3);

      return newSelection;
    });
  };

  const handleSubmit = async () => {
    const correctIndices = [2, 3, 4];
    const isCorrect = correctIndices.every((val) =>
      selectedIndices.includes(val)
    );

    if (isCorrect) {
      setResult("correct");
      setTask4Completed(true);
      handleSpeech(34.1);
      await saveActivity(); // Call the API if the answer is correct
    } else {
      setincorrectAttempts((prev) => prev + 1); // Increment counter on incorrect attempt
      setResult("incorrect");
      handleSpeech(39.2);
      // After 3 incorrect attempts, automatically highlight correct indices
      if (incorrectAttempts > 2) {
        // >=2 because we're incrementing attempts right above
        setSelectedIndices(correctIndices); // Highlight the correct indices
        await saveActivity();
      }
    }

    // Reset selections after submission if the attempts are not exhausted
    if (incorrectAttempts < 3) {
      setSelectedIndices([]);
    }

    // Reset the result after a brief delay (optional, depending on your UX design)
    setTimeout(() => {
      setResult(null);
    }, 2500);
  };

  const saveActivity = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M3",
          pageNum: 32,
          activityId: "M3Quiz",
          activityValue: JSON.stringify({
            question: "Quiz",
            result: incorrectAttempts <= 3 ? "correct" : "incorrect",
          }),
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const placeholders = [
    { src: runningAway, label: "Running away", widthClass: "w-44" },
    { src: iPad, label: "Playing on my iPad", widthClass: "w-36 mt-3" },
    { src: bodyImage, label: "Pointing to body map", widthClass: "w-32" },
    {
      src: stomachPain,
      label: "Pointing to the body part that hurts",
      widthClass: "w-32",
    },
    {
      src: painScale,
      label: "Using a pain scale",
      widthClass: "w-48 mb-12 mt-6",
    },
  ];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Pirate Quiz
          <img src={coin} className="w-12 ml-5 heartbeat" alt="coin" />
        </h2>
      </div>
      <div className="mr-5 mt-6 text-gray-700 text-base font-gillSans">
        <ol className="text-xl">
          <li className="mt-1">
            Captain Charlie wants to know the different ways you can communicate
            pain?
          </li>
          <li className="mt-1">
            Select the different ways to communicate pain?
          </li>
          <div className="flex mt-5 justify-around items-center gap-5 max-w-3xl">
            {placeholders.map((placeholder, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={placeholder.src}
                  alt={`pain-scale-${index}`}
                  className={`hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer max-h-32 ${
                    placeholder.widthClass
                  } ${
                    selectedIndices.includes(index)
                      ? "border-4 border-[#9c6334] rounded"
                      : ""
                  }`}
                  onClick={() => handleImageClick(index)}
                />
                <span className="text-center mt-5">{placeholder.label}</span>
              </div>
            ))}
          </div>

          {/* Render components based on the result */}
          {result === "correct" && (
            <div className=" bounceIn text-center mt-5">
              <h2 className="text-green-600 text-2xl">Correct Answer!</h2>
              <ol>
                <li>Excellent Job {carrier.firstName}</li>
                <li>Keep up the hard work.</li>
              </ol>
            </div>
          )}
          {incorrectAttempts >= 3 ? (
            // Message After Exhausting 3 Attempts
            <div className="text-center mt-4 text-lg font-semibold text-red-500">
              The correct answer is highlighted in boxes.
            </div>
          ) : (
            // Incorrect Answer Message
            result === "incorrect" && (
              <div className="flex flex-col items-center justify-center text-center">
                <div className="shake text-center mt-8">
                  <h4 className="text-red-600">Incorrect Answer! Attempts left: {3 - incorrectAttempts} / 3</h4>
                </div>
              </div>
            )
          )}
          {/* Submit Button */}
          <div className="flex justify-center mt-5">
            <button
              onClick={handleSubmit}
              className="px-4 py-2 text-white rounded bg-green-500 hover:bg-green-700 transition"
            >
              Submit
            </button>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default FinalQuiz;
