import React, { useState, useEffect } from "react";
import MapImage from "../assets/MapImage.jpg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import blueMapBG from "../assets/blueMapBG.jpeg";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const Map = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [carrier, setCarrier] = useState(location.state?.carrier);
  const [loading, setLoading] = useState(true);

  // Call the new API to fetch user progress only if necessary
  useEffect(() => {
    const isMissionCompleted = sessionStorage.getItem("isMissionCompleted");
    console.log("isMissionCompleted: ", isMissionCompleted);
    if (isMissionCompleted === "true" && carrier?.userId) {
      fetchUserProgress(); // Call the API to fetch updated user progress
    }else{
      setLoading(false);
    }
  }, []);

  const fetchUserProgress = async () => {      
      console.log("Map Page carrier is not fresh: ", carrier);
      try {
        // Call the new API to fetch the user's progress
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}user/progress/${carrier.userId}`
        );

        if (response.status === 200) {
          const updatedCarrier = response.data.data; // Assuming the user data is in response.data.data
          updatedCarrier.userId = carrier.userId;
          setCarrier(updatedCarrier); // Update carrier state
          setLoading(false); // Set loading to false when data is loaded
          console.log("Map Page updated carrier: ", updatedCarrier);
        }
      } catch (error) {
        console.error("Error fetching updated carrier info:", error);
        toast.error("Failed to load user information. Please try again.");
        setCarrier(null); // Clear carrier state
        navigate("/login", { state: { carrier: null } }); // Redirect to login
      }
    
  };

  // Function to handle navigation for missions Button
  const handleMissionClick = (mission) => {
    if (mission <= carrier.currentMissionId) {
      // Check if a section is saved in `carrier`
      const sectionToResume = carrier.currentMissionPage || 1;  // Default to section 1 if none is saved
      
      // Pass `carrier` and the section to start from
      navigate(`/mission${mission}`, { state: { carrier, section: sectionToResume } });
    } else {
      toast.error("This mission is locked");
    }
  };

  const handleIntro = () => {
    navigate("/home", { state: { carrier } });
  };

  // Function to determine button background and text color
  const getButtonColors = (mission) => {
    if (mission < carrier.currentMissionId) {
      return { bg: "bg-green-500", text: "text-white" }; // Completed mission colors
    } else if (mission === carrier.currentMissionId) {
      return { bg: "bg-[#faba12]", text: "text-black" }; // Current mission colors
    } else {
      return { bg: "bg-[#9c6334]", text: "text-[#dcaa6b]" }; // Locked mission colors
    }
  };

  // Show loading spinner while fetching updated carrier info
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    // BACKGROUND IMAGE
    <section
      className="flex justify-center items-center h-screen relative"
      style={{
        backgroundImage: `url(${blueMapBG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* MapImage Layer */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${MapImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          zIndex: 1,
        }}
      ></div>
      <ToastContainer />
      {/* BUTTONS PLACEMENT */}
      <div className="relative zoomer" style={{ zIndex: 2 }}>
        <link
          rel="stylesheet"
          href="https://unpkg.com/papercss@1.9.2/dist/paper.min.css"
        />
        <button
          className="absolute bg-[#909507] font-semibold lg:-top-24 lg:-left-60 md:-top-20 md:-left-48"
          onClick={() => handleIntro()}
        >
          Introduction
        </button>
        <button
          onClick={() => handleMissionClick(1)}
          className={`absolute ${getButtonColors(1).bg} ${
            getButtonColors(1).text
          } font-semibold lg:top-6 lg:-left-60 md:top-3 md:-left-48 whitespace-nowrap`}
        >
          Mission 1
        </button>
        <button
          onClick={() => handleMissionClick(2)}
          className={`absolute ${getButtonColors(2).bg} ${
            getButtonColors(2).text
          } font-semibold lg:top-32 lg:-left-48 md:top-28 md:-left-44 whitespace-nowrap`}
        >
          Mission 2
        </button>
        <button
          onClick={() => handleMissionClick(3)}
          className={`absolute ${getButtonColors(3).bg} ${
            getButtonColors(3).text
          } font-semibold lg:top-14 lg:right-8 md:top-14 md:right-8 whitespace-nowrap`}
        >
          Mission 3
        </button>
        <button
          onClick={() => handleMissionClick(4)}
          className={`absolute ${getButtonColors(4).bg} ${
            getButtonColors(4).text
          } font-semibold lg:right-2 lg:-top-7 whitespace-nowrap`}
        >
          Mission 4
        </button>
        <button
          onClick={() => handleMissionClick(5)}
          className={`absolute ${getButtonColors(5).bg} ${
            getButtonColors(5).text
          } font-semibold lg:bottom-20 lg:right-6 whitespace-nowrap`}
        >
          Mission 5
        </button>
        <button
          onClick={() => handleMissionClick(6)}
          className={`absolute ${getButtonColors(6).bg} ${
            getButtonColors(6).text
          } font-semibold lg:bottom-12 whitespace-nowrap`}
        >
          Mission 6
        </button>
        <button
          onClick={() => handleMissionClick(7)}
          className={`absolute ${getButtonColors(7).bg} ${
            getButtonColors(7).text
          } font-semibold lg:bottom-6 lg:left-28 whitespace-nowrap`}
        >
          Mission 7
        </button>
        <button
          onClick={() => handleMissionClick(8)}
          className={`absolute ${getButtonColors(8).bg} ${
            getButtonColors(8).text
          } font-semibold lg:left-16 whitespace-nowrap`}
        >
          Mission 8
        </button>
        <button
          onClick={() => handleMissionClick(9)}
          className={`absolute ${getButtonColors(9).bg} ${
            getButtonColors(9).text
          } font-semibold lg:top-16 lg:left-1 whitespace-nowrap`}
        >
          Mission 9
        </button>
        <button
          onClick={() => handleMissionClick(10)}
          className={`absolute ${getButtonColors(10).bg} ${
            getButtonColors(10).text
          } font-semibold lg:top-28 lg:left-24 whitespace-nowrap`}
        >
          Mission 10
        </button>
        <button
          onClick={() => handleMissionClick(11)}
          className={`absolute ${getButtonColors(11).bg} ${
            getButtonColors(11).text
          } font-semibold lg:top-12 lg:left-44 whitespace-nowrap`}
        >
          Mission 11
        </button>
        <button
          onClick={() => handleMissionClick(12)}
          className={`absolute ${getButtonColors(12).bg} ${
            getButtonColors(12).text
          } font-semibold lg:-top-4 lg:left-52 whitespace-nowrap`}
        >
          Mission 12
        </button>
        <button className="absolute bg-[#909507] font-semibold lg:left-60 lg:bottom-12">
          Finish
        </button>
      </div>
    </section>
  );
};

export default Map;
