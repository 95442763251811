import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import loginBG from "../assets/loginBG.png";
import fbLogo from "../assets/fbLogo.png";
import uniLogo from "../assets/uniLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

 useEffect(() => {
  // console.log(window.location)
 }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !firstName || !lastName || !password || !confirmPassword) {
      setError("All fields must be filled!");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/signup`, {
        firstName: firstName,
        lastName: lastName,
        userName: username,
        userPassword: password,
        currentMissionId: 1,
      });
      if (response.status === 200) {
        const { data } = response.data;
        // console.log("First Name : " + data.firstName);
        // console.log("Last Name : " + data.lastName);
        toast.success("Signup successful! Please login now.");
        setTimeout(() => {
          navigate("/", { state: { carrier: data } });
        }, 2000); // delay to show toast
      }
    } catch (error) {
      setError("Invalid username or password");
    }
  };

  const logInHandler = () => {
    navigate("/");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <ToastContainer />
      <div className="w-full max-w-3xl">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-1"
        >
          <div className="flex flex-row gap-24">
            <div>
              <div className="flex justify-between items-center gap-7 -mb-5">
                <img
                  src={fbLogo}
                  alt="Feeling-Better-Logo"
                  className=" w-36 items-center"
                />
                <img
                  src={uniLogo}
                  alt="University-Logo"
                  className=" w-24 h-14 items-center"
                />
              </div>
              <h2 className="mb-2 font-montserrat text-xl font-semibold">
                Sign Up
              </h2>
              <div className="mb-2">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="first-name"
                >
                  First Name (Child)
                </label>
                <input
                  type="text"
                  id="first-name"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-gillSans"
                />
              </div>
              <div className="mb-2">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="last-name"
                >
                  Last Name (Child)
                </label>
                <input
                  type="text"
                  id="last-name"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-gillSans"
                />
              </div>
              <div className="mb-2">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="username"
                >
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-gillSans"
                />
              </div>
              <div className="mb-1 relative">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-1"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="******************"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                <span
                  className="absolute bottom-5 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="h-5 w-5"
                  />
                </span>
              </div>
              <div className="mb-1 relative">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-1"
                  htmlFor="confirm-password"
                >
                  Confirm Password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  placeholder="******************"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                <span
                  className="absolute bottom-5 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    className="h-5 w-5"
                  />
                </span>
              </div>
              <div className="mb-2">
                <p className="text-sm">
                  Existing User? {""}
                  <button
                    onClick={logInHandler}
                    className="underline text-blue-600"
                  >
                    Log in
                  </button>
                </p>
              </div>
              {error && (
                <p className="text-red-500 text-xs italic mb-2">{error}</p>
              )}
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-[#3fa7d6] hover:bg-[#4ac4fc] text-white font-bold py-2 px-20 rounded focus:outline-none focus:shadow-outline"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div className="flex items-center">
              <img src={loginBG} alt="captain-charlie" className="w-96 h-96" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
