import React, { useEffect } from "react";
import drReily from "../../assets/Characters/Riley.png";
import pg19 from "../../assets/audios/speech/Mission6/pg19.mp3";

const Skill1Page1 = ({ onNext, setSubmitted, audioRef }) => {
  useEffect(() => {  
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    const audio = new Audio(pg19);
    audio.play();
    setSubmitted(true);
    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0 overflow-auto">
      <div className="flex flex-col lg:flex-row items-start w-full max-w-4xl rounded-lg p-5 lg:p-10">
        {/* Doctor Image */}
        <div className="w-full lg:w-1/3 flex justify-center lg:justify-start lg:mb-0">
          <img
            src={drReily}
            alt="Dr. Riley"
            className="w-60 h-auto rounded-lg shadow-lg mt-20"
          />
        </div>
        {/* Text Section */}
        <div className="w-full lg:w-2/3 text-gray-800 text-lg font-sans lg:ml-6 mr-5">
          <h2 className="text-4xl font-bold mb-4">Health Superheroes</h2>
          <p className="text-xl mt-8">
            When you have pain (like when your tummy hurts). <br />
            The doctor will check your tummy.
          </p>
          <p className="text-xl mt-8">
            The Doctor might touch your tummy or use a special tool to check
            your tummy.
          </p>
          <p className="text-xl mt-8">
            Doctors are <span className="font-bold">Health Superheros</span>:
            They find out what’s wrong when you have pain.
          </p>
        </div>
      </div>
      <button
        onClick={onNext}
        className="px-8 py-2 bg-green-600 text-xl text-white rounded"
      >
        Next
      </button>
    </div>
  );
};

export default Skill1Page1;
