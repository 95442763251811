import React, { useState } from "react";
import axios from "axios";
import greenTick from "../../assets/green-tick.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import redCross from "../../assets/red-cross.png";

const QuickTask = ({ carrier, onNext }) => {
  const [selectedOptions, setSelectedOptions] = useState([false, false, false]);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState("?");

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  // Create a dictionary of options and corresponding answers
  const optionsDict = {
    0: "Point to the body part that hurts.",
    1: "Point to the face to show how I am feeling to my mum/dad.",
    2: "Show my teacher by pointing to the face to show how I am feeling in school.",
  };

  // Handle option selection
  const handleOptionClick = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = !updatedOptions[index]; // Toggle selection
    setSelectedOptions(updatedOptions);
  };

  // Handle submit and check if all correct answers are selected
  const handleSubmit = async () => {
    const allCorrectSelected =
      selectedOptions[0] && selectedOptions[1] && selectedOptions[2];
    setSubmitted(true);
    console.log("allCorrectSelected", allCorrectSelected);
    if (allCorrectSelected) {
      setIsCorrect(true);
      sessionStorage.setItem("quickTaskCompleted", "true");
      audioPlay();
      onNext(); // Proceed to the next step if all correct
    } else {
      setIncorrectAttempts((prev) => prev + 1); // Increment incorrect attempts
      console.log("incorrectAttempts", incorrectAttempts);
      setIsCorrect(false);
      console.log("isCorrect", isCorrect);
    }

    // Prepare the selected options and their corresponding answers
    const selectedOptionsWithAnswers = selectedOptions.reduce(
      (acc, isSelected, index) => {
        if (isSelected) {
          acc[optionsDict[index]] = isSelected; // Store the answer based on the dictionary
        }
        return acc;
      },
      {}
    );

    // Hit the API to save quiz activity
    const userId = carrier.userId;
    const activityValue = {
      selectedOptionsWithAnswers,
      feedback: allCorrectSelected ? "Correct" : "Incorrect",
    };

    try {
      const payload = [
        {
          userId: userId,
          missionId: "M5",
          pageNum: 35,
          activityId: "M5QuickTask",
          activityValue: JSON.stringify(activityValue),
        },
      ];
      console.log("activityValue", activityValue);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-4xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-12 lg:text-4xl font-gillSans flex items-center justify-center">
        Quick Task
        <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
      </h2>
      <span className="text-xl font-gillSans mb-6">What can you do to show you are in pain?</span>
      {isCorrect === true && (
              <img src={greenTick} alt="Green Tick" className="w-12 h-12 ml-2" />
            )}
            {!isCorrect  && (
              <img src={redCross} alt="Red Cross" className="w-12 h-12 ml-2" />
            )}
      <p className="text-xl font-gillSans mb-6">Choose all the correct answers.</p>

      <div className="text-left text-xl">
        <div
          className={`cursor-pointer flex items-center ${
            submitted && incorrectAttempts >= 3 && !selectedOptions[0]
              ? "bg-yellow-200"
              : ""
          }`}
          onClick={() => handleOptionClick(0)}
        >
          {selectedOptions[0] && (
            <img src={greenTick} alt="tick" className="w-6 mr-2" />
          )}
          <p className="font-gillSans text-xl">a) Point to the body part that hurts.</p>
        </div>

        <div
          className={`cursor-pointer flex items-center ${
            submitted && incorrectAttempts >= 3 && !selectedOptions[1]
              ? "bg-yellow-200"
              : ""
          }`}
          onClick={() => handleOptionClick(1)}
        >
          {selectedOptions[1] && (
            <img src={greenTick} alt="tick" className="w-6 mr-2" />
          )}
          <p className="font-gillSans text-xl">
            b) Point to the face to show how I am feeling to my mum/dad.
          </p>
        </div>

        <div
          className={`cursor-pointer flex items-center ${
            submitted && incorrectAttempts >= 3 && !selectedOptions[2]
              ? "bg-yellow-200"
              : ""
          }`}
          onClick={() => handleOptionClick(2)}
        >
          {selectedOptions[2] && (
            <img src={greenTick} alt="tick" className="w-6 mr-2" />
          )}
          <p className="font-gillSans text-xl">
            c) Show my teacher by pointing to the face to show how I am feeling
            in school.
          </p>
        </div>
      </div>

      {/* Submit Button */}
      <div className="mt-6">
        <button
          onClick={handleSubmit}
          className={`px-6 py-2 text-xl rounded-md bg-[#6BBF59] text-white hover:bg-[#6BBF59] ${
            !isCorrect ? "font-gillSans shake" : ""
          }`}
        >
          Submit
        </button>
      </div>

      {submitted && incorrectAttempts >= 3 && (
        <div className="text-blue-500 mt-5 text-lg">
          Hint: All three answers are correct.
        </div>
      )}
    </div>
  );
};

export default QuickTask;
