import React, { useEffect, useRef } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import greenFaceAudio from "../../assets/audios/painScale0.mp3";
import orangeFaceAudio from "../../assets/audios/painScale6.mp3";
import redFaceAudio from "../../assets/audios/painScale10.mp3";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const PainScale = () => {
  const audioRef = useRef(null);

  const playAudio = (audioFile) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const audio = new Audio(audioFile);
    audio.play();
    audioRef.current = audio;
  };

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  useEffect(() => {
    audioPlay(); // Play the audio when the component mounts
  }, []);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
        Setting Sail on the Pain Scale
        <img src={coin} alt="coin" className="w-12 ml-10 heartbeat" />
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="lg:text-xl">
          <li className="mt-1">
            Remember, when your mum/dad or teacher ask you{" "}
            <span className="font-bold">“How you are Feeling”</span> you can
            point to the pain scale to show how much something hurts.
          </li>
          <li>
            <div className="flex mt-5 justify-around items-center fadeIn gap-5 max-w-3xl">
              <div className="flex items-center flex-col">
                <img
                  src={painScale0}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                  onClick={() => playAudio(greenFaceAudio)}
                />
                <span className="mt-2 text-center font-gillSans">
                  0 <br />
                  No hurt
                </span>
              </div>
              <div className="flex items-center flex-col">
                <img
                  src={painScale2}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                />
                <span className="mt-2 text-center font-gillSans">
                  2 <br />
                  Hurts little bit
                </span>
              </div>
              <div className="flex items-center flex-col">
                <img
                  src={painScale4}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                />
                <span className="mt-2 text-center font-gillSans">
                  4 <br />
                  Hurts little more
                </span>
              </div>
              <div className="flex items-center flex-col">
                <img
                  src={painScale6}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                  onClick={() => playAudio(orangeFaceAudio)}
                />
                <span className="mt-2 text-center font-gillSans">
                  6 <br />
                  Hurts even more
                </span>
              </div>
              <div className="flex items-center flex-col">
                <img
                  src={painScale8}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                />
                <span className="mt-2 text-center font-gillSans">
                  8 <br />
                  Hurts whole lot
                </span>
              </div>
              <div className="flex items-center flex-col">
                <img
                  src={painScale10}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                  onClick={() => playAudio(redFaceAudio)}
                />
                <span className="mt-2 text-center font-gillSans">
                  10 <br />
                  Hurts worst
                </span>
              </div>
            </div>
          </li>
          <ol className="lg:text-xl mt-10 ml-32 text-center p-2 w-2/3 bg-[#6BBF59] text-white rounded-md font-gillSans animate-zoomIn">
            Listen to 3 faces: Green face, Orange face, Red face.
          </ol>
        </ol>
      </div>
    </div>
  );
};

export default PainScale;
