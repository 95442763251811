import React from "react";
import taskVideo from "../../assets/videos/stomach.mp4";

const TaskVideo = ({ onNext }) => {
  return (
    <div className="flex flex-col items-center p-10 text-center">
      {/* Title */}
      <h2 className="text-4xl font-bold mb-4 text-[#6BBF59]">Task</h2>

      {/* Text content */}
      <div className="text-xl text-left mb-6 font-gillSans">
        <p className="mb-4">
          Captain Charlie has set you a task. Listen to Annie talking about her
          pain.
        </p>
        <p className="mb-4">
          Then you must help Captain Charlie make Annie feel better.
        </p>
      </div>

      {/* Video/Image Placeholder */}
      <div className="flex justify-center mb-6">
        <video
          src={taskVideo}
          controls
          loop={false}
          alt="Task Video"
          className="w-1/3 h-auto"
        />
      </div>

      {/* Next Button */}
      <div className="mt-4">
        <button
          onClick={onNext}
          className="px-6 py-2 text-xl rounded-md bg-green-500 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TaskVideo;
