import React, { useState, useRef } from "react";
import head from "../../assets/videos/head.mp4";
import headImage from "../../assets/head.png";
import stomachImage from "../../assets/stomach.png";
import mouthImage from "../../assets/mouth.png";
import armImage from "../../assets/arm.png";
import footImage from "../../assets/Body Parts/legs.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const HeadSection = ({ onQuizSuccess }) => {
  const [showQuiz, setShowQuiz] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [quizResult, setQuizResult] = useState(null);
  const [attempts, setAttempts] = useState(0); // Add attempt counter

  // Create a reference for the audio element
  const coinSoundRef = useRef(null);

  const handleQuizAttempt = () => {
    setShowQuiz(true);
  };

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
    if (answer === "head") {
      setQuizResult("correct");

      // Play the coin sound
      if (coinSoundRef.current) {
        coinSoundRef.current.play();
      }

      setTimeout(() => {
        onQuizSuccess();
      }, 2000);
    } else {
      setQuizResult("incorrect");
      setAttempts((prevAttempts) => prevAttempts + 1); // Increment attempts on incorrect answer         
    }    
  };

  return (
    <div className="flex flex-col items-center mt-5 justify-center gap-5 fadeIn">
      {!showQuiz ? (
        <>
          <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Head
          </h2>
          <video width="350" className="mt-5" controls>
            <source src={head} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button
            onClick={handleQuizAttempt}
            className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-[#6BBF59] transition duration-300 ease-out border-2 border-black rounded-full shadow-md group mt-8"
          >
            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#3FA7D6] group-hover:translate-x-0 ease">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                ></path>
              </svg>
            </span>
            <span className="absolute flex items-center justify-center w-full h-full text-gray-700 transition-all duration-300 transform group-hover:translate-x-full ease">
              Attempt Quiz!
            </span>
            <span className="relative invisible">Attempt Quiz</span>
          </button>
        </>
      ) : (
        <div className="flex flex-col items-center mt-10">
          <div className="flex flex-row items-start justify-center gap-2">
            <video width="350" className="-mt-20 mb-5 slideUp" controls>
              <source src={head} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
          </div>
          <h3 className="lg:text-xl font-gillSans mb-4 fadeIn">
            Which body part hurts in the video? 
          </h3>
          <div className="flex mt-2 justify-between pr-6 gap-5 lg:pr-8 fadeIn">
            <div
              className={`flex items-center flex-col cursor-pointer ${
                attempts >= 3 && "head" === "head" ? "bg-yellow-200 rounded-lg" : ""
              }`} // Highlight head after 3 incorrect attempts
              onClick={() => handleAnswerClick("head")}
            >
              <img
                src={headImage}
                alt="head"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Head</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                attempts >= 3 && "stomach" === "head" ? "bg-yellow-200" : ""
              }`} // Highlight head after 3 incorrect attempts
              onClick={() => handleAnswerClick("stomach")}
            >
              <img
                src={stomachImage}
                alt="stomach"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Tummy</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                attempts >= 3 && "mouth" === "head" ? "bg-yellow-200" : ""
              }`} // Highlight head after 3 incorrect attempts
              onClick={() => handleAnswerClick("mouth")}
            >
              <img
                src={mouthImage}
                alt="mouth"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Mouth</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                attempts >= 3 && "arm" === "head" ? "bg-yellow-200" : ""
              }`} // Highlight head after 3 incorrect attempts
              onClick={() => handleAnswerClick("arm")}
            >
              <img
                src={armImage}
                alt="arm"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Arm</span>
            </div>
            <div
              className={`flex items-center flex-col cursor-pointer ${
                attempts >= 3 && "foot" === "head" ? "bg-yellow-200" : ""
              }`} // Highlight head after 3 incorrect attempts
              onClick={() => handleAnswerClick("foot")}
            >
              <img
                src={footImage}
                alt="Legs"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
              />
              <span className="mt-2 font-gillSans lg:text-lg">Legs</span>
            </div>
          </div>
          {quizResult && (
            <p
              className={`mt-4 text-xl font-bold ${
                quizResult === "correct"
                  ? "text-green-600 font-gillSans bounceIn"
                  : "text-red-600 font-gillSans shake"
              }`}
            >
              {quizResult === "correct"
                ? "Correct! Now try clicking other body parts!"
                : "Incorrect. Please try again."}
            </p>
          )}
        </div>
      )}
      {/* Audio element for the coin sound */}
      <audio ref={coinSoundRef} src={coins} />
    </div>
  );
};

export default HeadSection;
