import React, { useEffect } from "react";
import drReillyImage from "../../assets/Characters/Riley.png";
import coins from "../../assets/audios/coins.mp3";
import coin from "../../assets/Icons/Coin.png";

const ExcellentJobPage = (carrier) => {
  const audio = new Audio(coins);
  useEffect(() => {
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="flex flex-col lg:flex-row items-center p-20 max-w-4xl mx-auto">
      {/* Doctor Image */}
      <div className="w-full lg:w-full flex justify-center lg:justify-start">
        <img
          src={drReillyImage}
          alt="Doctor Reilly"
          className="w-60 h-auto rounded-lg shadow-lg"
        />
      </div>

      {/* Congratulatory Text */}
      <div className="flex flex-col items-start">
        <h2 className="text-3xl font-bold mb-2">
          Excellent Job
          <img src={coin} alt="coin" className="w-14 h-14 inline-block ml-2" />
        </h2>
        <p className="text-xl mb-4 cursor-pointer">Well done!</p>
        <p className="text-xl mb-4">
          Thank you for your help {carrier.firstName} in helping Annie tell
          Doctor Reilly how she was feeling.
        </p>
        <p className="text-xl">
          Because you told Doctor Reilly how Annie was feeling, Doctor Reilly
          has given her magic medicine to feel better.
        </p>
      </div>
    </div>
  );
};

export default ExcellentJobPage;
