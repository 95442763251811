import React, { useEffect } from "react";
import captainCharlie from "../../assets/cptCharlie.png"; // Image path for Captain Charlie
import pain from "../../assets/videos/Communicating Pain.mp4";
import pg41 from "../../assets/audios/speech/Mission6/pg41.mp3";

const IncorrectAnswer = ({ attempts, resetQuiz }) => {
  useEffect(() => {    
    const audio = new Audio(pg41);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="flex flex-col items-start p-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-4">Incorrect Answer</h2>
      <div className="flex items-start gap-6">
        <img
          src={captainCharlie}
          alt="Captain Charlie"
          className="w-32 h-auto"
        />
        <div className="text-xl space-y-4">
          <p>Let's Try Again.</p>
          <p>
            Let's watch the video again to remember how to tell a doctor when
            you have pain.
          </p>
          <video src={pain} controls className="w-64 h-auto mt-2 rounded-lg" />
          <p className="mt-4">
            Remember if you need help, click the help button at the top of the
            page.
          </p>
          <button
          onClick={resetQuiz}
          className="mt-4 px-4 py-2 lg:text-lg bg-gray-500 text-white font-bold rounded hover:bg-gray-600 transition"
        >
          Try Again
        </button>
        </div>
      </div>
    </div>
  );
};

export default IncorrectAnswer;
