import React, {useEffect} from "react";
import treasureChest from "../../assets/miscellaneous/treasurebox.png";
import greenTick from "../../assets/green-tick.png";
import pg21Skill2 from "../../assets/audios/speech/Mission6/pg21Skill2.mp3";

const TreasureChestPage = ({ completedSkills, onSelectSkill }) => {
  useEffect(() => {
    if(completedSkills.skill1){
    const audio = new Audio(pg21Skill2);
    audio.play();

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
    }
  }, [completedSkills]);
  return (
    <div className="flex flex-col font-gillSans">
      <h1 className="text-3xl font-bold mb-4">Health Superheroes</h1>
      <p className="text-xl mb-2">Doctors have a very special job.</p>
      <p className="text-xl mb-3">They are superheroes when we have pain.</p>
      <p className="text-xl mb-3">Doctors have 2 very special skills: <span className="text-red-500">(click into each to learn more)</span> </p>
      <p className="lg:text-xl animate-pulse transform hover:scale-105 transition-transform duration-300">
        Click into the 2 treasure chests to find out about these special skills:
      </p>

      <div className="flex gap-8 mt-6">
        {/* Skill 1 Chest with Tick Mark */}
        <div
          className="relative cursor-pointer"
          onClick={() => onSelectSkill("skill1")}
        >
          <img
            src={treasureChest}
            alt="Treasure Chest"
            className={`w-2/4 hover:scale-105 transition-transform duration-200 ${!completedSkills.skill1 ? "border-4 border-[#9c6334] rounded" : "grayscale"}`}
          />
          {completedSkills.skill1 && (
            <img
              src={greenTick}
              alt="Completed"
              className="absolute -top-3 -left-3 w-24 h-auto"
            />
          )}
          <p className="text-center text-xl mt-2 font-semibold">
            Skill 1: Find out why it hurts so much
          </p>
        </div>

        {/* Skill 2 Chest */}
        <div
          className="relative cursor-pointer"
          onClick={() => onSelectSkill("skill2")}
        >
          <img
            src={treasureChest}
            alt="Treasure Chest"
            className={`w-2/4 hover:scale-105 transition-transform duration-200 ${completedSkills.skill1 ? "border-4 border-[#9c6334] rounded" : ""}`}
          />
          {completedSkills.skill2 && (
            <img
              src={greenTick}
              alt="Completed"
              className="absolute -top-3 -left-3 w-26 h-26"
            />
          )}
          <p className="text-center text-xl mt-2 font-semibold">
            Skill 2: Help us feel better
          </p>
        </div>
      </div>
    </div>
  );
};

export default TreasureChestPage;
