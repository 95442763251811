import React from "react";

const FBASD = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Feeling Better ASD
      </h2>
      <div className="text-base mt-2 mx-12 font-gillSans">
        <p className="mt-3">
          Feeling Better ASD contains 13 missions. Each mission will focus on
          teaching a key skill associated with pain. The programme is broken
          down into 4 key skills:
        </p>
        <ul className="ml-5 font-semibold">
          <li className=" mt-3 ">Understanding Pain</li>
          <li className=" mt-3 ">Communicating Pain</li>
          <li className=" mt-3 ">Coping Strategies</li>
          <li className=" mt-3 ">Mental Health and Pain</li>
        </ul>
        <p className="mt-2">
          Each week your child will access 1 mission. Each mission will take
          approx. 20-25 minutes to complete.
        </p>
        <p className="mt-2">
          Progress on the programme is based on the completion of each mission.
        </p>
        <p className="mt-2">
          Each mission contains activities for your child to practice (e.g.
          tummy breathing). This programme is interactive to encourage and
          support learning for your child.
        </p>
        <p className="mt-2">
          After each mission resources are available for you and your child to
          access. Resources include online or printable versions of each of the
          skills taught in the missions.
        </p>
      </div>
    </div>
  );
};

export default FBASD;
