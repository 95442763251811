import React, {useEffect} from "react";
import drReily from "../../assets/Characters/Riley.png";
import painScale from "../../assets/pain scale/Pain scale.png";
import pg24 from "../../assets/audios/speech/Mission6/pg24.mp3";

const Skill2Page3 = ({ onNext }) => {
  useEffect(() => {    
    const audio = new Audio(pg24);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="flex flex-col items-center">
      <h2 className="text-3xl font-gillSans font-bold mb-4">Ask Questions</h2>
      <div className="flex items-start gap-6">
        <img src={drReily} alt="Dr. Riley" className="w-40 h-auto" />
        <div className="flex flex-col items-start font-gillSans text-xl">
          <p className="mt-4">When you go to the doctor's they might ask you <br/> <span className="font-bold">'How are You Feeling'?</span></p>
          <p className="mt-4">You can use the pain scale to show the doctor how much it hurts.</p>
          <img src={painScale} alt="Pain Scale" className="mt-4 w-80 h-auto" />
          <p className="mt-4">The doctor might ask your mum/dad some questions about you.</p>
        </div>
      </div>
      <button onClick={onNext} className="mt-6 px-8 py-2 bg-green-600 text-white text-xl rounded">Next</button>
    </div>
  );
};

export default Skill2Page3;
