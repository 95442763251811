import React, {useEffect} from "react";
import medicineImage from "../../assets/pain diary/medicines.png";
import pg26 from "../../assets/audios/speech/Mission6/pg26.mp3";

const Skill2Page4 = ({ onNext }) => {
  useEffect(() => {    
    const audio = new Audio(pg26);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-80 h-72 text-sm w-full bg-transparent md:w-[80%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center  md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Magic Medicine
      </h2>
      <div className="flex items-start gap-2">
        <div className="flex flex-grow">
          <img
            src={medicineImage}
            alt="Magic Medicine"
            className="w-96"
          />
        </div>
        <div className="flex flex-col items-start font-gillSans text-xl mt-5">
          <p className="mb-4">
            Sometimes the doctor might give you magic medicine <br/> to make you feel
            better soon.
          </p>
          <p className="mb-4">
            Your mum/dad will then give you this magic medicine <br/> when you are at
            home.
          </p>
          <p className="mb-4">
            Sometimes when Captain Charlie is sick,<br/> Dr. Reilly gives him magic
            medicine to help him feel better.
          </p>
      <button
        onClick={onNext}
        className="mt-6 px-8 py-2 bg-green-600 text-white text-xl rounded"
      >
        Next
      </button>
        </div>
      </div>
    </div>
  );
};

export default Skill2Page4;
