import React, { useState, useEffect } from "react";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png"; 
import coins from "../../assets/audios/coins.mp3";
import sailorAmy from "../../assets/Characters/SailorAmy.jpg"; 
import captain from "../../assets/cptCharlie.png";

const QuizTaskPage = ({
  carrier,
  onTryAgain,
  handleSpeech,
  submitted,
  setSubmitted,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [attempts, setAttempts] = useState(1); // Track the number of attempts

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  if (isCorrect) {
    sessionStorage.setItem("SailorAmytaskCompleted", "true");
    audioPlay();
  } else if (attempts >= 3) {
    sessionStorage.setItem("SailorAmytaskCompleted", "true");
  }

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    const correctAnswer = 2; // The correct answer is option 2
    const answerIsCorrect = option === correctAnswer;
    setIsCorrect(answerIsCorrect);

    if (!answerIsCorrect) {
      setAttempts((prevAttempts) => prevAttempts + 1);
    }
    let userId = carrier.userId;
    // Hit the API to save quiz activity
    try {
      const payload = [
        {
          userId: userId,
          missionId: "M1",
          pageNum: 6,
          activityId: "M1PainQuiz",
          activityValue: JSON.stringify({
            selectedOption: option,
            feedback: answerIsCorrect ? "Correct" : "Incorrect",
          }),
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
    setSubmitted(true);
  };

  useEffect(() => {
    console.log("M5 Sailor Amy Quiz submitted: ", submitted);
    if (submitted) {
      console.log("M5 Sailor Amy Quiz isCorrect: ", isCorrect);
      if (isCorrect) {
        handleSpeech(17.1); // Play correct answer audio when correct
      } else {
        handleSpeech(17.2); // Play incorrect answer audio when incorrect
      }
    }
  }, [submitted, isCorrect, handleSpeech]);

  const handleTryAgain = () => {
    setSubmitted(false);
    setSelectedOption(null);
    setIsCorrect(null);
    onTryAgain();
  };

  if (submitted) {
    return isCorrect ? (
      // Correct Answer Component
      <div className="flex max-w-lg flex-col bounceIn font-gillSans">
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-xl font-bold mb-2 text-center text-green-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Correct Answer!
          </h2>
          <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
        </div>
        <div className="flex mt-10">
          <div className="w-full">
          <img src={captain} className="w-66 mr-10" alt="Sailor Amy" />
          </div>
          <div className="flex-grow">
          <ol className="mt-3 lg:text-xl">
            <li>Excellent Job Navigator {carrier?.firstName}!</li>
            <li className="mt-3">
              That's correct! Sailor Amy uses the pain scale and points to the
              different faces to show how much it hurts.
            </li>
          </ol>
          </div>
        </div>
      </div>
    ) : (
      // Incorrect Answer Component
      <div className="flex max-w-xl flex-col shake font-gillSans">
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-xl font-bold mb-2 text-center text-red-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Incorrect Answer!
          </h2>
          {/* You can add an icon here if needed */}
        </div>
        <div className="flex mt-10">
          <div className="w-full">
            <img src={captain} className="w-full mr-10" alt="Captain" />
          </div>
          <div className="flex-grow">
            <ol className="mt-3 lg:text-xl">
              <li>Let's Try Again.</li>
              <li className="mt-3">
                Click on the photo to listen to Sailor Amy again to find out how
                she shows her mum/dad she has pain.
              </li>
              <li className="mt-3">
                <img
                  src={sailorAmy}
                  className="w-20 cursor-pointer"
                  onClick={handleTryAgain}
                  alt="Sailor Amy"
                />
              </li>
              <li className="mt-3">
                Remember if you need help, click the help button at the top of
                the page.
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      <div className="flex flex-row">
        <div className="flex-grow text-gray-700 text-base font-gillSans">
          <h2 className="text-4xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-12 lg:text-4xl font-gillSans flex items-center justify-center">
            Task
            <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
          </h2>
          <p className="mt-5 underline font-semibold lg:text-xl">
            What does Sailor Amy do to show her mum/dad she is in pain?
          </p>
          <ol className="list-decimal text-xl flex flex-col">
            <li
              className={`mt-5 cursor-pointer hover:scale-110 ${
                attempts >= 3 && selectedOption === 2
                  ? "bg-yellow-200"
                  : "hover:text-[#6BBF59]"
              } transform transition duration-300 ease-in-out`}
              onClick={() => handleOptionClick(1)}
            >
              Laugh
            </li>
            <li
              className={`mt-5 cursor-pointer hover:scale-110 ${
                attempts >= 3 && selectedOption === 2
                  ? "bg-yellow-200"
                  : "hover:text-[#6BBF59]"
              } transform transition duration-300 ease-in-out`}
              onClick={() => handleOptionClick(2)}
            >
              Point to the different faces on the pain scale.
            </li>
            <li
              className={`mt-5 cursor-pointer hover:scale-110 ${
                attempts >= 3 && selectedOption === 2
                  ? "bg-yellow-200"
                  : "hover:text-[#6BBF59]"
              } transform transition duration-300 ease-in-out`}
              onClick={() => handleOptionClick(3)}
            >
              Run away
            </li>
          </ol>
        </div>
        <div className="w-2/3 mt-20 flex">
          <img src={sailorAmy} alt="Sailor Amy" className="p-1 ml-10 w-64 h-auto" />
        </div>
      </div>
    </div>
  );
};

export default QuizTaskPage;
