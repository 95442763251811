import React from "react";
import MissionComplete from "../MissionComplete";

const Mission1Complete = ({ carrier }) => {
  const completedMissionId = 1;
  const rank = "Deckhand";
  const coins = 11;
  const userId = carrier.userId;
  const currentMissionId = 2;
  const currentMissionPage = 18;


  return (
    <>
      <MissionComplete
        completedMissionId={completedMissionId}
        rank={rank}
        coins={coins}
        userId={userId}
        currentMissionId={currentMissionId}
        currentMissionPage={currentMissionPage}        
      />
    </>
  );
};

export default Mission1Complete;
