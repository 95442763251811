import React, { useEffect, useRef } from "react";
import DrTalkExpert from "../../assets/Badges/DrTalkExpert.png";

const Badge6 = () => {
 
  return (
    <div className="flex justify-center items-center p-10 rounded-lg max-w-4xl mx-auto fadeIn">
      {/* Left side - Text */}
      <div className="w-1/2 text-left pr-15">
        <h2 className="text-2xl font-bold mb-4 text-left ml-6 lg:text-3xl">
          Your Special Badge
        </h2>
        <p className="text-gray-700 text-xl mb-4">
          Congratulations, Captain Charlie has awarded you a special badge
          called <strong>'Doctor Talk Champion'</strong>.
        </p>
        <p className="text-gray-700 text-xl mb-4">
          This badge shows you have done something great- talking to a doctor!
        </p>
        <p className="text-gray-700 text-xl">
          Every time you look at your badge, you can remember how important it
          is to talk to a doctor when you are in pain. You're a real health
          hero!
        </p>
      </div>
      {/* Right side - Image */}
      <div className="w-1/2 flex justify-center">
        <img
          src={DrTalkExpert}
          alt="Pain Communicator Badge"
          className="w-72 h-auto"
        />
      </div>
    </div>
  );
};

export default Badge6;
