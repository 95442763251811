import React from "react";
import communicatingPain from "../../assets/videos/3. Communicating Pain_F.mp4";

const YourPain = ({ onNext }) => {
  return (
    <div className="w-full h-auto p-10 text-center ">
      <h1 className="text-3xl font-gillSans font-bold text-center text-[#6BBF59] mb-6">Your Pain</h1>
      <div className="text-xl text-gray-700 mb-8 font-gillSans">
        <p>Everybody feels pain sometimes.</p>
        <p>
          Pain is important as it is how your body tells you something is wrong.
        </p>
        <p className="mt-6">Watch the video to hear Annie talk about pain.</p>
      </div>

      <div className="flex justify-center mb-6">
        <video
          src={communicatingPain}
          controls
          className="w-1/3 h-auto cursor-pointer"
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="flex justify-center">
        <button
          onClick={onNext}
          className="px-6 py-2 bg-green-500 text-white text-xl rounded-lg hover:bg-green-600 transition duration-200"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default YourPain;
