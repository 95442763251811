import React, { useState } from "react";

const PirateQuiz = ({ onNext, carrier, handleSpeech, submitted, setSubmitted }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };
  
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        A Pirates Adventure
      </h2>
      <div className="text-center font-gillSans">
        <ul>
          <li className=" mt-10 lg:text-xl md:text-xl">
            Powder Monkey {carrier?.firstName} your pirate quiz is to answer 2
            important questions.
          </li>
          <li className=" mt-10 lg:text-xl md:text-xl">
            Captain Charlie wants to test your knowledge on what you learned
            today.
          </li>
          <li className=" mt-10 lg:text-xl md:text-xl">
            You will receive your special pirate surprise after you finished the
            pirate task.
          </li>
        </ul>
        {ready ? (
          <p>Started!</p>
        ) : (
          <button
            className="lg:text-lg px-8 py-2 mt-14 rounded-md bg-green-500 text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
            onClick={handleStart}
          >
            Yes, Let's Go!
          </button>
        )}
      </div>
    </div>
  );
};

export default PirateQuiz;
