import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import painScale from "../../assets/pain scale/Pain scale.png";

const EndMission4Continued = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20 ml-15">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Mission this week continued
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans">
        <div>
          <p className=" mt-5 lg:text-xl">
            <span className="font-semibold">Extra task:</span> If a body part
            hurts pick the face that shows the level of pain.
          </p>
          <div className="mt-10">
            <img src={painScale} className="ml-14 w-72" />
          </div>
          <p className=" ml-2 mt-4 lg:text-xl">
            At the end of the week, you will receive a special badge from
            Captain Charlie for your hard work.
          </p>
        </div>
        <div>
          <img src={cptCharlie} alt="cptCharlie" className=" w-9/12" />
        </div>
      </div>
    </div>
  );
};

export default EndMission4Continued;
