import { useEffect , React} from "react";
import painScaleImage from "../../assets/pain scale/Pain scale.png"; 
import teacherImage from "../../assets/miscellaneous/teachers.png"; 
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
const TellingYourTeacher = () => {
    const audioPlay = () => {
        const audio = new Audio(coins);
        audio.play();
      };

  useEffect(() => {
    audioPlay(); // Play the audio when the component mounts
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-2xl font-bold text-center text-[#F79D84] md:text-3xl lg:text-4xl font-gillSans flex items-center justify-center">
        Telling your Teacher
        <img src={coin} alt="coin" className="w-12 ml-10 heartbeat" />
      </h2>

      <div className="text-gray-700 text-base mt-6 flex justify-between items-center font-gillSans">
        {/* Left side - Teacher image */}
        <div className="w-2/5 flex justify-center items-center">
          <img src={teacherImage} alt="Teacher" className="w-40 h-auto" />
        </div>

        {/* Right side - Text content and Pain Scale */}
        <div className="w-1/2 text-xl text-left mr-10">
          <p className="mb-4">
            If you are in school and your body part hurts, it's very important to tell your teacher.
          </p>
          <p>
            Point to the face to show how you are feeling.
          </p>
          <div className="flex justify-center items-center mt-8">
            <img src={painScaleImage} alt="Wong-Baker Pain Scale" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TellingYourTeacher;
