import React, { useEffect, useState } from 'react';
import axios from 'axios';

const StarsRecordDash = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [starsRecord, setStarsRecord] = useState([]);

  useEffect(() => {
    // Fetch users on component mount
    axios.get(`${process.env.REACT_APP_API_BASE_URL}users/getAllUserIdsAndUserNames`)
      .then(response => {
        const fetchedUsers = Array.isArray(response.data.data) ? response.data.data : [];
        setUsers(fetchedUsers);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUser(userId);

    if (userId) {
      // Fetch stars record data for the selected user
      axios.get(`${process.env.REACT_APP_API_BASE_URL}fetchStars/${userId}`)
        .then(response => {
          const recordEntries = response.data && Array.isArray(response.data.data) ? response.data.data : [];
          setStarsRecord(recordEntries);
        })
        .catch(error => {
          console.error('Error fetching stars record data:', error);
        });
    } else {
      setStarsRecord([]);
    }
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <header style={{ marginBottom: '20px' }}>
        <h1 style={{ textAlign: 'center' }}>Stars Record Dashboard</h1>
      </header>

      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <label htmlFor="user-select" style={{ marginRight: '10px' }}>Select User:</label>
        <select
          id="user-select"
          value={selectedUser}
          onChange={handleUserChange}
          style={{ padding: '5px', fontSize: '16px' }}
        >
          <option value="">--Select User--</option>
          {users.map((user, index) => (
            <option key={index} value={user.userId}>
              {user.userName}
            </option>
          ))}
        </select>
      </div>

      {starsRecord.length > 0 && (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '2px solid black' }}>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1' }}>
              <tr>
                <th style={{ border: '2px solid black', padding: '8px' }}>Record ID</th>
                <th style={{ border: '2px solid black', padding: '8px' }}>Mission ID</th>
                <th style={{ border: '2px solid black', padding: '8px' }}>Stars Collected</th>
                <th style={{ border: '2px solid black', padding: '8px' }}>Total Stars</th>              
              </tr>
            </thead>
            <tbody>
              {starsRecord.map((entry, index) => (
                <tr key={index}>
                  <td style={{ border: '2px solid black', padding: '8px', textAlign: 'center' }}>{entry.recordId}</td>
                  <td style={{ border: '2px solid black', padding: '8px', textAlign: 'center' }}>{entry.missionId}</td>
                  <td style={{ border: '2px solid black', padding: '8px', textAlign: 'center' }}>{entry.starsCollected}</td>
                  <td style={{ border: '2px solid black', padding: '8px', textAlign: 'center' }}>{entry.totalStars}</td>                
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default StarsRecordDash;
