import React, { useState } from "react";

const PirateQuizReady = ({ onNext, carrier }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className="flex flex-col items-start p-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">Pirate Quiz</h2>
      <p className="text-2xl mb-8">
        Master at Arms {carrier.firstName}, your pirate task is to answer 3 very
        important questions.
      </p>
      <p className="text-2xl mb-6">Are you ready?</p>
      {ready ? (
        <p className="text-green-600 font-semibold">Started!</p>
      ) : (
        <button
          className="px-4 py-2 bg-green-600 text-white text-lg font-bold rounded-lg hover:bg-green-700 transition duration-200"
          onClick={handleStart}
        >
          Yes, Let’s Go!
        </button>
      )}
    </div>
  );
};

export default PirateQuizReady;
