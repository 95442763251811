import React, { useState } from "react";
import CorrectAnswer from "./CorrectAnswer"; 
import IncorrectAnswer from "./IncorrectAnswer"; 

const TalkingToDoctor = ({ carrier, setSection, section }) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

  const handleOptionClick = (option) => {
    // Toggle option selection
    setSelectedAnswers((prev) =>
      prev.includes(option)
        ? prev.filter((answer) => answer !== option)
        : [...prev, option]
    );
  };

  const handleSubmit = () => {
    setIsSubmitted(true);

    // Check if the answer is correct
    const isCorrect =
      selectedAnswers.includes("a") &&
      selectedAnswers.includes("c") &&
      selectedAnswers.length === 2;

    if (isCorrect) {
      setShowCorrectAnswer(true);
    } else {
      setAttempts((prev) => prev + 1);
      if (attempts + 1 >= 3) {
        setShowCorrectAnswer(true); // Show correct answer after 3 incorrect attempts
      }
    }
  };

  const isCorrectAnswer = (option) => option === "a" || option === "c";

  const resetQuiz = () => {
    setSelectedAnswers([]);
    setIsSubmitted(false);
    setAttempts(0);
    setShowCorrectAnswer(false);
    setSection(section - 1);
  };

  if (isSubmitted && showCorrectAnswer) {
    return <CorrectAnswer carrier={carrier} />;
  } else if (isSubmitted && !showCorrectAnswer) {
    return <IncorrectAnswer attempts={attempts} resetQuiz={resetQuiz} />;
  }

  return (
    <div className="flex flex-col items-start p-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">Talking to Your Doctor</h2>
      <p className="text-xl mb-4">
        When you go to the doctor it is important to tell the doctor how you are
        feeling.
      </p>
      <p className="text-xl mb-4">
        The doctor might ask you{" "}
        <span className="font-semibold">How are you feeling?</span>
      </p>
      <p className="text-xl mb-6">How do you tell a doctor you are in pain?</p>
      <p className="text-green-600 italic text-xl mb-4">
        • Click on all the correct ones (hint: there are 2 correct options):
      </p>

      <ol className="list-[lower-alpha] pl-6 text-xl space-y-2">
        <li
          onClick={() => handleOptionClick("a")}
          className={`cursor-pointer ${
            isSubmitted && isCorrectAnswer("a") ? "bg-yellow-300" : ""
          } ${selectedAnswers.includes("a") ? "bg-yellow-200" : ""}`}
        >
          Point to the face on the pain scale to show how much something hurts
        </li>
        <li
          onClick={() => handleOptionClick("b")}
          className={`cursor-pointer ${
            isSubmitted &&
            !isCorrectAnswer("b") &&
            selectedAnswers.includes("b")
              ? "bg-red-200"
              : ""
          } ${selectedAnswers.includes("b") ? "bg-yellow-200" : ""}`}
        >
          Laugh at the doctor
        </li>
        <li
          onClick={() => handleOptionClick("c")}
          className={`cursor-pointer ${
            isSubmitted && isCorrectAnswer("c") ? "bg-yellow-300" : ""
          } ${selectedAnswers.includes("c") ? "bg-yellow-200" : ""}`}
        >
          Point to the body part that hurts
        </li>
      </ol>

      {/* Submit Button */}
      {!showCorrectAnswer && (
        <button
          onClick={handleSubmit}
          className="mt-6 px-4 py-2 bg-green-500 text-white font-bold rounded hover:bg-green-600 transition"
          disabled={isSubmitted}
        >
          Submit
        </button>
      )}      

      {/* Reset Button to allow reattempt after incorrect answers */}
      {showCorrectAnswer && attempts < 3 && (
        <button
          onClick={resetQuiz}
          className="mt-4 px-4 py-2 bg-gray-500 text-white font-bold rounded hover:bg-gray-600 transition"
        >
          Try Again
        </button>
      )}
    </div>
  );
};

export default TalkingToDoctor;
