import React, { useState, useRef } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import face1Audio from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2Audio from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3Audio from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4Audio from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5Audio from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6Audio from "../../assets/audios/speech/PainDiary/Face6.mp3";

const audioFiles = [face1Audio, face2Audio, face3Audio, face4Audio, face5Audio, face6Audio];

const UsingPainScale = () => {
  const [currentAudio, setCurrentAudio] = useState(null);
  const audioRef = useRef(null);

  const handlePainAudio = (index) => {
    setCurrentAudio(audioFiles[index]);
  };

  React.useEffect(() => {
    if (currentAudio) {
      audioRef.current.src = currentAudio;
      audioRef.current.play();
    }
  }, [currentAudio]);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Using a Pain Scale
      </h2>
      <div className="text-gray-700 text-base font-gillSans mr-11">
        <ol>
          <li className="mt-1 lg:text-xl">
            When you are in pain, you can use a picture of faces (pain scale) to tell your mum/dad or teacher that you are in pain.
          </li>
          <li className="mt-1 lg:text-xl">Each face shows how much it hurts.</li>
          <li className="mt-1 lg:text-xl">
            Your turn: Click on the different faces to hear how much it hurts.
          </li>
          <div className="flex mt-10 justify-around items-center fadeIn gap-5 max-w-3xl">
            {[painScale0, painScale2, painScale4, painScale6, painScale8, painScale10].map((src, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={src}
                  alt={`pain-scale-${index}`}
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                  onClick={() => handlePainAudio(index)}
                />
                <span className="mt-2 text-center font-gillSans">
                  {index * 2} <br />
                  {index === 0 && "No hurt"}
                  {index === 1 && "Hurts a little bit"}
                  {index === 2 && "Hurts a little more"}
                  {index === 3 && "Hurts even more"}
                  {index === 4 && "Hurts a whole lot"}
                  {index === 5 && "Hurts the worst"}
                </span>
              </div>
            ))}
          </div>
        </ol>
      </div>
      <audio ref={audioRef} />
    </div>
  );
};

export default UsingPainScale;
