import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PainBadges from "./PainBadges";
import PainPassport from "./PainPassport";
import PainScale from "./PainScale";
import PirateBadges from "./PirateBadges";
import TummyBreathing from "./TummyBreathing";
import UnderWater from "../../../assets/UnderWater.jpg";
import Videos from "./Videos";

const Resources = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Pain Badges":
        return <PainBadges />;
      case "Pain Passport":
        return <PainPassport />;
      case "Pain Scale":
        return <PainScale />;
      case "Pirate Badges":
        return <PirateBadges />;
      case "Tummy Breathing":
        return <TummyBreathing />;
      case "Videos":
        return <Videos />;
      default:
        return <PainBadges />;
    }
  };

  return (
    <div
      className="h-screen relative"
      style={{
        backgroundImage: `url(${UnderWater})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#2cdee2",
      }}
    >
      {/* Keep the header at the top */}
      <header className="bg-[#267e8c] w-full absolute top-0">
        <ol className="flex flex-row justify-evenly gap-7 px-6 py-6 bg-opacity-50 backdrop-filter backdrop-blur-lg zoomer">
          <button
            className="rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
            onClick={handleBackClick}
            style={{
              backgroundColor: "",
              color: "#ffffff",
            }}
          >
            <svg
              className="w-5 h-5 hover:text-black transform rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </button>
          <button
            onClick={() => setActiveComponent("Pain Badges")}
            className={`text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out ${
              activeComponent === "Pain Badges" ? "text-black" : ""
            }`}
          >
            Pain Badges
          </button>
          <button
            onClick={() => setActiveComponent("Pain Passport")}
            className={`text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out ${
              activeComponent === "Pain Passport" ? "text-black" : ""
            }`}
          >
            Pain Passport
          </button>
          <button
            onClick={() => setActiveComponent("Pain Scale")}
            className={`text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out ${
              activeComponent === "Pain Scale" ? "text-black" : ""
            }`}
          >
            Pain Scale
          </button>
          <button
            onClick={() => setActiveComponent("Pirate Badges")}
            className={`text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out ${
              activeComponent === "Pirate Badges" ? "text-black" : ""
            }`}
          >
            Pirate Badges
          </button>
          <button
            onClick={() => setActiveComponent("Tummy Breathing")}
            className={`text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out ${
              activeComponent === "Tummy Breathing" ? "text-black" : ""
            }`}
          >
            Tummy Breathing
          </button>
          <button
            onClick={() => setActiveComponent("Videos")}
            className={`text-white text-lg font-semibold hover:text-black transition duration-300 ease-in-out ${
              activeComponent === "Videos" ? "text-black" : ""
            }`}
          >
            Videos
          </button>
        </ol>
      </header>

      {/* Component displayed below buttons */}
      <div className="p-6 flex justify-center pt-24">{renderComponent()}</div>
    </div>
  );
};

export default Resources;
