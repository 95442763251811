import React, { useState, useEffect, useRef } from "react";
import treasureChest from "../../assets/miscellaneous/specialTool.png";
import noHurtFace from "../../assets/pain scale/0.png";
import teachersHelp from "../../assets/miscellaneous/teachers.png";
import noMorePain from "../../assets/miscellaneous/doc.png";
import SpecialTool from "./SpecialTool";
import FeelBetter from "./FeelBetter";
import TeachersHelp from "./TeachersHelp";
import NoMorePain from "./NoMorePain";

const PiratePainScaleGuide = ({ setIsOnChildComponent, audioRef }) => {
  const [currentStep, setCurrentStep] = useState(null); // Initially, no child component is selected
  const [currentChild, setCurrentChild] = useState(null); // Track the active child component
  const [completedSteps, setCompletedSteps] = useState([]); // To track completed steps  

  // Function to handle child navigation
  const handleChildNavigation = (childName) => {
    
      audioRef.current.pause(); // Stop parent audio if playing
      audioRef.current.currentTime = 0;
    
    setCurrentChild(childName); // Set selected child
    console.log("childName: ", childName);
    if(childName === "NoMorePain"){    
      sessionStorage.setItem("PiratePainScaleGuideCompleted", "true");
    }
  };
  
  // When a child component is active, disable the forward arrow
  useEffect(() => {
    if (currentChild) {
      setIsOnChildComponent(true); // Disable forward arrow
    }
  }, [currentChild, setIsOnChildComponent]);

  // After completing a step, show the parent component again with next item highlighted
  const handleNext = (step) => {
    
    if(step === 3){    
      sessionStorage.setItem("PiratePainScaleGuideCompleted", "true");
    }
    setCompletedSteps([...completedSteps, step]);
    console.log("completedSteps: ", completedSteps);
    
    setCurrentChild(null); // Go back to parent component (image grid)
    setIsOnChildComponent(false); // Enable forward arrow    
  };  

  // Handle Floater class for highlighting the next step
  const isFloater = (index) => index === completedSteps.length;

  // Render the child component if one is selected, otherwise render the parent component
  if (currentStep !== null) {    
    switch (currentStep) {
      case "special-tool":
        return <SpecialTool onNext={() => handleNext(0)} />;
      case "feel-better":
        return <FeelBetter onNext={() => handleNext(1)} />;
      case "teachers-help":
        return <TeachersHelp onNext={() => handleNext(2)} />;
      case "no-more-pain":
        return <NoMorePain onNext={() => handleNext(3)} />;
      default:
        return null;
    }
  }  

  // Parent component that shows the images
  return (
    <div className="flex flex-col items-center text-center p-10 mt-10 mr-10">
      <div>
        {/* If no child is active, show the image grid */}
        {currentChild === null ? (
          <>
            {/* Title and Description */}
            <h1 className="text-3xl font-bold mb-4">
              A Pirate’s Guide to Understanding the Pain Scale
            </h1>
            <p className="text-xl mb-6">
              Click into the 4 pictures below to learn why it’s important to use
              the pain scale.
            </p>

            {/* Image Grid */}
            <div className="grid grid-cols-2 gap-8">
              {/* Special Tool */}
              <div
                className={`flex flex-col items-center cursor-pointer ${
                  isFloater(0) ? "floater" : ""
                }`}
                onClick={() => handleChildNavigation("SpecialTool")}
              >
                <img
                  src={treasureChest}
                  alt="Special Tool"
                  className="w-32 h-auto mb-2"
                />
                <span className="text-xl font-bold">Special Tool</span>
              </div>
              {/* Feel Better */}
              <div
                className={`flex flex-col items-center cursor-pointer ${
                  isFloater(1) ? "floater" : ""
                }`}
                onClick={() => handleChildNavigation("FeelBetter")}
              >
                <img
                  src={noHurtFace}
                  alt="Feel Better"
                  className="w-28 h-auto mb-2"
                />
                <span className="text-xl font-bold">Feel Better</span>
              </div>
              {/* Teachers Can Help */}
              <div
                className={`flex flex-col items-center cursor-pointer ${
                  isFloater(2) ? "floater" : ""
                }`}
                onClick={() => handleChildNavigation("TeachersHelp")}
              >
                <img
                  src={teachersHelp}
                  alt="Teachers Can Help"
                  className="w-28 h-auto mb-2"
                />
                <span className="text-xl font-bold">Teachers Can Help</span>
              </div>
              {/* No More Pain */}
              <div
                className={`flex flex-col items-center cursor-pointer ${
                  isFloater(3) ? "floater" : ""
                }`}
                onClick={() => handleChildNavigation("NoMorePain")}
              >
                <img
                  src={noMorePain}
                  alt="No More Pain"
                  className="w-28 h-auto mb-2"
                />
                <span className="text-xl font-bold">No More Pain</span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center text-center p-10 mt-10 mr-10">
            {currentChild === "SpecialTool" && (
              <SpecialTool onNext={handleNext} />
            )}
            {currentChild === "FeelBetter" && (
              <FeelBetter onNext={handleNext} />
            )}
            {currentChild === "TeachersHelp" && (
              <TeachersHelp onNext={handleNext} />
            )}
            {currentChild === "NoMorePain" && (
              <NoMorePain onNext={handleNext} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PiratePainScaleGuide;
