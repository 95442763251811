import React from "react";
import drReily from "../../assets/Characters/Riley.png";
import painScale from "../../assets/pain scale/Pain scale.png"; // Wong-Baker pain scale image
import noPainFace from "../../assets/pain scale/0.png"; // No Pain face image

const DoctorRiley = ({carrier}) => {
  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0 overflow-auto">
      <div className="flex flex-col lg:flex-row items-start w-full max-w-4xl rounded-lg p-5 lg:p-10">
        {/* Doctor Image */}
        <div className="w-full lg:w-1/3 flex justify-center lg:justify-start lg:mb-0">
          <img src={drReily} alt="Dr. Riley" className="w-60 h-auto rounded-lg shadow-lg mt-20" />
        </div>

        {/* Text Section */}
        <div className="w-full lg:w-2/3 text-gray-800 text-lg font-gillSans lg:ml-6 mr-5">
          <h1 className="text-3xl font-bold mb-4 text-center lg:text-left text-gray-800">
            Doctor Riley
          </h1>

          <p className="mb-2 text-xl">
            Hi <span className="font-semibold font-gillSans">{carrier.firstName}</span>, my name is Doctor Reilly.<br/> My job is to make people <span className="text-green-600 font-bold">feel better!</span>
          </p>
          <p className="mb-2 text-xl font-gillSans">I am one of Captain Charlie’s friends.</p>
          <p className="mb-2 text-xl font-gillSans">
            I am going to show you how to tell your doctor how you are feeling using your pain scale.
          </p>
          
          {/* Pain Scale Image */}
          <div className="flex justify-center lg:justify-start my-4">
            <img
              src={painScale}
              alt="Wong-Baker Pain Rating Scale"
              className="w-auto h-16"
            />
          </div>

          <p className="mb-2 text-xl font-gillSans">
            Just like when your mum/dad ask <span className="font-bold text-green-600">‘how you are feeling?’</span> <br/> and you can point to the face that shows how much pain you have.
          </p>

          <p className="mb-2 text-xl font-gillSans">
            When Captain Charlie and his crew tell me <br/> they have pain, I help them <span className="text-green-600 font-bold">feel better</span>.
          </p>

          {/* No Pain Face */}
          <div className="flex items-center mt-4">
            <img src={noPainFace} alt="No Pain Face" className="w-8 h-8 mr-2" />
            <p className="text-gray-700 text-xl font-gillSans">No Pain</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorRiley;
