import React from "react";

const NeedHelp = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        If You Need Help
      </h2>
      <div className="text-base font-gillSans mx-5">
        <ul>
          <li className=" mt-8 ">
            It is important to ask for Help if you need it.{" "}
            <button className="ml-2 px-8 py-1 rounded-md bg-[#EE6352] text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]">
              Help
            </button>
          </li>
          <li className=" mt-8 ">
            You ask press the help button at the top of the screen or you can
            ask your mum/dad or teacher.
          </li>
          <li className=" mt-8 ">
            Captain Charlie says asking for Help is a key role in becoming a
            Captain.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NeedHelp;
