import React from "react";
import drReily from "../../assets/Characters/Riley.png"; // Ensure the path to Dr. Reilly's image is correct

const DoctorTalks = ({ carrier }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center p-10 max-w-4xl mx-auto">
      {/* Image Section */}
      <div className="w-full lg:w-1/3 flex justify-center lg:justify-start mb-6 lg:mb-0">
        <img
          src={drReily}
          alt="Dr. Reilly"
          className="w-60 h-auto rounded-lg shadow-lg"
        />
      </div>

      {/* Text Section */}
      <div className="w-full lg:w-2/3 text-left lg:pl-8">
        <h2 className="text-3xl font-bold mb-4">Doctor Talks: Your Turn</h2>
        <p className="text-xl mb-4">
          <span className="font-semibold">{carrier.firstName}</span>, you have been given a special task.
        </p>
        <p className="text-xl mb-4">
          Doctor Reilly needs your help.
        </p>
        <p className="text-xl mb-4">
          Annie is in pain.
        </p>
        <p className="text-xl mb-4">
          Listen to Annie's story and then tell Doctor Reilly <br/> where Annie is hurting and how she is feeling.
        </p>
        <p className="text-xl mb-4 font-semibold">
          This is an extra special task. You will receive a special surprise after you help Doctor Reilly.
        </p>
      </div>
    </div>
  );
};

export default DoctorTalks;
