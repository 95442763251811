import React, { useState, useEffect } from "react";
import captainCharlie from "../../assets/cptCharlie.png"; // Import Captain Charlie image
import Correct1 from "./Correct1";
import Incorrect from "./TryAgain";

const TaskPage = ({carrier, audioRef}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]); // Tracks selected answers
  const [isCorrect, setIsCorrect] = useState(null); // null means no selection, true for correct, false for incorrect
  const [attempts, setAttempts] = useState(0); // Tracks the number of incorrect attempts
  const [showFeedback, setShowFeedback] = useState(false); 

  const handleAnswerSelection = (answer) => {
    // If two options are already selected, do nothing
    if (selectedAnswers.length === 2) return;

    // Add selected answer to the list
    setSelectedAnswers((prev) => {
      const updatedAnswers = [...prev, answer];

      // Check for correct combination when two answers are selected
      if (updatedAnswers.length === 2) {
        if (
          updatedAnswers.includes("a") &&
          updatedAnswers.includes("c")
        ) {
          setIsCorrect(true);
        } else {
          setIsCorrect(false);
          setAttempts((prevAttempts) => prevAttempts + 1);
        }
        // Delay showing the feedback component by 1.5 seconds
        setTimeout(() => setShowFeedback(true), 1500);
      }
      return updatedAnswers;
    });
  };

  const resetQuiz = () => {
    // Resets the quiz state for a new attempt
    setSelectedAnswers([]);
    setIsCorrect(null);
    setShowFeedback(false);
  };

  // If the task is completed, show the Correct1 or Incorrect component after 2 seconds
  if (showFeedback) {
    return isCorrect ? <Correct1 carrier={carrier} audioRef={audioRef}/> : <Incorrect onTryAgain={resetQuiz} audioRef={audioRef}/>;
  }

  return (
    <div className="flex flex-col items-center p-4">
      <h2 className="text-3xl font-bold mb-2 mt-5">Task</h2>
      <p className="text-xl mb-4">
        Excellent work learning all about how a doctor can help you feel better.
      </p>
      <p className="text-xl mb-4">
        Can you tell Captain Charlie what are the 2 special skills that a doctor has?
      </p>

      <div className="flex items-center gap-8">
        {/* Text Section */}
        <div>
          <ol className="list-decimal pl-6 space-y-2 text-xl">
            <li
              className={`cursor-pointer ${
                selectedAnswers.includes("a") || (attempts >= 3 && "bg-yellow-500")
                  ? "bg-yellow-500"
                  : ""
              }`}
              onClick={() => handleAnswerSelection("a")}
            >
              Help make you feel better
            </li>
            <li
              className={`cursor-pointer ${
                selectedAnswers.includes("b") ? "bg-yellow-200" : ""
              }`}
              onClick={() => handleAnswerSelection("b")}
            >
              Do your homework for you
            </li>
            <li
              className={`cursor-pointer ${
                selectedAnswers.includes("c") || (attempts >= 3 && "bg-yellow-500")
                  ? "bg-yellow-500"
                  : ""
              }`}
              onClick={() => handleAnswerSelection("c")}
            >
              Find out why your body part hurts
            </li>
          </ol>
        </div>

        {/* Image Section */}
        <img
          src={captainCharlie}
          alt="Captain Charlie"
          className="w-64 h-auto"
        />
      </div>

      {/* Conditional Rendering of Feedback */}
      {isCorrect === true && !showFeedback && (
        <div className="mt-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700">
          <p>
            Correct! A doctor can help make you feel better and find out why
            your body part hurts.
          </p>          
        </div>
      )}
      
      {isCorrect === false && !showFeedback && (
        <div className="mt-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
          <p>
            Incorrect. Please try again! A doctor cannot do your homework for
            you.
          </p>
          <button onClick={resetQuiz} className="mt-2 p-2 bg-blue-500 text-white rounded">
            Try Again
          </button>
        </div>
      )}
    </div>
  );
};

export default TaskPage;
