import React from "react";

const Introduction = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        What is Feeling Better ASD?
      </h2>
      <div className="text-center mr-4 font-gillSans">
        <ul>
          <li className=" mt-8 lg:text-lg md:text-lg">
            Feeling Better ASD will teach you all about pain and how to cope
            with pain.
          </li>
          <li className=" mt-8 lg:text-lg md:text-lg">
            The programme is set on a Pirate Ship and during the programme you
            will meet Captain Charlie and his crew.
          </li>
          <li className=" mt-8 lg:text-lg md:text-lg">
            It is your mission to is to complete each mission and gain your
            ranks to become a captain, like Captain Charlie".
          </li>
          <li className=" mt-8 lg:text-lg md:text-lg">
            You will learn fun activities that will help you feel better when
            you have pain.
          </li>
          <li className=" mt-8 lg:text-lg md:text-lg">
            There will be lots of videos and fun surprises throughout the
            programme.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Introduction;
