import React, { useState, useEffect } from "react";
import AnimatedAttemptCounter from "../AnimatedAttemptCounter ";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import pg50Q2 from "../../assets/audios/speech/Mission6/pg50Q2.mp3";
const QuestionTwo = ({ onNext }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const correctAnswer = "a";

  useEffect(() => {    
    const audio = new Audio(pg50Q2);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  const handleAnswerClick = (answer) => {
    if (attempts < 3) {
      setSelectedAnswer(answer);
      if (answer === correctAnswer) {
        setTimeout(() => {
            onNext();
        }, 1000);
      } else {
        setAttempts(attempts + 1);
      }
    }
  };

  const getAnswerStyle = (answer) => {
    if (attempts >= 3 && answer === correctAnswer) return "bg-yellow-400";
    return selectedAnswer === answer ? "bg-green-400" : "";
  };

  return (
    <div>
      <p className="text-xl mb-4">
        2. How do we tell a doctor we are in pain?
      </p>
      <ol className="list-[lower-alpha] pl-6 space-y-2 text-xl">
        <li
          onClick={() => handleAnswerClick("a")}
          className={`cursor-pointer ${getAnswerStyle("a")}`}
        >
          Point to the face on the pain scale to show how much something hurts
          {selectedAnswer === "a" && <img src={greenTick} alt="Incorrect" className="w-6 h-6 inline-block ml-2" />}
        </li>
        <li
          onClick={() => handleAnswerClick("b")}
          className={`cursor-pointer ${getAnswerStyle("b")}`}
        >
          Laugh at the doctor
          {selectedAnswer === "b" && <img src={redCross} alt="Correct" className="w-6 h-6 inline-block ml-2" />}
        </li>
        <li
          onClick={() => handleAnswerClick("c")}
          className={`cursor-pointer ${getAnswerStyle("c")}`}
        >
          Don’t speak to the doctor
          {selectedAnswer === "c" && <img src={redCross} alt="Incorrect" className="w-6 h-6 inline-block ml-2" />}
        </li>
      </ol>
      <div className="flex justify-center mt-5">
        <AnimatedAttemptCounter incorrectAttempts={attempts} />
      </div>
    </div>
  );
};

export default QuestionTwo;
