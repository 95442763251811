import React, { useState, useEffect, useRef } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale8 from "../../assets/pain scale/8.png";
import polly from "../../assets/Characters/SailorPolly.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Polly = ({ audio1, audio2, carrier }) => {
  const [selectedFace, setSelectedFace] = useState(null);
  const [greenClicked, setGreenClicked] = useState(false);
  const [orangeClicked, setOrangeClicked] = useState(false);
  const audioRef = useRef(null); // Create a ref to track the audio instance

  // Create refs for both audio instances
  const audioRefGreen = useRef(new Audio(audio1));
  const audioRefOrange = useRef(new Audio(audio2));

  // Play coins audio when either section is loaded
  useEffect(() => {
    if (selectedFace === "green" || selectedFace === "orange") {
      const audio = new Audio(coins);
      audio.play();
    }
  }, [selectedFace]);

  const handleSubmit = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M4",
          pageNum: 22,
          activityId: "M4Polly",
          activityValue: JSON.stringify({
            question: "Quiz",
            result: "correct",
          }),
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  // Function to handle face clicks
  const handleFaceClick = (face) => {
    // Stop both audios first
    if (audioRefGreen.current) {
      audioRefGreen.current.pause();
      audioRefGreen.current.currentTime = 0; // Reset the green audio
    }
    if (audioRefOrange.current) {
      audioRefOrange.current.pause();
      audioRefOrange.current.currentTime = 0; // Reset the orange audio
    }

    if (face === "green") {
      // Play green face audio
      setSelectedFace("green");
      audioRefGreen.current.play();

      setGreenClicked(true);
      handleSubmit();
    } else if (face === "orange" && greenClicked) {
      // Play orange face audio
      setSelectedFace("orange");
      audioRefOrange.current.play();

      setOrangeClicked(true);
      handleSubmit();
    } else if (face === "orange" && !greenClicked) {
      toast.warning("Please click the green face first!");
    }
  };

  // Function to handle the "Back" button click
  const handleBackClick = () => {
    setSelectedFace(null);
  };

  // Mark session storage complete if both faces have been clicked
  useEffect(() => {
    if (greenClicked && orangeClicked) {
      sessionStorage.setItem("pollyCompleted", "true");
    }
  }, [greenClicked, orangeClicked]);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <ToastContainer position="top-right" autoClose={3000} />
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Sailor Polly's Pain
      </h2>

      {selectedFace === null && (
        <div className="text-gray-700 text-base font-gillSans">
          <ol className="text-xl">
            <li className="mt-4 text-center">
              Have a listen to Polly talking about a time she was not in pain
              and a time she was in pain.
            </li>
            <li className="mt-4">
              Click onto the{" "}
              <span className="text-green-600 font-bold">green</span> face to
              hear when she was happy-
            </li>
            <li>
              <div className="flex items-center flex-col">
                <img
                  src={painScale0}
                  alt="pain-scale"
                  className={`w-16 h-auto hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer ${
                    greenClicked ? "grayscale" : ""
                  }`}
                  onClick={() => handleFaceClick("green")}
                />
                <span className="mt-2 text-center font-gillSans">
                  0 <br />
                  No hurt
                </span>
              </div>
            </li>
            <li className="mt-4">
              Click onto the{" "}
              <span className="text-orange-600 font-bold">orange</span> face to
              hear when she had pain-
            </li>
            <li>
              <div className="flex items-center flex-col">
                <img
                  src={painScale8}
                  alt="pain-scale"
                  className={`w-16 h-auto hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer ${
                    orangeClicked ? "grayscale" : ""
                  }`} // Orange face becomes grayscale only after being clicked
                  onClick={() => handleFaceClick("orange")}
                />
                <span className="mt-2 text-center font-gillSans">
                  8 <br />
                  Hurts whole lot
                </span>
              </div>
            </li>
          </ol>
        </div>
      )}

      {/* Green face section */}
      {selectedFace === "green" && (
        <div className="fadeIn text-center mt-10 font-gillSans">
          <div className="flex flex-row justify-around">
            <div>
              <img src={polly} alt="polly" className="w-40" />
            </div>
            <div>
              <div className="-mt-6 flex flex-row items-center justify-center gap-2">
                <h2 className="text-xl font-bold mb-2 text-center text-green-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
                  No Pain
                </h2>
                <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
              </div>
              <ul className="lg:text-xl text-base text-center">
                <li className="mt-2">
                  I went to the park one day with my dad.
                </li>
                <li className="mt-2">
                  We were playing on the swings. I was really happy.
                </li>
                <li className="mt-2">
                  Just like the happy face on the pain scale.
                </li>
                <li className="mt-2">
                  This means I don't feel any pain at all.
                </li>
                <li className="mt-2">
                  <div className="flex items-center flex-col">
                    <img src={painScale0} className="w-10" alt="pain-scale" />
                    <span className="mt-2 text-center font-gillSans">
                      0 <br />
                      No hurt
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <button
            className="lg:text-lg px-4 py-2 mt-4 w-32 bg-green-600 text-white rounded-md hover:bg-green-400"
            onClick={handleBackClick}
          >
            Back
          </button>
        </div>
      )}

      {/* Orange face section */}
      {selectedFace === "orange" && (
        <div className="fadeIn text-center mt-4">
          <div className="flex flex-row justify-around">
            <div>
              <img src={polly} alt="polly" className="w-60 mt-5" />
            </div>
            <div className="ml-5 -mt-3">
              <div className="flex flex-row items-center justify-center gap-2">
                <h2 className="text-xl font-bold mb-2 text-center text-orange-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
                  Hurts whole lot
                </h2>
                <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
              </div>
              <ol className="lg:text-xl text-base text-center">
                <li className="mt-2">
                  One time I had a bad tummy pain and all I wanted to do was lie
                  on my bed. 
                </li>
                <li className="mt-2">
                  I was very sad just like the orange face on the pain scale.
                </li>
                <li className="mt-2">
                  This face is for when a body part hurts a lot. 
                </li>
                <li className="mt-2">
                  <div className="flex items-center flex-col">
                    <img src={painScale8} className="w-20" alt="pain-scale" />
                    <span className="mt-2 text-center font-gillSans">
                      8 <br />
                      Hurts whole lot
                    </span>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <button
            className="lg:text-lg px-4 py-2 mt-2 w-32 bg-orange-600 text-white rounded-md hover:bg-orange-400"
            onClick={handleBackClick}
          >
            Back
          </button>
        </div>
      )}
    </div>
  );
};

export default Polly;
