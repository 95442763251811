import React, { useState } from 'react';
import PainDiaryDash from './PainDiaryDash';
import StarsRecordDash from './StarsRecordDash';
import UnderWater from "../../../assets/UnderWater.jpg";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
    const [Pain, setPain] = useState(false);
    const [Star, setStar] = useState(false);
    const navigate = useNavigate();
    const handlePain = () => {
        setPain(true);
        setStar(false);
    };

    const handleStar = () => {
        setStar(true);
        setPain(false);
    };
    const signUpHandler = () => {
        navigate("/sign-up");
      };


    return (        
        <div>
            <div
        className="h-screen relative flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(${UnderWater})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#2cdee2",
        }}
      >
            <div className='flex flex-col mb-10'>
                <div className="btn-grp self-center mt-4 flex gap-2">
                    <button
                        className={`rounded p-2 ${Pain ? 'bg-yellow-400' : 'bg-gray-300'}`}
                        onClick={handlePain}
                    >
                        Pain Diary
                    </button>
                    <button
                        className={`rounded p-2 ${Star ? 'bg-yellow-400' : 'bg-gray-300'}`}
                        onClick={handleStar}
                    >
                        Star Dashboard
                    </button>
                    <button
                        className={`rounded p-2 bg-green-400 `}
                        onClick={signUpHandler}
                    >
                        Signup
                    </button>
                </div>
                {Pain ? <PainDiaryDash /> : <StarsRecordDash />}
            </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
