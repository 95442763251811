import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import BodyMap from "../BodyMapNew";
import axios from "axios";
import React, { useCallback, useEffect, useState, useRef } from "react";
import armAudio from "../../assets/audios/Arm.mp3";
import headAudio from "../../assets/audios/Head.mp3";
import footAudio from "../../assets/audios/Legs.mp3";
import mouthAudio from "../../assets/audios/Mouth.mp3";
import stomachAudio from "../../assets/audios/Tummy.mp3";

const DrRileyNeedsHelp = ({ submitted, setSubmitted, carrier }) => {
  const [answer, setAnswer] = useState("");
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const playNextAudio = useCallback(() => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    const nextAudioPath = audioQueue[0];
    const nextAudio = new Audio(nextAudioPath);

    nextAudio.play();
    setAudioQueue((queue) => queue.slice(1));

    nextAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [audioQueue]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      setIsPlaying(true);
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);
  const handleImageClick = (audioPath) => {
    setAudioQueue((queue) => [...queue, audioPath]);
  };
  const audioMap = {
    Head: headAudio,
    Stomach: stomachAudio,
    Mouth: mouthAudio,
    "Left Arm": armAudio,
    "Right Arm": armAudio,
    Legs: footAudio,
  };

  const handleAreaClick = async (areaTitle) => {
    const audioFile = audioMap[areaTitle];

    if (audioMap[areaTitle]) {
      handleImageClick(audioMap[areaTitle]);
    }

    let activityValue;
    if (areaTitle === "Head") {
      setAnswer("correct");
      activityValue = "Correct : " + areaTitle;
      console.log("activityValue", activityValue);
    } else {
      setAnswer("incorrect");
      activityValue = "Incorrect : " + areaTitle;
      console.log("activityValue", activityValue);
    }
    setSubmitted(true);
    // Hit the API to save mission activity
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M6",
          pageNum: 44,
          activityId: "M6DrRileyTaskBodyMap",
          activityValue: activityValue,
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };
  return (
    <div className="flex flex-col items-center p-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-2 mt-4">
        Doctor Riley Needs Your Help
      </h2>
      <p className="text-xl mb-6 text-center">
        After watching Annie's video can you tell Doctor Reilly:
      </p>
      <div className="flex flex-row items-start justify-between w-full">
        {/* Left Side: Text */}
        <div className="w-1/2 text-left text-2xl text-gray-700 font-gillSans pr-4">
          Q1. What body part hurts Annie?
        </div>

        {/* Right Side: BodyMap and feedback */}
        <div className="w-1/2 flex flex-col items-center">
          <BodyMap
            onAreaClick={handleAreaClick}
            carrier={carrier}
            submitted={submitted}
            setSubmitted={setSubmitted}
          />
        </div>
        <div className="flex flex-row items-center justify-center">         
          {/* Conditionally render the green tick or red cross */}
          {answer === "correct" && (
            <img
              src={greenTick}
              alt="Correct Answer"
              className="w-20 h-20 mt-4 bounceIn"
            />
          )}
          {answer === "incorrect" && (
            <img
              src={redCross}
              alt="Incorrect Answer"
              className="w-20 h-20 mt-4 shake"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DrRileyNeedsHelp;
