import React from "react";
import starIcon from "../assets/star.png"; // Make sure to replace this with the actual path to your star icon

const ProgressBar = ({ currentSection, totalSections }) => {
  // Define how many stars to display
  const maxStars = 20;
  const starInterval = Math.ceil(totalSections / maxStars); // Interval between each star
  const progress = (currentSection / totalSections) * 100;

  return (
    <div
      className="relative w-full bg-[#8b5e3c] h-6 rounded-full overflow-hidden"
      style={{ width: "90%", maxWidth: "800px", margin: "0 auto" }}
    >
      <div
        className="bg-[#76c7c0] h-full transition-width duration-500 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
      {/* Star Icons */}
      <div className="absolute top-0 left-0 h-full flex items-center justify-between w-full px-2">
        {[...Array(maxStars)].map((_, index) => {
          const sectionForStar = (index + 1) * starInterval;
          return (
            <img
              key={index}
              src={starIcon}
              alt="star"
              className={`w-6 h-6 ${
                sectionForStar <= currentSection ? "opacity-100" : "opacity-50"
              }`}
              style={{ marginLeft: index === 0 ? "0" : "-12px" }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
