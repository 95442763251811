import React, { useEffect } from "react";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Correct = ({handleSpeech}) => {
  useEffect(() => {    
    const audio = new Audio(coins);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  

  return (
    <div className=" flex max-w-lg flex-col bounceIn">
      <h4 className=" text-3xl mt-5 text-green-500">Correct Answer!</h4>
      <ol className="text-xl mt-5">
        <li>Yes that's correct! The tummy hurts.</li>
        <li className="mt-5">
          <img src={coin} className="w-24 spinner" alt="coin" />
        </li>
      </ol>
    </div>
  );
};

export default Correct;
