import React from "react";
import coinIcon from "../../assets/Icons/Coin.png"; // Icon for coin

const CheckUpPage = () => {
  return (
    <div className="flex flex-col items-center p-8 max-w-4xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between w-full items-center">
        <h2 className="text-3xl font-bold mb-2 text-center flex items-center justify-center mb-16">Check-Ups with the Doctor
          <img src={coinIcon} alt="Coin Icon" className="w-12 ml-4 heartbeat" />                         
        </h2>
      </div>

      {/* Content Section */}
      <div className="text-left text-xl space-y-4">
        <p>Sometimes you might go to the doctor for a check-up.</p>
        <p>
          A check-up is when you visit a doctor to make sure you are healthy and feeling good.
        </p>
        <p>
          The doctor might ask you <span className="font-bold text-2xl">How are you Feeling?</span>
        </p>
        <p>
          Check-ups are very important. Remember doctors have a special skill to help us <br/>feel better.
        </p>
      </div>
    </div>
  );
};

export default CheckUpPage;
