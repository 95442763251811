import React, { useEffect, useRef } from "react";
import painScale from "../../assets/pain scale/Pain scale.png";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";
import mateyMorganAudio from "../../assets/audios/mateyMorganAudio.mp3";
import coin from "../../assets/Icons/Coin.png";

const MateyMorgan2 = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">      
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Matey Morgan
          <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />      
        </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="mr-10 lg:text-lg">
          <li className="mt-2">
            Hi, My name is Matey Morgan. I am one of Captain Charlie's Friends.
            Captain Charlie has set me a mission to tell you about{" "}
            <span className=" text-green-600 font-bold">
              using a pain scale
            </span>{" "}
            when telling your mum/dad or teacher when you are in pain.
          </li>
          <li className="mt-2">
            Sometimes when I eat too many sweets my tummy hurts. My mum ask's me{" "}
            <span className=" text-green-600 font-bold">
              "how am I feeling?"
            </span>
            I point to the face to show how I am feeling. When my tummy hurts, I
            point to the{" "}
            <span className=" text-green-600 font-bold">
              orange or red face as it really hurts.
            </span>{" "}
            My mum/dad then help me feel better by giving me a heat pack to put
            over my tummy. This makes me feel much better.
          </li>
          <li className="mt-2">
            The next time that something hurts you,{" "}
            <span className=" text-green-600 font-bold">
              try pointing to the faces.
            </span>{" "}
            It will let your mum and dad know that you need their help and they
            can{" "}
            <span className=" text-green-600 font-bold">
              make you feel much better.
            </span>
          </li>
          <div className="flex flex-row justify-around">
            <img src={painScale} alt="painScale" className="w-96 mt-6" />
            <img src={mateyMorgan} alt="mateyMorgan" className="w-36 mt-6" />
          </div>
        </ol>
        <audio ref={audioRef} src={mateyMorganAudio} />
      </div>
    </div>
  );
};

export default MateyMorgan2;
