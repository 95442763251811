import React, { useEffect, useRef } from "react";
import coin from "../../assets/Icons/Coin.png";
import faceNoHurt from "../../assets/pain scale/0.png";
import painScale from "../../assets/pain scale/Pain scale.png";
import sailorAmy from "../../assets/Characters/SailorAmy.jpg";
import sailorAmyStoryContinued from "../../assets/audios/speech/Mission5/sailorAmyStory2.mp3";



const SailorAmyStoryContinued = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Automatically play the audio when the component loads
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
        Sailor Amy's Story Continued
        <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
      </h2>
      <div className="flex items-start space-x-6">
        {/* Sailor Amy image */}
        <img
          src={sailorAmy}
          alt="Sailor Amy"
          className="w-64 h-auto rounded-lg"
        />

        {/* Story content */}
        <div className="text-left text-gray-700 font-gillSans text-xl">
          <p>
            When I have pain, I use the pain scale to show my <strong>mum</strong> and dad where it hurts.
          </p>
          <p>They help me feel better.</p>

          {/* Single face image (No Hurt) */}
          <div className="flex justify-center mt-3">
            <img src={faceNoHurt} alt="No Hurt" className="w-24 h-auto" />
            <p className="ml-4 self-center">No Hurt</p>
          </div>

          <p className="mt-3 font-bold">
            Top Pirate Secret:
            <span className="font-normal"> Telling your mum/dad or teacher about your pain is a sign of bravery! It’s very important to use the pain scale.</span>
          </p>

          
          {/* Pain scale image */}
          <img
            src={painScale}
            alt="Pain Scale"
            className="w-96 mt-4 mx-auto"
          />
        </div>       
      </div>

      {/* Audio */}
      <audio ref={audioRef} src={sailorAmyStoryContinued} />
    </div>
  );
};

export default SailorAmyStoryContinued;
