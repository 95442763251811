import React, { useEffect } from "react";
import Captain from "../../assets/cptCharlie.png";
import wongBakerPainScale from "../../assets/pain scale/Pain scale.png";
import pg14 from "../../assets/audios/speech/Mission6/pg14.mp3";
const TryAgain = ({onTryAgain, audioRef}) => {
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    // Play celebration audio when the component mounts
    const audio = new Audio(pg14);
    audio.play();
    
    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Incorrect Answer
      </h2>
      <div className="flex justify-evenly items-center">
        <img src={Captain} className="w-72" alt="Captain" />
        <div className="text-gray-700 text-base lg:text-xl font-gillSans">
          <p>Let’s Try Again.</p>
          <p className="mt-4">
            Click into the pain scale to remember all about the different faces.
          </p>
          <img
            src={wongBakerPainScale}
            alt="Wong-Baker Pain Scale"
            onClick={onTryAgain}
            className="w-60 mt-5"
          />
          <p className="mt-4">
            Remember if you need help, click the help button at the top of the page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TryAgain;
