import React from "react";
import MissionComplete from "../MissionComplete";

const Mission4Complete = ({ carrier }) => {
  const completedMissionId = 4;
  const rank = "Navigator";
  const coins = 20;
  const userId = carrier.userId;
  const currentMissionId = 5;
  const currentMissionPage = 44;
  
  return (
    <>
      <MissionComplete
        completedMissionId={completedMissionId}
        rank={rank}
        userId={userId}
        coins={coins}
        currentMissionId={currentMissionId}
        currentMissionPage={currentMissionPage}        
      />
    </>
  );
};

export default Mission4Complete;
