import React, { useState } from "react";
import morgan from "../../assets/Characters/MateyMorgan.png";
import annie from "../../assets/Characters/Annie.png";

const Adventure = ({ onNext }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        A Pirates Adventure
      </h2>
      <div className="text-gray-700 flex flex-row items-start font-gillSans">
        <div>
          <ol>
            <li className=" mt-2 lg:text-xl md:text-xl">
              You have a special adventure that you must complete.
            </li>
            <li className="mt-2 lg:text-xl md:text-xl">
              You will hear two stories:
            </li>
            <ol className="list-decimal text-xl mt-1">
              <li>
                <div className="flex flex-row gap-10">
                  <p>Annie will talk about her pain</p>
                  <img src={annie} className="w-20" alt="Annie" />
                </div>
              </li>
              <li className="mt-4">
                <div className="flex flex-row gap-10">
                  <p>Morgan will talk about his pain</p>
                  <img src={morgan} className="w-20" alt="Matey Morgan" />
                </div>
              </li>
            </ol>
            <li className="lg:text-xl md:text-xl mt-2">
              Captain Charlie has asked Annie and Morgan 'How are you feeling?'
            </li>
            <li className="lg:text-xl mt-2 md:text-xl">
              Annie and Matey Morgan need your help to pick the face that shows
              how much pain they are in.
            </li>
          </ol>
          {ready ? (
            <p>Started!</p>
          ) : (
            <div className="mt-5 lg:text-lg flex justify-center">
              <button
                className="px-20 py-2 mt-4 rounded-md bg-green-500 text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
                onClick={handleStart}
              >
                I am ready to help <br /> Annie and Morgan
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Adventure;
