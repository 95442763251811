import React, {useEffect} from "react";
import drReily from "../../assets/Characters/Riley.png"; 
import noHurtFace from "../../assets/pain scale/0.png";
import pg22 from "../../assets/audios/speech/Mission6/pg22.mp3";

const Skill2Page1 = ({ onNext }) => {
  useEffect(() => {    
    const audio = new Audio(pg22);
    audio.play();    
    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0 overflow-auto">
      <div className="flex flex-col lg:flex-row items-start w-full max-w-4xl rounded-lg p-5 lg:p-10 gap-3">
        {/* Doctor Image */}
        <div className="w-full lg:w-1/3 flex justify-center lg:justify-start lg:mb-0">
          <img
            src={drReily}
            alt="Dr. Riley"
            className="w-5/6 mt-20 h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-2/3 flex flex-col items-center lg:items-start font-gillSans">
          <h2 className="text-3xl font-bold mb-4">Help you feel better</h2>
          <p className="text-xl mb-4">
            When you have pain, the doctor can help you feel better.
          </p>
          <p className="text-xl mb-4">
            That’s a doctor’s most important job, to help you feel better,<br/> so
            you don’t have any more pain.
          </p>
          <p className="text-xl mb-4">
            You will now learn how doctors can help you feel better.
          </p>

          {/* No Hurt Face Icon */}
          <div className="flex justify-center mt-4">
            <img src={noHurtFace} alt="No Hurt" className="w-12 h-auto" />
          </div>
            <span className="text-xl font-gillSans">No Pain</span>

          {/* Next Button */}
          <button
            onClick={onNext}
            className="mt-4 px-8 py-2 rounded-md bg-green-600 text-white text-xl"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Skill2Page1;
