import React, { useEffect } from "react";
import Captain from "../../assets/cptCharlie.png";
import confettiGif from "../../assets/videos/Celebrations/confetti.gif";
import celebrationSound from "../../assets/audios/Kids Cheering.mp3";
import coin from "../../assets/Icons/Coin.png";
import pg45 from "../../assets/audios/speech/Mission6/pg45.mp3";

const Correct2 = ({ carrier }) => {
  useEffect(() => {
    // Play celebration audio when the component mounts
    const audio = new Audio(pg45);
    audio.play();    
    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <>
      <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Correct Answer
        </h2>
        <div className=" flex justify-evenly items-center">
          <img src={Captain} className=" w-72" alt="" />
          <ol className="text-gray-700 text-base  lg:text-xl font-gillSans">
            <li>Excellent Job Master at Arms {carrier.firstName}!</li>
            <li className=" mt-4">You are doing a fantastic job!</li>            
            <li className=" mt-4">
              <img src={coin} alt="coin" className="w-24 spinner" />
            </li>
          </ol>
        </div>
        <img
          src={confettiGif}
          alt="confetti"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          style={{ width: "60%", height: "auto" }}
        />
      </div>
    </>
  );
};

export default Correct2;
