import React, { useState } from "react";
import BodyMap from "../BodyMapNew";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const PainThisWeek = ({ carrier, submitted, setSubmitted }) => {
  const [clickedAreas, setClickedAreas] = useState([]);  

  // Function to update clicked areas
  const handleAreaClick = (area) => {
    // Check if the clicked area already exists in clickedAreas
    if (clickedAreas.includes(area)) {
      setClickedAreas((prevClickedAreas) =>
        prevClickedAreas.filter((clickedArea) => clickedArea !== area)
      );
    } else {
      setClickedAreas((prevClickedAreas) => [...prevClickedAreas, area]);
    }
    sessionStorage.setItem("pain5Completed", true);
  };

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    sessionStorage.setItem("pain5Completed", "true");
    let userId = carrier.userId;
    // Hit the API to save mission activity
    try {
      const payload = [
        {
          userId: userId, 
          missionId: "M6", 
          pageNum: 8, 
          activityId: "M6PainBodyMap", 
          activityValue: JSON.stringify(clickedAreas), 
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
      audioPlay();
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };
  const handleNoPainSubmit = async () => {
    setSubmitted(true);
    sessionStorage.setItem("pain5Completed", "true");
    let userId1 = carrier.userId;
    // Hit the API to save mission activity
    try {
      const payload = [
        {
          userId: userId1, 
          missionId: "M6", 
          pageNum: 8, 
          activityId: "M6PainBodyMap", 
          activityValue: "No Pain", 
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
      audioPlay();
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  // Determine the number of columns based on the length of clickedAreas
  const numColumns = clickedAreas.length > 10 ? 2 : 1;

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Pain
          <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
        </h2>
      </div>
      <div className="text-gray-700 text-base flex justify-evenly font-gillSans">
        <ol className="text-xl mt-5">
          <li className="mt-5">
          Where was your pain this week?
          </li>

          <li className="mt-5">
          Click on the body parts that hurt you this week.

          </li>
          
          {/* Render the clicked areas as a list */}
          {submitted && <p className="mt-5">You submitted:</p>}
          <div className="grid grid-cols-2 gap-4">
            {[...Array(numColumns)].map((_, colIndex) => (
              <ol
                key={colIndex}
                start={
                  colIndex * Math.ceil(clickedAreas.length / numColumns) + 1
                }
                className="list-decimal mt-2"
              >
                {clickedAreas
                  .slice(
                    colIndex * Math.ceil(clickedAreas.length / numColumns),
                    (colIndex + 1) * Math.ceil(clickedAreas.length / numColumns)
                  )
                  .map((area, index) => (
                    <li key={index}>{area}</li>
                  ))}
              </ol>
            ))}
          </div>
          <button
            onClick={handleNoPainSubmit}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 mr-8"
          >
            I had no pain this week
          </button>
          {!submitted && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          {submitted && <div className="mt-5">Submitted successfully!</div>}
        </ol>
        <BodyMap onAreaClick={handleAreaClick} />
      </div>
    </div>
  );
};

export default PainThisWeek;
