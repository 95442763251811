import React from "react";
import bodyImage from "../../assets/pain scale/Point to map.png";
import painScale from "../../assets/pain scale/Pain scale.png";
import stomachPain from "../../assets/pain scale/Point to stomach.png";

const Section3 = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        How to show you have pain
      </h2>
      <div className="text-gray-700 mt-5 text-base fadeIn font-gillSans">
        <p className="mt-2 lg:text-xl">
          You can tell your mum/dad or teacher you are in pain in many different
          ways.
        </p>
        <ol className="mt-2 flex flex-col flex-wrap">
          <li className="flex lg:text-xl items-center justify-evenly fadeIn">
            Pointing to the body part that hurts
            <img src={stomachPain} className="w-25 ml-27" alt="stomachPain" />
          </li>
          <li className="flex lg:text-xl items-center justify-evenly mt-3 fadeIn">
            Pointing to the body map
            <img src={bodyImage} className="w-25 ml-25" alt="bodyMap" />
          </li>
          {/* <li className="flex items-center justify-evenly mt-6 fadeIn">
            Using a pain scale
            <img src={painScale} className="w-44 ml-24" alt="painScale" />
          </li> */}
        </ol>
      </div>
    </div>
  );
};

export default Section3;
