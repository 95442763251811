import React from "react";
import Captain from "../../assets/cptCharlie.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import { useEffect } from "react";
import axios from "axios";

const Impressive = ({ carrier }) => {
  useEffect(() => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  return (
    <>
      <div className=" relative w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Excellent Job!
          </h2>
          <img src={coin} className="w-12 ml-20 spinner" alt="coin" />
        </div>
        <div className=" flex justify-evenly items-center">
          <img src={Captain} className="mt-10 w-72" alt="" />
          <ol className="text-gray-700 text-base  lg:text-2xl font-gillSans">
            <li>Excellent job Powder Monkey {carrier?.firstName}!</li>            
          </ol>
        </div>
      </div>
    </>
  );
};

export default Impressive;
