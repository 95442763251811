import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import AnimatedAttemptCounter from "../AnimatedAttemptCounter ";
import pg30Q3 from "../../assets/audios/speech/Mission5/pg30Q3.mp3";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";

const YourTurn = ({ carrier, setSubmitted }) => {
  const [selectedIndex, setSelectedIndex] = useState(null); // State to keep track of selected image
  const [clickedIndex, setClickedIndex] = useState(null);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [isCorrect, setIsCorrect] = useState("?");
  const audioRef = useRef(new Audio(pg30Q3));
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }

    // Cleanup function to pause and reset audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset to start
      }
    };
  }, [audioRef]);

  const painScaleDescriptions = {
    0: "No hurt",
    1: "Hurts little bit",
    2: "Hurts little more",
    3: "Hurts even more",
    4: "Hurts whole lot",
    5: "Hurts worst",
  };

  const handleImageClick = async (index) => {
    // Set the selected image index
    if (index >= 0 && index <= 5) {
      setSelectedIndex(index);
      if (index >= 3 && index <= 5) {
        setClickedIndex(index);
        setIsCorrect(true);
        sessionStorage.setItem("TaskParentCompleted", "true");
        setSubmitted(false);  // Reset submitted to false
      } else {
        setIsCorrect(false);
        setClickedIndex(-1);
        setIncorrectAttempts((prev) => prev + 1);
      }
      let userId = carrier.userId;
      // Hit the API to save activity details
      try {
        const payload = [
          {
            userId: userId,
            missionId: "M5",
            pageNum: 32,
            activityId: "M5AnnieTaskPainScale",
            activityValue: JSON.stringify({
              selectedOption: painScaleDescriptions[index],
            }), // Save selected option
          },
        ];
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
          payload
        );
        // console.log("Response:", response.data);
      } catch (error) {
        console.error("Error saving mission activity:", error);
      }
    } else {
      console.error(
        "Index out of range. Please provide an index between 0 and 5."
      );
      return;
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-18">
      <h2 className="text-xl font-bold mb-4 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Your Turn
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl">
          <li className="mt-4 flex items-center justify-center space-x-4">
            <span>Q3. How is Annie feeling?</span>
            {isCorrect === true && (
              <img src={greenTick} alt="Green Tick" className="w-12 h-12 ml-2" />
            )}
            {!isCorrect  && (
              <img src={redCross} alt="Red Cross" className="w-12 h-12 ml-2" />
            )}
          </li>
          <div className="flex mt-10 justify-between items-center fadeIn gap-5 w-full">
            <div className="flex items-center flex-col">
              <img
                src={painScale0}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 0
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(0)}
              />
              <span className="mt-2 text-center font-gillSans">
                0 <br />
                No hurt
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale2}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 1
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(1)}
              />
              <span className="mt-2 text-center font-gillSans">
                2 <br />
                Hurts little bit
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale4}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 2
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(2)}
              />
              <span className="mt-2 text-center font-gillSans">
                4 <br />
                Hurts little more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale6}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 3
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : incorrectAttempts >= 3
                    ? "bg-yellow-200"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(3)}
              />
              <span className="mt-2 text-center font-gillSans">
                6 <br />
                Hurts even more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale8}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 4
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(4)}
              />
              <span className="mt-2 text-center font-gillSans">
                8 <br />
                Hurts whole lot
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale10}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 5
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(5)}
              />
              <span className="mt-2 text-center font-gillSans">
                10 <br />
                Hurts worst
              </span>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <AnimatedAttemptCounter incorrectAttempts={incorrectAttempts} />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default YourTurn;
