import React, { useState, useEffect } from "react";
import ImageMapper from "react-image-mapper";
import bodyImageMap from "../assets/bodyImage.png";
import greenTick from "../assets/green-tick.png"; // Use your green tick image here
import redCross from "../assets/red-cross.png"; // Use your red cross image here

const BodyMapNew = ({ onAreaClick, result }) => {
  const [clickedArea, setClickedArea] = useState(null);

  // Image map areas
  const areas = [
    {
      id: "head",
      shape: "poly",
      coords: [
        75, 64, 75, 53, 71, 43, 66, 38, 67, 32, 68, 26, 68, 26, 69, 16, 74, 9,
        81, 6, 91, 5, 97, 8, 101, 13, 104, 20, 105, 28, 106, 33, 106, 39, 101,
        40, 100, 46, 98, 51, 97, 55, 96, 64,
      ],
      title: "Head",
      fillColor: "#05fcfa",
    },
    {
      id: "right-arm",
      shape: "poly",
      coords: [
        75, 64, 71, 67, 62, 71, 52, 75, 43, 77, 38, 85, 38, 97, 40, 106, 38,
        118, 35, 135, 32, 147, 30, 158, 28, 171, 25, 180, 22, 185, 16, 185, 10,
        190, 7, 195, 16, 194, 12, 198, 5, 205, 12, 213, 16, 212, 25, 205, 32,
        197, 36, 185, 41, 176, 45, 163, 49, 149, 54, 134,
      ],
      title: "Right Arm",
      fillColor: "#05fcfa",
    },
    {
      id: "left-arm",
      shape: "poly",
      coords: [
        97, 64, 103, 69, 111, 73, 120, 77, 130, 79, 134, 85, 136, 93, 134, 104,
        134, 111, 135, 119, 136, 129, 138, 136, 140, 144, 143, 154, 145, 165,
        146, 175, 150, 183, 151, 187, 158, 187, 163, 192, 167, 195, 158, 195,
        158, 198, 168, 207, 166, 211, 157, 215, 147, 206, 142, 199, 136, 185,
        130, 172, 126, 158, 122, 145, 117, 127,
      ],
      title: "Left Arm",
      fillColor: "#05fcfa",
    },
    {
      id: "chest",
      shape: "poly",
      coords: [74, 63, 97, 63, 116, 123, 57, 124],
      title: "Chest",
      fillColor: "#05fcfa",
    },
    {
      id: "stomach",
      shape: "poly",
      coords: [
        57, 124, 115, 123, 116, 145, 117, 158, 120, 181, 124, 209, 48, 209, 52,
        188, 54, 160, 57, 141,
      ],
      title: "Stomach",
      fillColor: "#05fcfa",
    },
    {
      id: "legs",
      shape: "poly",
      coords: [
        49, 202, 48, 217, 48, 230, 48, 242, 50, 255, 52, 267, 50, 278, 49, 292,
        49, 309, 49, 321, 52, 334, 52, 349, 51, 359, 46, 370, 43, 381, 47, 385,
        54, 386, 61, 385, 64, 382, 64, 370, 67, 364, 68, 357, 67, 341, 67, 326,
        67, 316, 70, 306, 70, 290, 74, 273, 76, 259, 79, 246, 80, 234, 81, 219,
        82, 209, 87, 208, 91, 211, 91, 220, 92, 230, 92, 242, 95, 253, 98, 265,
        100, 278, 102, 290, 103, 303, 105, 316, 106, 323, 106, 333, 106, 343,
        105, 355, 105, 362, 108, 368, 109, 375, 109, 383, 111, 385, 120, 385,
        128, 384, 130, 380, 123, 362, 121, 353, 121, 335, 122, 324, 124, 316,
        124, 300, 123, 278, 121, 268, 123, 256, 124, 241, 125, 228, 124, 218,
        124, 208, 123, 202,
      ],
      title: "Legs",
      fillColor: "#05fcfa",
    },
  ];

  const handleClick = (area) => {
    setClickedArea(area);
    onAreaClick(area.title); // Pass the clicked area title
  };

  return (
    <div className="flex justify-between items-center">
      {/* Green Tick or Red Cross */}
      <div className="flex flex-col items-center space-y-4">
        {result === true && (
          <img src={greenTick} alt="Green Tick" className="w-14 h-14" />
        )}
        {result === false && (
          <img src={redCross} alt="Red Cross" className="w-14 h-14" />
        )}
      </div>

      {/* Body Map */}
      <div className="invert">
        <ImageMapper
          src={bodyImageMap}
          map={{ name: "bodyMap", areas: areas }}
          width={170}
          height={390}
          onClick={(area) => handleClick(area)}
        />
      </div>

      {/* Empty Space on the right to balance the layout */}
      <div className="w-14 h-14"></div>
    </div>
  );
};

export default BodyMapNew;
