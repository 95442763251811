import React, { useEffect } from "react";
import captainCharlie from "../../assets/cptCharlie.png"; 
import facePainImage from "../../assets/pain scale/facePain.png"; 
import bodyPartImage from "../../assets/pain scale/Point to stomach.png";
import coins from "../../assets/audios/coins.mp3";
import coin from "../../assets/Icons/Coin.png";
import pg40 from "../../assets/audios/speech/Mission6/pg40.mp3";

const CorrectAnswer = ({ carrier }) => {
  useEffect(() => {    
    const audio = new Audio(pg40);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  useEffect(() => {
    audioPlay();
  }, []);
  return (
    <div className="flex flex-col items-start p-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold ml-40 mt-5 flex items-center">Correct Answer
        <img src={coin} alt="Coin" className="w-10 h-10 ml-2" />
      </h2>
      <div className="flex items-start gap-6 mt-5">
        <img src={captainCharlie} alt="Captain Charlie" className="w-40 h-40" />
        <div className="text-xl space-y-4">
          <p className="font-bold">Excellent Job Master at Arms {carrier.firstName}!</p>
          <p>That’s correct! You can tell a doctor how you are feeling by:</p>
          <ol className="list-decimal pl-5">
            <li>
              Pointing to the face on the pain scale to show how much pain you have.
              <img src={facePainImage} alt="Pain Scale" className="w-40 mt-2" />
            </li>
            <li className="mt-2">
              Point to the body part that hurts (like pointing to your tummy when it hurts).
              <img src={bodyPartImage} alt="Body Part" className="w-32 mt-2" />
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default CorrectAnswer;
