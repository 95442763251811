import React, { useEffect, useRef } from "react";
import teachersHelpImage from "../../assets/miscellaneous/teachers.png"; 
import painScaleImage from "../../assets/pain scale/Pain scale.png"; 
import pg26 from "../../assets/audios/speech/Mission5/pg26.mp3";

const TeachersHelp = ({ onNext }) => {

  const audioRef = useRef(new Audio(pg26));
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.play();
        }

        // Cleanup function to pause and reset audio when the component unmounts
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset to start
            }
        };
    }, [audioRef]);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      {/* Title */}
      <h2 className="text-2xl font-bold mb-4">Teacher’s Can Help</h2>

      {/* Text content */}
      <div className="text-lg text-left mb-6">
        <p className="mb-4">
          When something hurts in school, it is important to tell your teacher using the pain scale. You can point to the face that shows how much something is hurting.
        </p>
        <p className="mb-4">
          When you show your teacher, they can help you feel better.
        </p>
        <p>
          The next time you have pain in school, you can use the pain scale to show your teacher how much it hurts.
        </p>
      </div>

      {/* Image Container - Place images side by side */}
      <div className="flex justify-center items-center mb-4 gap-10">
        {/* Teachers Help Image */}
        <img src={teachersHelpImage} alt="Teachers Can Help" className="w-36 h-auto" />

        {/* Wong-Baker Faces Pain Rating Scale Image */}
        <img src={painScaleImage} alt="Wong-Baker Faces Pain Rating Scale" className="w-64 h-auto" />
      </div>

      {/* Next Button */}
      <div className="flex justify-center -mt-5">
        <button
          onClick={onNext}
          className="px-6 py-2 text-xl rounded-md bg-green-500 text-white mt-6"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TeachersHelp;
