import React, { useState } from "react";
import pdf from "../../../assets/Icons/pdf.png";
import videoIcon from "../../../assets/Icons/videoIconRed.png";
import tummyBreathingPdf from "../../../assets/PDF/tummyBreathing.pdf";
import Audio2 from "../../../assets/videos/2. Tummy Breathing_F.mp4";

const TummyBreathing = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to open and close the popup
  const handleVideoClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="w-80 h-72 text-sm max-w-5xl bg-transparent md:w-[90%] lg:h-96 xl:h-1/2 fadeIn">
      <div>
        <h2 className="text-xl font-bold text-center text-[#267e8c] md:text-2xl lg:text-4xl font-gillSans">
          Tummy Breathing
        </h2>
        <div className="text-gray-700 flex gap-5 justify-center text-base mt-10 font-gillSans">
          {/* Video icon to trigger the popup */}
          <div
            className="mt-5 flex items-center flex-col w-28 cursor-pointer hover:scale-110 transform transition duration-300 ease-in-out"
            onClick={handleVideoClick}
          >
            <img src={videoIcon} className="w-24" alt="videoIcon" />
            <span className="mt-2 text-center font-gillSans">
              Tummy Breathing
            </span>
          </div>

          {/* PDF download link */}
          <div className="flex mt-4 justify-between gap-5 flex-wrap">
            <a
              href={tummyBreathingPdf}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center flex-col w-28 cursor-pointer hover:scale-110 transform transition duration-300 ease-in-out"
            >
              <img src={pdf} className="w-20" alt="pdf" />
              <span className="mt-2 text-center font-gillSans">
                Tummy Breathing PDF
              </span>
            </a>
          </div>
        </div>
      </div>

      {/* Video Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="relative bg-white w-[90%] max-w-4xl p-4 rounded-lg">
            {/* Close button */}
            <button
              className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl font-bold"
              onClick={handleClosePopup}
            >
              &times;
            </button>

            {/* Title inside the popup */}
            <h3 className="text-xl font-bold text-center mb-4 text-[#267e8c]">
              Tummy Breathing Video
            </h3>

            {/* Video */}
            <video
              src={Audio2}
              controls
              className="w-full h-auto rounded-lg"
            ></video>
          </div>
        </div>
      )}
    </div>
  );
};

export default TummyBreathing;
