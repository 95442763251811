import axios from "axios";
import React, { useCallback, useEffect, useState, useRef } from "react";
import armAudio from "../../assets/audios/Arm.mp3";
import headAudio from "../../assets/audios/Head.mp3";
import footAudio from "../../assets/audios/Legs.mp3";
import mouthAudio from "../../assets/audios/Mouth.mp3";
import stomachAudio from "../../assets/audios/Tummy.mp3";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import BodyMap from "../BodyMapNew";
import pg30Q1 from "../../assets/audios/speech/Mission5/pg30Q1.mp3";

const Bodymap = ({
  carrier,
  setSubmitted,   
  onCorrect,
}) => {
  const [answer, setAnswer] = useState("");
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(new Audio(pg30Q1));
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }

    // Cleanup function to pause and reset audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset to start
      }
    };
  }, [audioRef]);

  const playNextAudio = useCallback(() => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    const nextAudioPath = audioQueue[0];
    const nextAudio = new Audio(nextAudioPath);

    nextAudio.play();
    setAudioQueue((queue) => queue.slice(1));

    nextAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [audioQueue]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      setIsPlaying(true);
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);
  const handleImageClick = (audioPath) => {
    setAudioQueue((queue) => [...queue, audioPath]);
  };
  const audioMap = {
    Head: headAudio,
    Stomach: stomachAudio,
    Mouth: mouthAudio,
    "Left Arm": armAudio,
    "Right Arm": armAudio,
    Legs: footAudio,
  };

  const handleAreaClick = async (areaTitle) => {
    console.log("Area Title:", areaTitle);
    const audioFile = audioMap[areaTitle];
    if (audioMap[areaTitle]) {
      handleImageClick(audioMap[areaTitle]);
    }
    let activityValue;
    if (areaTitle === "Stomach") {
      setAnswer("correct");
      activityValue = "Correct : " + areaTitle;
      console.log("activityValue", activityValue);
      onCorrect();
    } else {
      setAnswer("incorrect");
      activityValue = "Incorrect : " + areaTitle;
      console.log("activityValue", activityValue);
    }
    setSubmitted(true);
    // Hit the API to save mission activity
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M5",
          pageNum: 30,
          activityId: "M5AnnieTaskBodyMap",
          activityValue: activityValue,
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-4 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Annie
      </h2>

      <div className="flex flex-row items-start justify-between w-full">
        {/* Left Side: Text */}
        <div className="w-1/2 text-left text-xl text-gray-700 font-gillSans pr-4">
          Q1. What body part hurts Annie?
        </div>

        {/* Right Side: BodyMap and feedback */}
        <div className="w-1/2 flex flex-col items-center">
          <BodyMap onAreaClick={handleAreaClick} />
        </div>
        <div className="flex flex-row items-center justify-center">
          {/* Conditionally render the green tick or red cross */}
          {answer === "correct" && (
            <img
              src={greenTick}
              alt="Correct Answer"
              className="w-12 h-12 mt-4"
            />
          )}
          {answer === "incorrect" && (
            <img
              src={redCross}
              alt="Incorrect Answer"
              className="w-12 h-12 mt-4"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Bodymap;
