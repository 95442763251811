import React from "react";
import mapExpert from "../../assets/Badges/mapExpert.png";

const Badge2 = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Your Special Badge
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="lg:text-xl lg:mt-10 ">
          <li className="lg:mt-6">
            Congratulations, Captain Charlie has awarded you a special badge
            called ' Body Map Expert' for all your hard work in learning how
            to use the body map to show where it hurts.
          </li>
          <div className="flex lg:mt-4 md:mt-2 justify-center pr-6 gap-5 lg:pr-8">
            <img src={mapExpert} alt="badge" className="w-64" />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default Badge2;
