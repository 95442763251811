import React, { useState } from "react";
import tummyBreathing from "../../../assets/pain diary/tummyBreathing.png";
import music from "../../../assets/pain diary/listenMusic.png";
import heatPack from "../../../assets/pain diary/heatPack.png";
import medication from "../../../assets/pain diary/medicines.png";
import deepPressure from "../../../assets/pain diary/deepPressure.png";
import walk from "../../../assets/pain diary/goForWalk.png";
// import axios from "axios";
import { useLocation } from "react-router-dom";
import axios from "axios";

const WhatIDid = () => {
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [buttonText, setButtonText] = useState("Submit");
  const [otherText, setOtherText] = useState(""); // State for the "OTHER" text input
  const location = useLocation();
  const carrier = location.state?.carrier;
  // // console.log("Hey",carrier);
  const handleImageClick = (index) => {
    setSelectedIndices((prev) => {
      if (prev.includes(index)) {
        // Deselect if already selected
        return prev.filter((i) => i !== index);
      } else if (prev.length < 3) {
        // Select new image if less than 3 are selected
        return [...prev, index];
      } else {
        // Do nothing if already 3 are selected
        return prev;
      }
    });
  };

  const handleSubmit = async () => {
    if (selectedIndices.length === 0 && !otherText.trim()) {
      alert("Please select at least one action or enter text in 'OTHER'.");
      return;
    }

    const selectedActions = selectedIndices.map((index) => placeholders[index].label);

    // Include the "OTHER" text if it's entered
    if (otherText.trim()) {
      selectedActions.push(otherText.trim());
    }

    const payload = {
      userId: carrier?.userId, // Assuming carrier object contains userId
      bodyPart: selectedActions.join(", "), // Send selected actions as the "bodyPart"
      diaryEntryTime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}logPainDiary`, payload);
      // console.log("Pain diary entry logged successfully:", response.data);
      setButtonText("Submitted successfully!");
    } catch (error) {
      console.error("Error logging pain diary entry:", error);
      setButtonText("Submit"); // Reset button text if there was an error
    }
  };

  const placeholders = [
    { src: tummyBreathing, label: "Tummy breathing", widthClass: "w-40" },
    { src: music, label: "Listen to music", widthClass: "w-40" },
    { src: heatPack, label: "Heat pack", widthClass: "w-40" },
    { src: medication, label: "Medication", widthClass: "w-40" },
    { src: deepPressure, label: "Deep pressure", widthClass: "w-40" },
    { src: walk, label: "Go for a walk", widthClass: "w-40" },
  ];

  return (
    <div className="relative w-80 h-full max-w-4xl bg-transparent md:w-[60%] lg:h-full xl:h-full xl:pr-7 fadeIn mt-20 overflow-hidden">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        What I Did to Feel Better
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="overflow-hidden">
          <li className="mt-1 text-xl text-center text-blue-800 mb-6">
            Select the things you did to feel better.
          </li>
          <div className="grid grid-cols-3 gap-4">
            {placeholders.map((placeholder, index) => (
              <div className="flex items-center flex-col" key={index}>
                <div>
                  <img
                    src={placeholder.src}
                    alt={`pain-scale-${index}`}
                    className={`hover:scale-110 transform transition rounded-lg duration-300 ease-in-out cursor-pointer max-h-28 ${
                      placeholder.widthClass
                    } ${
                      selectedIndices.includes(index)
                        ? "border-4 border-green-500 rounded"
                        : ""
                    }`}
                    onClick={() => handleImageClick(index)}
                  />
                  <span className="lg:text-lg text-center mt-2">{placeholder.label}</span>
                </div>
              </div>
            ))}
            
            <div className="flex items-center justify-center col-span-3">
              <div className="bg-green-200 p-4 rounded-md">
                <p className="mb-2 flex justify-center items-center">OTHER:</p>
                <input
                  type="text"
                  placeholder="(Enter text here)"
                  value={otherText}
                  onChange={(e) => setOtherText(e.target.value)}
                  className="p-2 border border-gray-300 rounded w-full"
                />
              </div>
            </div>
          </div>                    
          
          <div className="flex justify-center mt-4">
            <button
              onClick={handleSubmit}
              className={`p-2 rounded-lg text-white font-gillSans ${
                buttonText === "Submit"
                  ? "bg-[#267e8c] hover:bg-[#3ac2d8]"
                  : "bg-green-500"
              }`}
            >
              {buttonText}
            </button>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default WhatIDid;
