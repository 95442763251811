import React, { useEffect, useRef } from "react";
import drReily from "../../assets/Characters/Riley.png";
import communicatingPain from "../../assets/videos/Communicating Pain.mp4";
import pg20 from "../../assets/audios/speech/Mission6/pg20.mp3";

const Skill1Page2 = ({ onComplete }) => {
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  useEffect(() => {
    const audio = new Audio(pg20);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    // First Video: Start from 00:18 and end at 00:44
    const startTime1 = 18;
    const endTime1 = 44;

    const handlePlay1 = () => {
      if (videoRef1.current) {
        videoRef1.current.currentTime = startTime1;
      }
    };

    const handleTimeUpdate1 = () => {
      if (videoRef1.current && videoRef1.current.currentTime >= endTime1) {
        videoRef1.current.pause();
      }
    };

    // Second Video: Start from 01:15
    const startTime2 = 75;

    const handlePlay2 = () => {
      if (videoRef2.current) {
        videoRef2.current.currentTime = startTime2;
      }
    };

    videoRef1.current.addEventListener("play", handlePlay1);
    videoRef1.current.addEventListener("timeupdate", handleTimeUpdate1);
    videoRef2.current.addEventListener("play", handlePlay2);

    // Cleanup event listeners on component unmount
    return () => {
      if (videoRef1.current) {
        videoRef1.current.removeEventListener("play", handlePlay1);
        videoRef1.current.removeEventListener("timeupdate", handleTimeUpdate1);
      }
      if (videoRef2.current) {
        videoRef2.current.removeEventListener("play", handlePlay2);
      }
    };
  }, []);

  return (
    <div className="absolute inset-0 flex justify-center items-center pt-4 md:pt-0 overflow-auto">
      <div className="flex flex-col lg:flex-row items-start w-full max-w-5xl p-5 lg:p-10 gap-8">
        {/* Doctor Image */}
        <div className="w-full lg:w-1/3 flex lg:justify-start lg:mb-0">
          <img
            src={drReily}
            alt="Dr. Riley"
            className="w-4/5 h-4/5 rounded-lg shadow-lg ml-20 mt-20"
          />
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-2/3 flex flex-col items-center lg:items-start">
          <h2 className="text-2xl font-bold mb-4">Health Superheroes</h2>
          <p className="text-xl mb-4">
            When your body part hurts you can show the doctor by:
          </p>
          <ol className="text-xl mb-6">
            <li className="mb-3">1. Point to the body part that hurts.</li>
            <li>2. Or using the Body Map to show where it hurts.</li>
          </ol>
          <div className="flex gap-4 mb-6">
            <video
              ref={videoRef1}
              src={communicatingPain}
              controls
              className="w-2/5"
              loop={false}
            />
            <video
              ref={videoRef2}
              src={communicatingPain}
              controls
              className="w-2/5"
              loop={false}
            />
          </div>
          <p className="text-xl mb-3">
            The doctor might touch your body part that hurts to <br /> find out why it hurts.
            hurts.
          </p>
          <button
            onClick={onComplete}
            className="p-3 bg-green-600 text-white rounded text-lg"
          >
            Finish Skill 1
          </button>
        </div>
      </div>
    </div>
  );
};

export default Skill1Page2;
