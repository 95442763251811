import React from "react";
import fbLogo from "../../../assets/fbLogo3.png";

const Contact = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Contact Us
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans gap-10">
        <div>
          <p className="mt-24 lg:text-lg">
            If you have any questions throughout the programme, you can click
            the email the team at{" "}
            <span className="font-semibold underline">
              feelingbetterASD@gmail.com
            </span>
          </p>
        </div>
        <div>
          <img src={fbLogo} alt="Feeling Better" className=" w-3/5" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
