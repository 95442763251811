import React from "react";

const Mission5Intro = () => {
  return (
    <div className="w-80 h-72 flex flex-col justify-center items-center text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-5xl font-gillSans">
        Mission 5
      </h2>
      <p className="italic text-2xl text-[#59CD90] font-gillSans">
        Communicate More- Feel Better
      </p>
    </div>
  );
};

export default Mission5Intro;
