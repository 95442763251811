import React from "react";
import deckhand from "../../../assets/Ranks/Deckhand.png";
import striker from "../../../assets/Ranks/Striker.png";
import powderMonkey from "../../../assets/Ranks/Powder Monkey.png";
import navigator from "../../../assets/Ranks/Navigator.png";
import masterAtArms from "../../../assets/Ranks/Master at Arms.png";
import gunner from "../../../assets/Ranks/Gunner.png";
import boatswain from "../../../assets/Ranks/Boatswain.png";
import thirdMate from "../../../assets/Ranks/Third Mate.png";
import secondMate from "../../../assets/Ranks/Second Mate.png";
import firstMate from "../../../assets/Ranks/Deckhand.png";
import quarterMaster from "../../../assets/Ranks/Quatremaster.png";
import captain from "../../../assets/Ranks/Captain.png";

const PirateBadges = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-5xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#267e8c] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Pirate Badges
      </h2>
      <p className="text-gray-700 text-base font-gillSans">
        When you start this programme, you will start off as a Deckhand and
        every time you finish a mission you move up the ranks until you become
        Captain of the ship (just like Captain Charlie).
      </p>
      <div className="text-gray-700 text-base font-gillSans">
        <div className="flex mt-4 justify-between pr-6 gap-5 lg:pr-8 flex-wrap">
          <div className="flex items-center flex-col w-28">
            <img
              src={deckhand}
              alt="deckhand"
              className="hover:scale-110 transform transition duration-300 ease-in-out"
            />
            <span className="mt-2 font-gillSans">Deckhand</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={striker}
              alt="striker"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Striker</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={powderMonkey}
              alt="powderMonkey"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Powder Monkey</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={navigator}
              alt="navigator"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Navigator</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={masterAtArms}
              alt="master-at-arms"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Master-at-Arms</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={gunner}
              alt="gunner"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Gunner</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={boatswain}
              alt="boatswain"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Boatswain</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={thirdMate}
              alt="third-mate"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Third Mate</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={secondMate}
              alt="second-mate"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Second Mate</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={firstMate}
              alt="first-mate"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">First Mate</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={quarterMaster}
              alt="quarter-master"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Quarter Master</span>
          </div>
          <div className="flex items-center flex-col w-28">
            <img
              src={captain}
              alt="captain"
              className="hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Captain</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PirateBadges;
