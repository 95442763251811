import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import homeButton from "../../../assets/homeBtn.png";
import sectionsBG from "../../../assets/sectionsBG.png";
import ProgressBar from "../../ProgressBar";
import End from "./End";
import Hurt from "./Hurt";
import Intro from "./Intro";
import PainFace from "./PainFace";
import WhatIDid from "./WhatIDid";
import axios from "axios";

const PainDiary = () => {
  const [section, setSection] = useState(1);
  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  const navigate = useNavigate();
  const location = useLocation();
  const carrier = location.state?.carrier;
  const currentMissionId = carrier.currentMissionId;

  // Define allowed sections based on currentMissionId
  const getAllowedSections = () => {
    if (currentMissionId === 1 || currentMissionId === 2) {
      return [1, 2, 5];
    } else if (currentMissionId >= 3 && currentMissionId <= 7) {
      return [1, 2, 3, 5];
    } else if (currentMissionId >= 7 && currentMissionId <= 12) {
      return [1, 2, 3, 4, 5];
    }
    return []; // Default case if no match found
  };

  const allowedSections = getAllowedSections();
  const isLastPage = section === allowedSections[allowedSections.length - 1];
  const isPreviousDisabled = section === allowedSections[0];
  const totalSections = allowedSections.length;

  const handlePrevious = () => {
    const currentIndex = allowedSections.indexOf(section);
    if (currentIndex > 0) setSection(allowedSections[currentIndex - 1]);
  };

  const handleNext = () => {
    const currentIndex = allowedSections.indexOf(section);
    if (currentIndex < allowedSections.length - 1) {
      setSection(allowedSections[currentIndex + 1]);
    } else {
      navigate("/home", { state: { carrier } });
    }
  };

  const handleHomeClick = () => {
    navigate(-1);
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6] fadeIn">
      <div className="flex flex-row gap-5 items-center -mb-9 z-10">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer z-10 w-9 hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <div className="pl-56">
          <ProgressBar
            currentSection={allowedSections.indexOf(section) + 1}
            totalSections={totalSections}
          />
        </div>
      </div>
      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {section === 1 && <Intro />}
            {section === 2 && <Hurt />}
            {section === 3 && <PainFace />}
            {section === 4 && <WhatIDid />}
            {section === 5 && <End />}
          </div>
        </div>
      </div>
      <div className="flex justify-around">
        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            color: "#ffffff",
          }}
        >
          <svg
            className="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            className="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default PainDiary;
