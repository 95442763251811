import React from "react";

const AboutFB = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Who is the Feeling Better ASD programme for?
      </h2>
      <div className="text-base mt-8 font-gillSans">
        <p>
          Feeling Better ASD was developed for children who have experienced
          chronic or recurrent pain for a period of three months or more.
        </p>
        <p className="mt-3">The Feeling Better will focus on:</p>
        <ul className="ml-5">
          <li className=" mt-3 ">
            Providing education for autistic children and their parents
          </li>
          <li className=" mt-3 ">Teaching the skill of communicating pain</li>
          <li className=" mt-3 ">
            Learning skills and techniques such as relaxation, activity pacing,
            distraction skills to help them cope with pain.
          </li>
          <li className=" mt-3 ">
            Focus on the thoughts and feeling associated with pain.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutFB;
