import React, { useState } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import face1 from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../assets/audios/speech/PainDiary/Face6.mp3";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import coin from "../../assets/Icons/Coin.png";

const FeelingQuiz = ({carrier, submitted, setSubmitted}) => {
  const [selectedIndex, setSelectedIndex] = useState(null); // State to keep track of selected image
  const audioArray = [face1, face2, face3, face4, face5, face6];
  const location = useLocation();  
  const userId = carrier?.userId;

  const placeholders = [
    { src: painScale0, label: "0\nNo hurt" },
    { src: painScale2, label: "2\nHurts little bit" },
    { src: painScale4, label: "4\nHurts little more" },
    { src: painScale6, label: "6\nHurts even more" },
    { src: painScale8, label: "8\nHurts whole lot" },
    { src: painScale10, label: "10\nHurts worst" },
  ];

  const handleImageClick = async (index) => {
    setSelectedIndex(index); // Set the selected image index
    sessionStorage.setItem("FeelingBetterQuizCompleted", "true");
    // Play the corresponding audio
    const audio = new Audio(audioArray[index]);
    audio.play();

    // Save the activity with the selected face's value
    setSubmitted(true);
    await saveActivity(index);
  };

  const saveActivity = async (index) => {
    const trimmedLabel = `wong face: ${
      placeholders[index].label.split("\n")[1]
    }`;
    const payload = [
      {
        userId: carrier.userId,
        missionId: "M5",
        pageNum: 7,
        activityId: "M5RecapTask",
        activityValue: trimmedLabel, // Add the trimmed label as the activity value
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      console.log("Response:", response);
      toast.success("Activity saved successfully!");
    } catch (error) {
      console.error("Error saving mission activity:", error);
      toast.error("Error saving activity!");
    }

  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          How are you Feeling?
          <img src={coin} className="w-12 ml-5 heartbeat" alt="coin" />
        </h2>
      </div>

      <div className="text-gray-700 text-base text-left px-5 font-gillSans">
        <p className="mt-4 text-center text-xl">
          Captain Charlie wants to know if you had any pain. <br/> Click on the face
          that shows how you were feeling in the last week.
        </p>

        <h3 className="mt-5 text-2xl font-bold text-center">
          Did you have any pain in the last week?
        </h3>
        <p className="text-center text-xl">
          Remember the green face has no pain and the orange/red faces have
          pain.
        </p>

        <div className="flex flex-col items-center mt-8">
          <div className="text-xl font-bold mb-5">
            Wong-Baker FACES Pain Rating Scale
          </div>

          <div className="flex justify-around items-center gap-5 w-full max-w-3xl">
            {placeholders.map((placeholder, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={placeholder.src}
                  alt={`pain-scale-${index}`}
                  className={`transform transition duration-300 ease-in-out cursor-pointer ${
                    selectedIndex === index
                      ? "scale-110 border-4 rounded-full border-green-500"
                      : "hover:scale-110"
                  }`}
                  onClick={() => handleImageClick(index)}
                />
                <span className="mt-2 text-center font-gillSans whitespace-pre-line">
                  {placeholder.label}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default FeelingQuiz;
