import React from "react";
import MissionComplete from "../MissionComplete";

const Mission3Complete = ({ carrier }) => {
  const completedMissionId = 3;
  const rank = "Powder Monkey";
  const coins = 26;
  const userId = carrier.userId;
  const currentMissionId = 4;
  const currentMissionPage = 44;
  return (
    <>
      <MissionComplete
        completedMissionId={completedMissionId}
        rank={rank}
        userId={userId}
        coins={coins}
        currentMissionId={currentMissionId}
        currentMissionPage={currentMissionPage}       
      />
    </>
  );
};

export default Mission3Complete;
