import React, { useEffect, useState } from "react";

const AnimatedAttemptsTracker = ({ incorrectAttempts, maxAttempts = 3 }) => {
  const remainingAttempts = maxAttempts - incorrectAttempts;
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (incorrectAttempts > 0) {
      setIsAnimating(true);
      const timer = setTimeout(() => setIsAnimating(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [incorrectAttempts]);

  const getColor = () => {
    if (remainingAttempts === maxAttempts) return "bg-green-500";
    if (remainingAttempts === 1) return "bg-red-500";
    return "bg-yellow-500";
  };

  return (
    <div className="text-center mt-4">
      {/* Remaining attempts display */}
      <div className={`text-lg font-bold ${getColor()} p-2 rounded-lg transition-all duration-500 ease-in-out ${isAnimating ? "scale-110" : "scale-100"}`}>
        Attempts left: {remainingAttempts} / {maxAttempts}
      </div>

      {/* Circles indicating remaining attempts */}
      <div className="mt-4 flex justify-center space-x-4">
        {[...Array(maxAttempts)].map((_, index) => (
          <div
            key={index}
            className={`w-8 h-8 rounded-full transition-all duration-300 ease-in-out ${
              index < remainingAttempts ? getColor() : "bg-gray-300"
            } ${isAnimating && index === remainingAttempts - 1 ? "animate-pulse" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};

export default AnimatedAttemptsTracker;
