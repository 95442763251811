import React from "react";
import coins from "../../assets/audios/coins.mp3";
import coin from "../../assets/Icons/Coin.png";
import wongBakerPainScale from "../../assets/pain scale/Pain scale.png";
import painScaleChampionBadge from "../../assets/Badges/M5Recap.png";

const Recap = () => {

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      {/* Title Section */}
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-4xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Recap
          <img src={coin} className="w-8 ml-3 heartbeat" alt="coin" />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-row justify-between items-start px-5 font-gillSans text-xl mt-5">
        {/* Left Side: Text */}
        <div className="text-gray-700 text-left w-2/3">
          <p>
            In the last mission you learned about using a pain scale when you are in pain. And you earned your{" "}
            <span className="font-semibold">Pain Scale Champion Badge</span>.
          </p>
          <p className="mt-5">
            You learned that a pain scale is a way to show how much a body part hurts.
          </p>
          <p className="mt-5">
            When a body part hurts, you can point to the face that shows how much pain you have.
          </p>
        </div>

        {/* Right Side: Images */}
        <div className="w-2/3 flex flex-col items-center">
          <img
            src={painScaleChampionBadge}
            alt="Pain Scale Champion Badge"
            className="w-40 h-auto mb-5"
          />
          <img
            src={wongBakerPainScale}
            alt="Wong-Baker Pain Rating Scale"
            className="w-auto h-40"
          />
        </div>
      </div>
    </div>
  );
};

export default Recap;
