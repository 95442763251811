import React, { useRef } from "react";
import mostPain from "../../assets/pain scale/10.png";
import painScale10 from "../../assets/audios/painScale10.mp3";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const MostPain = () => {
  const audioRef = useRef(null);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Most Pain
          <img src={coin} className="w-12 ml-5 heartbeat" alt="coin" />
        </h2>
      </div>
      <div className="text-gray-700 text-base flex flex-row font-gillSans">
        <div className=" flex flex-col ">
          <p className="mt-10 lg:text-xl">
            This face shows that a body part <br/>hurts a lot. This means that
            it is the worst pain.
          </p>
          <p className="mt-10 lg:text-xl">
            Press play to hear that there is a lot of pain.
          </p>
          <button
            onClick={handlePlayClick}
            className="px-8 py-2 mt-10 rounded-md flex-row w-32 bg-green-600 text-white font-bold transition duration-200 hover:bg-green-500 border-2 border-transparent"
          >
            Play
          </button>
        </div>
        <div className=" mt-12 ml-12">
          <img src={mostPain} alt="cptCharlie" className="w-56 mr-11" />
        </div>
      </div>
      <audio ref={audioRef} src={painScale10} />
    </div>
  );
};

export default MostPain;
