import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PainDiaryDash = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [painDiary, setPainDiary] = useState([]);

  useEffect(() => {
    // Fetch users on component mount
    axios.get(`${process.env.REACT_APP_API_BASE_URL}users/getAllUserIdsAndUserNames`)
      .then(response => {
        const fetchedUsers = Array.isArray(response.data.data) ? response.data.data : [];
        setUsers(fetchedUsers);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUser(userId);

    if (userId) {
      // Fetch pain diary data for the selected user
      axios.get(`${process.env.REACT_APP_API_BASE_URL}painDiary/${userId}`)
        .then(response => {
          const diaryEntries = response.data && Array.isArray(response.data.data) ? response.data.data : [];
          setPainDiary(diaryEntries);
        })
        .catch(error => {
          console.error('Error fetching pain diary data:', error);
        });
    } else {
      setPainDiary([]);
    }
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <header style={{ marginBottom: '20px' }}>
        <h1 style={{ textAlign: 'center' }}>Pain Diary Dashboard</h1>
      </header>

      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <label htmlFor="user-select" style={{ marginRight: '10px' }}>Select User:</label>
        <select
          id="user-select"
          value={selectedUser}
          onChange={handleUserChange}
          style={{ padding: '5px', fontSize: '16px' }}
        >
          <option value="">--Select User--</option>
          {users.map(user => (
            <option key={user.userId} value={user.userId}>
              {user.userName}
            </option>
          ))}
        </select>
      </div>

      {painDiary.length > 0 && (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '2px solid black' }}>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1' }}>
              <tr>
                <th style={{ border: '2px solid black', padding: '8px' }}>ID</th>
                <th style={{ border: '2px solid black', padding: '8px' }}>Body Part</th>
                <th style={{ border: '2px solid black', padding: '8px' }}>Time</th>
              </tr>
            </thead>
            <tbody>
              {painDiary.map(entry => (
                <tr key={entry.diaryId}>
                  <td style={{ border: '2px solid black', padding: '8px', textAlign: 'center' }}>{entry.diaryId}</td>
                  <td style={{ border: '2px solid black', padding: '8px', textAlign: 'center' }}>{entry.bodyPart}</td>
                  <td style={{ border: '2px solid black', padding: '8px', textAlign: 'center' }}>{entry.diaryEntryTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PainDiaryDash;
