import React from "react";
import painScale from "../../assets/pain scale/Pain scale.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Section4 = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Pain Scale
          <img src={coin} className="w-12 ml-5 heartbeat" alt="coin" />
        </h2>
      </div>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl">
          <li className="mt-2">The special skill is called a 'Pain Scale'.</li>
          <li className="mt-2">This is a pain scale-</li>
          <img src={painScale} alt="painScale" className=" w-[30rem] ml-24 mt-3" />
          <li className="mt-3">
            Using the pain scale is very important. All of Captain Charlie's
            crew know how to use the pain scale.
          </li>
          <li className="mt-3">
            Using the pain scale is a very important skill in becoming a pirate.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Section4;
