

const Incorrect = ({ handleSpeech, handlePrevious }) => {
  

  const handleGoBack = () => {
    // Call the handlePrevious function passed as a prop
    handlePrevious();
  };
  return (
    <div className="flex max-w-lg flex-col shake">
      <h4 className="text-3xl mt-5 text-red-500">Incorrect Answer!</h4>
      <ol className="text-xl mt-5">
        <li>Nice Try!</li>
      </ol>
      <button
        className="px-4 py-2 mt-4 w-32 bg-red-800 text-white rounded-md hover:bg-red-600"
        onClick={handleGoBack}
      >
        Try Again
      </button>
    </div>
  );
};

export default Incorrect;
