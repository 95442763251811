import React, { useState,useEffect } from "react";
import QuestionOne from "./QuestionOne";
import QuestionTwo from "./QuestionTwo";
import QuestionThree from "./QuestionThree";
import coins from "../../assets/audios/coins.mp3";
import coin from "../../assets/Icons/Coin.png";

const PirateQuiz = (carrier) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  
  const handleNextQuestion = () => {
    setCurrentQuestion((prev) => prev + 1);
  };

  const audioPlay = () => {    
    const audio = new Audio(coins);
    audio.play();
  };

  audioPlay();

  return (
    <div className="p-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">
        Pirate Quiz
        <img src={coin} alt="coin" className="w-12 h-auto inline-block ml-5 heartbeat" />
      </h2>
      {currentQuestion === 1 && <QuestionOne onNext={handleNextQuestion} />}
      {currentQuestion === 2 && <QuestionTwo onNext={handleNextQuestion} />}
      {currentQuestion === 3 && <QuestionThree carrier={carrier} />}
    </div>
  );
};

export default PirateQuiz;
