import React, { useState } from "react";
import cptCharlie from "../../assets/cptCharlie.png";

const PainScaleIntro = ({ onNext }) => {
  // State to track if the user is ready to start
  const [ready, setReady] = useState(false);

  // Function to handle click on the start button
  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="text-gray-700 text-base flex flex-row font-gillSans">
        <div>
          <p className="mt-10 lg:text-lg">
            Captain Charlie wants you to know another very special way to show
            your mum/dad or teacher when you have pain or a body part hurts.
          </p>
          <p className="mt-10 lg:text-lg">
            Do you want to find out about this special skill?
          </p>
          {/* Render different content based on whether the user is ready or not */}
          {ready ? (
            <p className="mt-10 lg:text-lg font-gillSans">
              Your mission has begun!
            </p>
          ) : (
            <button
              onClick={handleStart}
              className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-[#6BBF59] transition duration-300 ease-out border-2 border-black rounded-full shadow-md group mt-8 ml-20"
            >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#3FA7D6] group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-gray-700 transition-all duration-300 transform group-hover:translate-x-full ease">
                Yes, Let's go!
              </span>
              <span className="relative invisible">Yes, Let's Find Out!</span>
            </button>
          )}
        </div>
        <div>
          <img src={cptCharlie} alt="cptCharlie" className=" w-9/12" />
        </div>
      </div>
    </div>
  );
};

export default PainScaleIntro;
