import React, {useEffect} from "react";
import drReily from "../../assets/Characters/Riley.png";
import medicineImage from "../../assets/pain diary/medicines.png";
import pg27 from "../../assets/audios/speech/Mission6/pg27.mp3";

const Skill2Page5 = ({ onNext }) => {
  useEffect(() => {    
    const audio = new Audio(pg27);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="flex flex-col items-center">
      <h2 className="text-3xl font-gillSans font-bold mb-4">Remember...</h2>
      <p className="text-xl mb-2">
        When we are in pain, we might go to the doctors to help us feel better.
      </p>
      <p className="text-xl mb-2">
        Doctors can ask you{" "}
        <span className="font-bold">'How you are feeling'</span> to help you
        feel better.
      </p>
      <img src={drReily} alt="Dr. Riley" className="w-32 h-auto" />
      <p className="text-xl mb-2">
        Doctors can also give you some magic medicine to help you feel better.
      </p>
      <img src={medicineImage} alt="Medicine" className="w-32 h-auto" />
      <button
        onClick={onNext}
        className="mt-6 px-8 py-2 bg-green-600 text-white text-xl rounded"
      >
        Next
      </button>
    </div>
  );
};

export default Skill2Page5;
