import React from "react";
import drReily from "../../assets/Characters/Riley.png"; 
import pirateHat from "../../assets/miscellaneous/pirateHat.png"; 

const MeetDoctorRiley = ({carrier, onnext}) => {
  const handlePirateHatClick = () => {
    onnext();
  };

  return (
    <div className="flex flex-col items-center w-full px-5 py-10 lg:py-20 lg:px-20">
      {/* Title */}
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">
        Meet Doctor Riley
      </h1>

      {/* Content Section */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start w-full max-w-4xl">
        {/* Doctor Image */}
        <div className="w-full lg:w-1/2 flex justify-center mb-8 lg:mb-0">
          <img
            src={drReily}
            alt="Dr. Riley"
            className="w-80 h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Text Section */}
        <div className="w-full lg:w-1/2 text-gray-800 text-xl font-sans px-4 lg:px-10 mr-20">
          <p className="mb-4">
            <span className="font-semibold">{carrier.firstName}</span> meet Dr.
            Riley.
          </p>
          <p className="mb-4">
            Captain Charlie wants you to learn a very special skill from Dr.
            Riley.
          </p>
          <p
            className="text-red-600 font-semibold cursor-pointer"
            onClick={handlePirateHatClick}
          >
            Click on the pirate hat to find out this top skill
          </p>

          {/* Pirate Hat with Skill */}
          <div className="flex items-center">
            <img
              src={pirateHat}
              alt="Pirate Hat"
              className="w-2/5 mr-4 cursor-pointer hover:scale-105 hover:rotate-45 transition-transform duration-200"
              onClick={handlePirateHatClick}
            />
            <p className="text-gray-800 font-semibold">
              Top Skill: Learn how to tell a doctor when you are in pain.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetDoctorRiley;
