import React from "react";
import cptCharlie from "../../../assets/cptCharlie.png";

const Missions = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Missions
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans mx-5">
        <div>
          <p className="mt-5 lg:text-lg">
            Each week Captain Charlie will set a new mission for you.
          </p>
          <p className="mt-5 lg:text-lg">
            Your job is to complete the mission.
          </p>
          <p className="mt-5 lg:text-lg">
            There are some special badges to pick up along the way.
          </p>
          <p className="mt-5 lg:text-lg">
            When you have completed Captain Charlie's mission you will awarded a
            new pirate name and ranking in his ship.
          </p>
          <p className="mt-5 lg:text-lg">
            If you complete all of the missions you will be awarded the rank of
            Captain and at the end of the programme. You will receive a special
            prize from Captain Charlie.
          </p>
        </div>
        <div>
          <img src={cptCharlie} alt="Captain Charlie" className=" w-4/5" />
        </div>
      </div>
    </div>
  );
};

export default Missions;
