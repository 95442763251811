import React, { useState, useEffect } from "react";
import stomachPain from "../../assets/pain scale/Point to stomach.png";
import bodyImage from "../../assets/bodyImageResized.png";
import painScale from "../../assets/pain scale/Pain scale.png";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import cptCharlie from "../../assets/cptCharlie.png";
import communicatingPain from "../../assets/videos/Communicating Pain.mp4";

const RecapTask = ({ carrier, handleSpeech, submitted, setSubmitted }) => {
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [result, setResult] = useState(null);
  const [incorrectAttempts, setincorrectAttempts] = useState(0);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  useEffect(() => {
    if (result === "correct") {
      handleSubmit();
      audioPlay();      
    }
  }, [result, carrier]);

  useEffect(() => {
    if (submitted) {
      if (result === "correct") {
        handleSpeech(10.1); // Play correct answer audio when correct
      } else if (result === "incorrect") {
        handleSpeech(10.2); // Play incorrect answer audio when incorrect
      }
    }
  }, [submitted, result, handleSpeech]);

  
  const handleImageClick = (index) => {
    setSelectedIndices((prev) => {
      const newSelection = prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index].slice(0, 3);

      return newSelection;
    });
  };

  const handleSubmit = async () => {
    const correctIndices = [0, 1, 2];
    const isCorrect = correctIndices.every((val) =>
      selectedIndices.includes(val)
    );

    if (isCorrect) {
      setResult("correct");
      sessionStorage.setItem("recapQuizCompleted", "true");
      handleSpeech(10.1);
      await saveActivity(); // Call the API if the answer is correct
    } else {
      setincorrectAttempts((prev) => prev + 1); // Increment counter on incorrect attempt
      setResult("incorrect");
      handleSpeech(10.2);
      // After 3 incorrect attempts, automatically highlight correct indices
      if (incorrectAttempts > 2) {
        // >=2 because we're incrementing attempts right above
        setSelectedIndices(correctIndices); // Highlight the correct indices
        await saveActivity();
      }
    }

    // Reset selections after submission if the attempts are not exhausted
    if (incorrectAttempts < 3) {
      setSelectedIndices([]);
    }

    setSubmitted(true);
    
  };

  const handleTryAgain = () => {
    setSubmitted(false);
    setSelectedIndices([]);
    setResult(null);
  };

  const saveActivity = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M4",
          pageNum: 10,
          activityId: "M4RecapTask",
          activityValue: JSON.stringify({
            question: "Quiz",
            result: "correct",
          }),
        },
      ];
      // console.log("RecapTask.jsx .env = ", process.env.REACT_APP_API_BASE_URL);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const placeholders = [
    {
      src: stomachPain,
      label: "Pointing to the body part that hurts",
      widthClass: "w-32",
    },
    { src: bodyImage, label: "Using the body map", widthClass: "w-20" },
    {
      src: painScale,
      label: "Pointing to different faces to show how much it hurts",
      widthClass: "w-52 mb-12 mt-6",
    },
  ];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Recap on Last Mission
      </h2>
      {/* Conditionally render the quiz or feedback based on the result */}
      {result === null ? (
        <div className="text-gray-700 text-base mt-10 font-gillSans">
          <ol className="text-xl">
            <li className="-mt-5">
              In the last mission you learned about what is pain.
            </li>
            <li className="mt-2">
              You learned different ways to tell your mum/dad or teacher when
              you are in pain.
            </li>
            <li className="mt-2">Can you remember what they were?</li>
            <div className="flex mt-5 px-7 justify-around items-center gap-5 max-w-4xl">
              {placeholders.map((placeholder, index) => (
                <div className="flex items-center flex-col" key={index}>
                  <img
                    src={placeholder.src}
                    alt={`pain-scale-${index}`}
                    className={`hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer max-h-32 ${
                      placeholder.widthClass
                    } ${
                      selectedIndices.includes(index)
                        ? "border-4 border-[#9c6334] rounded"
                        : ""
                    }`}
                    onClick={() => handleImageClick(index)}
                  />
                  <span className="text-center mt-5">{placeholder.label}</span>
                </div>
              ))}
            </div>
            {/* Submit Button */}
            <div className="flex justify-center mt-5">
              <button
                onClick={handleSubmit}
                className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 transition"
              >
                Submit
              </button>
            </div>
          </ol>
        </div>
      ) : result === "correct" ? (
        <div className="flex items-center flex-col bounceIn font-gillSans">
          <div className="flex flex-row items-center justify-center gap-2">
            <h2 className="text-xl font-bold mb-2 text-center text-green-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
              Correct Answer!
            </h2>
            <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
          </div>
          <div className="flex text-base flex-row mt-5">
            <img src={cptCharlie} className="w-56" alt="Captain" />
            <ol className="text-xl">
              <li>Excellent Job Powder Monkey {carrier?.firstName}! </li>
              <li className="mt-3">
                That's correct-
                <ol>
                  <li className="mt-4">
                    <div className="flex flex-row gap-2">
                      <p className="text-center">
                        You can point to the body part that hurts
                      </p>
                      <img src={stomachPain} className="w-20" alt="" />
                    </div>
                  </li>
                  <li className="mt-2">
                    <div className="flex flex-row gap-2">
                      <p className="text-center">Point to the body map</p>
                      <img src={bodyImage} className="w-10" alt="" />
                    </div>
                  </li>
                  <li className="mt-4">
                    <div className="flex flex-row gap-2">
                      <p className="text-center">
                        Point to different faces on the pain scale
                      </p>
                      <img src={painScale} className="w-32" alt="" />
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      ) : (
        <div className="text-gray-700 text-base font-gillSans shake text-center mt-2">
          <h4 className="text-red-600 text-2xl">Incorrect Answer!</h4>
          <ol>
            <li>Let's try again!</li>
            <li className="mt-3">
              Let's watch the video again and find out what is pain.
            </li>
            <li className="mt-3">
              Remember if you need help, click the help button at the top of the
              page.
            </li>
            <li>
              <div className="flex pt-5 justify-center pr-6 gap-5 lg:pr-8">
                <video width="450" controls>
                  <source src={communicatingPain} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </li>
          </ol>
          <div className="flex justify-center">
            <button
              className="px-4 py-2 mt-4 w-32 bg-red-800 text-white rounded-md hover:bg-red-600"
              onClick={handleTryAgain}
            >
              Try Again
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecapTask;
