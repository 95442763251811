import React, {useEffect} from "react";
import drReily from "../../assets/Characters/Riley.png";
import medicineImage from "../../assets/pain diary/medicines.png";
import greenTick from "../../assets/green-tick.png";


const Skill2Page6 = ({ onNext, setSubmitted }) => {
 useEffect(() => {    
  setSubmitted(false);
 }, []);
  return (
    <div className="flex flex-col items-center">
      <h2 className="text-3xl font-gillSans font-bold mb-4">Doctor Tools: How They Help</h2>
      <p className="text-xl mb-2">When you have pain, you might go to the doctors to help you feel better.</p>
      <div className="flex gap-10 mt-6">
        <div className="relative flex flex-col items-center">
          <img src={drReily} alt="Ask Questions" className="w-32 h-auto" />
          <img src={greenTick} alt="Completed" className="absolute -top-2 -right-2 w-18 h-18" />
          <p className="mt-2 font-semibold text-xl">Ask Questions</p>
        </div>
        <div className="relative flex flex-col items-center">
          <img src={medicineImage} alt="Magic Medicine" className="w-32 h-auto" />
          <img src={greenTick} alt="Completed" className="absolute -top-2 -right-2 w-18 h-18" />
          <p className="mt-2 font-semibold text-xl">Magic Medicine</p>
        </div>
      </div>
      <button onClick={onNext} className="mt-6 px-8 py-2 bg-green-600 text-white text-xl rounded-md">Next</button>
    </div>
  );
};

export default Skill2Page6;
