import React, { useState } from "react";
import videoIcon from "../../../assets/Icons/videoIconRed.png";
import Audio1 from "../../../assets/videos/bodyParts.mp4";
import Audio2 from "../../../assets/videos/2. Tummy Breathing_F.mp4";
import Audio3 from "../../../assets/videos/3. Communicating Pain_D2.mp4";
import Audio4 from "../../../assets/videos/3. Communicating Pain_F.mp4";
import Audio5 from "../../../assets/videos/4. Pain Scenarios_F.mp4";
import Audio6 from "../../../assets/videos/4A. Falling off bike.mp4";
import Audio7 from "../../../assets/videos/4B. Eating too much food.mp4";
import Audio8 from "../../../assets/videos/4C. Toothache.mp4";
import Audio9 from "../../../assets/videos/4D. Headache.mp4";
import Audio10 from "../../../assets/videos/5. What is Pain_F.mp4";
import Audio11 from "../../../assets/videos/6. Pain Management_F.mp4";
import Audio12 from "../../../assets/videos/Deep Pressure part 1.mp4";
import Audio13 from "../../../assets/videos/Deep Pressure part 2.mp4";
import Audio14 from "../../../assets/videos/Heat Pack .mp4";
import Audio15 from "../../../assets/videos/Listening to music .mp4";
import Audio16 from "../../../assets/videos/Medication .mp4";
import Audio17 from "../../../assets/videos/Physical Excerise part 1.mp4";
import Audio18 from "../../../assets/videos/Physical excerise part 2.mp4";

// Array of video data
const videoData = [
  { title: "Body parts", src: Audio1 },
  { title: "Tummy breathing", src: Audio2 },
  { title: "Communicating pain 1", src: Audio3 },
  { title: "Communicating pain 2", src: Audio4 },
  { title: "Pain scenarios", src: Audio5 },
  { title: "Falling off bike", src: Audio6 },
  { title: "Eating too much", src: Audio7 },
  { title: "Toothache", src: Audio8 },
  { title: "Headache", src: Audio9 },
  { title: "What is pain", src: Audio10 },
  { title: "Pain management", src: Audio11 },
  { title: "Deep pressure 1", src: Audio12 },
  { title: "Deep pressure 2", src: Audio13 },
  { title: "Heat pack", src: Audio14 },
  { title: "Listening to music", src: Audio15 },
  { title: "Medication", src: Audio16 },
  { title: "Physical Exercise 1", src: Audio17 },
  { title: "Physical Exercise 2", src: Audio18 },
];

const Videos = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  // Function to open the popup
  const openPopup = (video) => {
    setSelectedVideo(video);
  };

  // Function to close the popup
  const closePopup = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="w-80 h-72 text-sm max-w-5xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#267e8c] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Videos
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <div className="flex mt-4 justify-between gap-5 flex-wrap">
          {videoData.map((video, index) => (
            <div key={index} className="flex items-center flex-col w-28">
              <img
                src={videoIcon}
                alt="videoIcon"
                className="hover:scale-110 transform transition duration-300 ease-in-out"
                onClick={() => openPopup(video)}
              />
              <span className="mt-2 text-center font-gillSans">
                {video.title}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Popup Modal */}
      {selectedVideo && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-[#30afb5] p-5 rounded-lg max-w-2xl w-full">
            <button
              className="absolute top-2 right-2 text-white hover:text-gray-700 text-lg"
              onClick={closePopup}
            >
              &times;
            </button>
            <h3 className="text-lg font-bold mb-4 text-center text-white">{selectedVideo.title}</h3>
            <video
              src={selectedVideo.src}
              controls
              className="w-full h-96 rounded-lg"
              autoPlay
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Videos;
