import React from "react";

const Benefits = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        The Benefits on Online Pain Management
      </h2>
      <div className="text-base font-gillSans mx-5">
        <ul>
          <li className=" mt-8 ">
            Cognitive behavioural therapy (CBT) has been shown to be an
            effective treatment for young people and adults who are trying to
            manage long term pain.
          </li>
          <li className=" mt-8 ">
            Feeling Better ASD combines CBT and applications of behavioural
            psychology to support the learning of pain communication and pain
            management.
          </li>
          <li className=" mt-8 ">
            Feeling Better ASD provides education for autistic children by
            teaching core skills including; the skill of communicating pain,
            learning skills and techniques such as relaxation, activity pacing, and
            distraction skills to help them cope with pain and focusing on the
            thoughts and feeling associated with pain.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Benefits;
