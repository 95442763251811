import React, { useState, useEffect } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import face1 from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../assets/audios/speech/PainDiary/Face6.mp3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import pg50Q3 from "../../assets/audios/speech/Mission6/pg50Q3.mp3";

const QuestionThree = (carrier) => {
  const [isCorrect, setIsCorrect] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const audioArray = [face1, face2, face3, face4, face5, face6];

  useEffect(() => {
    const audio = new Audio(pg50Q3);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  const placeholders = [
    { src: painScale0, label: "0\nNo hurt" },
    { src: painScale2, label: "2\nHurts little bit" },
    { src: painScale4, label: "4\nHurts little more" },
    { src: painScale6, label: "6\nHurts even more" },
    { src: painScale8, label: "8\nHurts whole lot" },
    { src: painScale10, label: "10\nHurts worst" },
  ];

  const handleImageClick = async (index) => {
    setShowHint(false);

    // Play the corresponding audio
    const audio = new Audio(audioArray[index]);
    audio.play();

    // Incorrect answer (first 3 faces: index 0, 1, 2)
    // Correct answer (last 3 faces: index 3, 4, 5)
    if (index >= 0 && index <= 5) {
      setSelectedIndex(index);
      setClickedIndex(index);
      if (index >= 3) {
        setIsCorrect("Correct");
      } else {
        setIsCorrect("Incorrect");
        setAttempts(attempts + 1);
      }

      // Hit the API to save activity details
      let userId = carrier.userId;
      const trimmedLabel = `wong face: ${
        placeholders[index].label.split("\n")[1]
      }`;
      try {
        const payload = [
          {
            userId: userId,
            missionId: "M6",
            pageNum: 50,
            activityId: "M6PirateQuizPainScale",
            activityValue: isCorrect + " : " + trimmedLabel,
          },
        ];
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
          payload
        );
        console.log("payload:", payload);
        toast.success("Quiz completed successfully!");
      } catch (error) {
        console.error("Error saving mission activity:", error);
      }
    } else {
      console.error(
        "Index out of range. Please provide an index between 0 and 5."
      );
      return;
    }
  };

  return (
    <div>
      <div className="text-gray-700 text-base">
        <ol className="text-xl">
          <li>Q3. If we have pain, what face can we show the doctor?</li>
          <div className="flex mt-10 justify-around items-center fadeIn gap-5 max-w-3xl">
            <div className="flex items-center flex-col">
              <img
                src={painScale0}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 0
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(0)}
              />
              <span className="mt-2 text-center font-gillSans">
                0 <br />
                No hurt
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale2}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 1
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(1)}
              />
              <span className="mt-2 text-center font-gillSans">
                2 <br />
                Hurts little bit
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale4}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 2
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(2)}
              />
              <span className="mt-2 text-center font-gillSans">
                4 <br />
                Hurts little more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale6}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 3
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(3)}
              />
              <span className="mt-2 text-center font-gillSans">
                6 <br />
                Hurts even more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale8}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 4
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(4)}
              />
              <span className="mt-2 text-center font-gillSans">
                8 <br />
                Hurts whole lot
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale10}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 5
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(5)}
              />
              <span className="mt-2 text-center font-gillSans">
                10 <br />
                Hurts worst
              </span>
            </div>
          </div>
        </ol>
      </div>
      {/* Hint button */}
      <div className="text-center mt-4">
        <button
          className="lg:text-lg px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-400"
          onClick={() => setShowHint(!showHint)}
        >
          Hint
        </button>
      </div>

      {/* Conditionally rendered hint */}
      {(showHint || attempts >= 3) && (
        <div className="lg:text-xl mt-4 text-center text-blue-600 font-gillSans">
          Point to yellow face onwards
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default QuestionThree;
