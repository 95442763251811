import React, { useState, useEffect, useCallback } from "react";
import headImage from "../../assets/head.png";
import stomachImage from "../../assets/stomach.png";
import mouthImage from "../../assets/mouth.png";
import armImage from "../../assets/arm.png";
import footImage from "../../assets/Body Parts/legs.png";
import headAudio from "../../assets/audios/Head.mp3";
import stomachAudio from "../../assets/audios/Tummy.mp3";
import mouthAudio from "../../assets/audios/Mouth.mp3";
import armAudio from "../../assets/audios/Arm.mp3";
import footAudio from "../../assets/audios/Legs.mp3";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const QuickTask = ({ carrier, handleSpeech, submitted, setSubmitted }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [shake, setShake] = useState(false);
  const [attempts, setAttempts] = useState(0); // Track the number of incorrect attempts
  const [totalIncorrectAttempts, setTotalIncorrectAttempts] = useState(0); // Total attempts across questions


  const audioPlay = () => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();
  };

  const questions = [
    {
      id: "head",
      text: "Can you click on the picture that shows head?",
      audio: headAudio,
    },
    {
      id: "stomach",
      text: "Can you click on the picture that shows tummy?",
      audio: stomachAudio,
    },
    {
      id: "foot",
      text: "Can you click on the picture that shows legs?",
      audio: footAudio,
    },
    {
      id: "mouth",
      text: "Can you click on the picture that shows mouth?",
      audio: mouthAudio,
    },
    {
      id: "arm",
      text: "Can you click on the picture that shows arm?",
      audio: armAudio,
    },
  ];

  useEffect(() => {
    if (questionIndex === questions.length) {
      // Hit the API to save mission activity
      sessionStorage.setItem("quickTaskCompleted", "true");
      setSubmitted(true);
      handleSpeech(10.1);
      saveMissionActivity();
      audioPlay();
    }
  }, [questionIndex]);

  const playNextAudio = useCallback(() => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    const nextAudioPath = audioQueue[0];
    const nextAudio = new Audio(nextAudioPath);

    nextAudio.play();
    setAudioQueue((queue) => queue.slice(1));

    nextAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [audioQueue]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      setIsPlaying(true);
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);

  const handleClick = (audioPath) => {
    setAudioQueue((queue) => [...queue, audioPath]);
  };

  const handleImageClick = (id) => {
    if (id === questions[questionIndex]?.id) {
      setIsTransitioning(true);
      setTotalIncorrectAttempts((prev) => prev + attempts);
      setAttempts(0);
      setTimeout(() => {
        setIsTransitioning(false);
        setQuestionIndex((prevIndex) => prevIndex + 1);
      }, 500);
    } else {
      setShake(true);
      setAttempts((prevAttempts) => prevAttempts + 1); // Increment attempts on incorrect answer
      setTimeout(() => setShake(false), 500);
    }
  };
  const userId = carrier.userId;
  const missionId = "M2";
  const pageNum = 10;
  const activityId = "M2QuickTask";
  
  // Create a dynamic activityValue based on attempts
  let activityValue = `Completed with ${totalIncorrectAttempts} incorrect attempt(s)`;
  if (totalIncorrectAttempts === 0) {
    activityValue = "Completed Perfectly";
  } else if (totalIncorrectAttempts > 0 && questionIndex === questions.length) {
    activityValue = `Completed with ${totalIncorrectAttempts} incorrect attempt(s)`;
  }
  
  const saveMissionActivity = async () => {
    try {
      const payload = [
        {
          userId: userId,
          missionId: missionId,
          pageNum: pageNum,
          activityId: activityId,
          activityValue: activityValue,
        },
      ];
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Quick Task
          <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
        </h2>
      </div>
      <ol className="  text-base mb-4 mt-2 lg:text-xl text-gray-700 font-gillSans">
        <li>Hi {carrier?.firstName}, I have a quick task for you.</li>
      </ol>
      <div className="text-gray-700 text-base flex flex-col items-center font-gillSans">
        <p
          className={`lg:text-xl mb-4 transition-opacity duration-500 ${
            isTransitioning ? "opacity-0" : "opacity-100"
          }`}
        >
          {questions[questionIndex]
            ? questions[questionIndex].text
            : "Congratulations! You have completed the quiz!"}
        </p>

        {/* IMAGES  */}
        {questionIndex < questions.length && (
          <div
            className={`flex pt-10 justify-between pr-6 gap-5 lg:pr-8 transition-opacity duration-500 ${
              isTransitioning ? "opacity-0" : "opacity-100"
            }`}
          >
            <div
              className={`flex items-center flex-col ${
                shake ? "shake" : "" || (attempts >= 3 && questions[questionIndex]?.id === "head" ? "bg-yellow-200 rounded-lg" : "")               
              }`}
            >
              <img
                src={headImage}
                alt="head"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  handleClick(headAudio);
                  handleImageClick("head");
                }}
              />
              <span className="mt-2 font-gillSans lg:text-lg">Head</span>
            </div>
            <div
              className={`flex items-center flex-col ${
                shake ? "shake" : "" || (attempts >= 3 && questions[questionIndex]?.id === "stomach" ? "bg-yellow-200 rounded-lg" : "")               
              }`}
            >
              <img
                src={stomachImage}
                alt="stomach"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  handleClick(stomachAudio);
                  handleImageClick("stomach");
                }}
              />
              <span className="mt-2 font-gillSans lg:text-lg">Tummy</span>
            </div>
            <div
              className={`flex items-center flex-col ${
                shake ? "shake" : "" || (attempts >= 3 && questions[questionIndex]?.id === "mouth" ? "bg-yellow-200 rounded-lg" : "")               
              }`}
            >
              <img
                src={mouthImage}
                alt="mouth"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  handleClick(mouthAudio);
                  handleImageClick("mouth");
                }}
              />
              <span className="mt-2 font-gillSans lg:text-lg">Mouth</span>
            </div>
            <div
              className={`flex items-center flex-col ${
                shake ? "shake" : "" || (attempts >= 3 && questions[questionIndex]?.id === "arm" ? "bg-yellow-200 rounded-lg" : "")               
              }`}
            >
              <img
                src={armImage}
                alt="arm"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  handleClick(armAudio);
                  handleImageClick("arm");
                }}
              />
              <span className="mt-2 font-gillSans lg:text-lg">Arm</span>
            </div>
            <div
              className={`flex items-center flex-col ${
                shake ? "shake" : "" || (attempts >= 3 && questions[questionIndex]?.id === "foot" ? "bg-yellow-200 rounded-lg" : "")               
              }`}
            >
              <img
                src={footImage}
                alt="foot"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  handleClick(footAudio);
                  handleImageClick("foot");
                }}
              />
              <span className="mt-2 font-gillSans lg:text-lg">Legs</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickTask;
