import React, { useEffect, useRef } from "react";
import painScale from "../../assets/pain scale/Pain scale.png";
import sailorAmy from "../../assets/Characters/SailorAmy.jpg";
import sailorAmyStory from "../../assets/audios/speech/Mission5/sailorAmyStory.mp3";
import coin from "../../assets/Icons/Coin.png";

const SailorAmyStory = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Automatically play the audio when the component loads
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
        Sailor Amy's Story
        <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
      </h2>
      <div className="flex items-start space-x-6">
        <div className="flex-shrink-0 w-1/3">
          {/* Image of Sailor Amy */}
          <img
            src={sailorAmy}
            alt="Sailor Amy"
            className="ml-10 w-44 h-auto rounded-lg"
          />
          {/* Pain scale image */}
          <img src={painScale} alt="Pain Scale" className="w-96 mt-6 mx-auto" />
        </div>

        {/* Story content */}
        <div className="flex-grow text-left text-gray-700 font-gillSans text-xl mt-5">
          <p>
            Hi, My name is Sailor Amy. I am one of Captain Charlie’s Friends.
            Captain Charlie has set me a task to tell you how to use the pain
            scale when you are in pain.
          </p>
          <p className="mt-4">
            It is <span className="font-bold underline">really easy</span> to
            use the pain scale.
          </p>
          <p className="mt-4">Here are the 4 steps:</p>
          <ol className="list-decimal list-inside space-y-2 mt-2">
            <li>
              <strong>Look at the faces</strong> that shows the different levels
              of pain.
            </li>
            <li>
              <strong>Pick the face</strong> that shows how much pain you have.
            </li>
            <li>
              <strong>Show this to your mum/dad or teacher.</strong>
            </li>
            <li>
              <strong>
                Always tell your mum/dad or teacher when you are feeling any
                pain.
              </strong>
            </li>
          </ol>
        </div>
      </div>
      <audio ref={audioRef} src={sailorAmyStory} />
    </div>
  );
};

export default SailorAmyStory;
