import React, { useEffect, useRef } from "react";
import pointingFaces from "../../assets/videos/PointingToFaces.mp4";
import painScale from "../../assets/pain scale/Pain scale.png";
import pg24 from "../../assets/audios/speech/Mission5/pg24.mp3";

const SpecialTool = ({ onNext }) => {
    const audioRef = useRef(new Audio(pg24));
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.play();
        }

        // Cleanup function to pause and reset audio when the component unmounts
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset to start
            }
        };
    }, [audioRef]);

    
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Special Tool
        </h2>
      </div>

      {/* Two-column layout */}
      <div className="flex justify-between items-start gap-5 mt-5">
        {/* Left side - Text */}
        <div className="w-2/3 text-gray-700 font-gillSans">
          <ol className="text-xl">
            <li className="text-xl mb-6">
              The pain scale is a special tool to show when you are in pain. It
              shows how much something hurts.
            </li>
            <li className="text-xl mb-6">
              Watch the video to learn more about the pain scale.
            </li>
          </ol>
        </div>

        {/* Right side - Video and Pain Scale */}
        <div className="w-2/3 flex flex-col items-center justify-start">
          <video
            src={pointingFaces}
            controls
            className="w-full h-auto mb-4"
            loop={false}
          ></video>
          <img src={painScale} alt="Pain Scale" className="w-full h-auto" />
        </div>
      </div>

      {/* Next Button */}
      <div className="mt-5 flex justify-center">
        <button
          onClick={onNext}
          className="px-6 py-2 text-xl rounded-md bg-green-500 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SpecialTool;
