import React from "react";
import painScale from "../../assets/pain scale/Pain scale.png";

const PSAdventure = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        The Pain Scale Adventure
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl mr-10">
          <li className="mt-4">
            The pain scale is a way to show how much something hurts.
          </li>
          <li className="mt-4">
            It uses different faces to help you show your pain to others.
          </li>
          <li className="mt-4">
            Sometimes your mum/dad or teacher might ask you-{" "}
            <span className=" font-extrabold"> "How are you Feeling?"</span>
          </li>
          <li className="mt-4">
            You can point to the face that shows how much pain you have.
          </li>
          <div className="flex flex-row justify-around mt-4">
            <img src={painScale} alt="painScale" className=" w-96 mt-6" />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default PSAdventure;
