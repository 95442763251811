import React from "react";
import annie from "../../assets/Characters/Annie.png";

const ThankYou = ({ carrier }) => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Thank You for all your help!
      </h2>
      <div className="text-gray-700 mt-8 text-center flex flex-row items-start font-gillSans">
        <div>
          <p className=" mt-10 lg:text-xl">
            {carrier?.firstName}, thank you for all you helping showing me which
            body parts hurt Annie.
          </p>
          <p className=" mt-10 lg:text-xl">
            Annie feels much better because you helped show her what body parts
            hurt.
          </p>
          <p className=" mt-10 lg:text-xl">
            <span className=" text-[#6BBF59] underline">Remember:</span> It is
            important to show when body parts hurt !
          </p>
        </div>
        <div className=" mx-14">
          <img src={annie} alt="annie" className="w-80" />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
