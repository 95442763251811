import React from 'react';
import Captain from "../assets/cptCharlie.png";


const ErrorPage = ({ errorCode = '404', errorMessage = 'Page not found' }) => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-cyan-300 to-cyan-600 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
        <div className="mb-6">
          <img src={Captain} alt="Pirate character" className="mx-auto rounded-full" />
        </div>
        <h1 className="text-6xl font-bold text-red-600 mb-4">{errorCode}</h1>
        <p className="text-xl text-gray-700 mb-6">{errorMessage}</p>
        <p className="text-gray-600 mb-8">Arrr! Looks like we have sailed off the map, Pirate! </p>
       
        <a
          href="/"
          className="inline-block bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Back to Safe Waters
        </a>
      </div>
    </div>
  );
};

export default ErrorPage;