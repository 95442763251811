import React, { useState, useEffect } from "react";
import TreasureChestPage from "./TreasureChestPage";
import Skill1Page1 from "./Skill1Page1";
import Skill1Page2 from "./Skill1Page2";
import Skill2Page1 from "./Skill2Page1";
import Skill2Page2 from "./Skill2Page2";
import Skill2Page3 from "./Skill2Page3";
import Skill2Page4 from "./Skill2Page4";
import Skill2Page5 from "./Skill2Page5";
import Skill2Page6 from "./Skill2Page6";


const HealthSuperheros = ({setIsOnChildComponent, onNext, setSubmitted, audioRef}) => {
  const [currentPage, setCurrentPage] = useState("treasureChest");

  useEffect(() => {
    if(currentPage === "treasureChest"){
      setIsOnChildComponent(true);
    } else if (currentPage === "skill2page6") {
      // Set `setIsOnChildComponent(false)` when on "skill2page6"
      setIsOnChildComponent(false);
    }
  }, [currentPage, setIsOnChildComponent]);  

  const [completedSkills, setCompletedSkills] = useState({
    skill1: false,
    skill2: false,
  });
  const [completedOptions, setCompletedOptions] = useState({
    askQuestions: false,
    magicMedicine: false,
  });

  const completeSkill = (skill) => {    
    setCompletedSkills((prev) => ({ ...prev, [skill]: true }));
    setCurrentPage("treasureChest");
  };

  const markOptionComplete = (option) => {
    setCompletedOptions((prev) => ({ ...prev, [option]: true }));
  };

  return (
    <div className="flex flex-col items-center">
      {currentPage === "treasureChest" && (
        <TreasureChestPage
          completedSkills={completedSkills}
          onSelectSkill={(skill) =>
            setCurrentPage(skill === "skill1" ? "skill1page1" : "skill2page1")
          }
        />
      )}

      {/* Skill 1 Pages */}
      {currentPage === "skill1page1" && (
        <Skill1Page1 onNext={() => setCurrentPage("skill1page2")} setSubmitted={setSubmitted} audioRef={audioRef} />
      )}
      {currentPage === "skill1page2" && (
        <Skill1Page2 onComplete={() => completeSkill("skill1")} />
      )}

      {/* Skill 2 Pages */}
      {currentPage === "skill2page1" && (
        <Skill2Page1 onNext={() => setCurrentPage("skill2page2")} />
      )}
      {currentPage === "skill2page2" && (
        <Skill2Page2
          completedOptions={completedOptions}
          onSelectOption={(option) => {
            if (option === "askQuestions") {
              setCurrentPage("skill2page3");
            } else if (option === "magicMedicine") {
              setCurrentPage("skill2page4");
            }
          }}
        />
      )}
      {currentPage === "skill2page3" && (
        <Skill2Page3
          onNext={() => {
            markOptionComplete("askQuestions");
            setCurrentPage("skill2page2");
          }}
        />
      )}
      {currentPage === "skill2page4" && (
        <Skill2Page4
          onNext={() => {
            markOptionComplete("magicMedicine");
            setCurrentPage("skill2page5");
          }}
        />
      )}
      {currentPage === "skill2page5" && (
        <Skill2Page5 onNext={() => setCurrentPage("skill2page6")} />
      )}
      {currentPage === "skill2page6" && (
        <Skill2Page6 onComplete={() => completeSkill("skill2")} onNext={onNext} setSubmitted={setSubmitted}/>
      )}
     
    </div>
  );
};

export default HealthSuperheros;
