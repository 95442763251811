import React, { useState, useEffect, useCallback } from "react";
import headImage from "../../assets/head.png";
import stomachImage from "../../assets/stomach.png";
import mouthImage from "../../assets/mouth.png";
import armImage from "../../assets/arm.png";
import footImage from "../../assets/Body Parts/legs.png";
import headAudio from "../../assets/audios/Head.mp3";
import stomachAudio from "../../assets/audios/Tummy.mp3";
import mouthAudio from "../../assets/audios/Mouth.mp3";
import armAudio from "../../assets/audios/Arm.mp3";
import footAudio from "../../assets/audios/Legs.mp3";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Section1 = () => {
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const playNextAudio = useCallback(() => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    const nextAudioPath = audioQueue[0];
    const nextAudio = new Audio(nextAudioPath);

    nextAudio.play();
    setAudioQueue((queue) => queue.slice(1));

    nextAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [audioQueue]);

  useEffect(() => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      setIsPlaying(true);
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);

  const handleImageClick = (audioPath) => {
    setAudioQueue((queue) => [...queue, audioPath]);
  };

  return (    
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
        Learn Your Body Parts
        <img src={coin} className="w-12 ml-4 heartbeat" alt="coin" />
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className=" lg:text-xl">
          <li className="mt-4 mb-4">
            Today we are going to learn about your body parts.
          </li>
          <li>The 5 body parts we will learn are:</li>
        </ol>

        {/* IMAGES  */}
        <div className="flex pt-10 justify-between pr-6 gap-5 lg:pr-8">
          <div className="flex items-center flex-col">
            <img
              src={headImage}
              alt="head"
              className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              onClick={() => handleImageClick(headAudio)}
            />
            <span className="mt-2 font-mono lg:text-lg">Head</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={stomachImage}
              alt="stomach"
              className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              onClick={() => handleImageClick(stomachAudio)}
            />
            <span className="mt-2 font-mono lg:text-lg">Tummy</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={mouthImage}
              alt="mouth"
              className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              onClick={() => handleImageClick(mouthAudio)}
            />
            <span className="mt-2 font-mono lg:text-lg">Mouth</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={armImage}
              alt="arm"
              className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              onClick={() => handleImageClick(armAudio)}
            />
            <span className="mt-2 font-mono lg:text-lg">Arm</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={footImage}
              alt="foot"
              className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              onClick={() => handleImageClick(footAudio)}
            />
            <span className="mt-2 font-mono lg:text-lg">Legs</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
