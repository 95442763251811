import React from "react";
import SeeYou from "../SeeYou";

const SeeYou5 = ({ carrier }) => {
  const rank = "Gunner";
  return (
    <>
      <SeeYou rank={rank} carrier={carrier} />
    </>
  );
};

export default SeeYou5;
