import React, { useState, useEffect } from "react";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import whatIsPain from "../../assets/videos/What is pain.mp4";

const PainQuiz = ({ carrier, handleSpeech, submitted, setSubmitted }) => {
  const [selectedOption, setSelectedOption] = useState(null);  
  const [isCorrect, setIsCorrect] = useState(null);
  const [attempts, setAttempts] = useState(0); // Track the number of attempts  

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  if (isCorrect) {
    audioPlay();
    sessionStorage.setItem("painQuizCompleted", "true");
  }

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    setSubmitted(true);
    const correctAnswer = 2;
    const answerIsCorrect = option === correctAnswer;
    setIsCorrect(answerIsCorrect);    

    if (!answerIsCorrect) {
      setAttempts((prevAttempts) => prevAttempts + 1);
    }

    let userId = carrier.userId;
    // Hit the API to save quiz activity
    try {
      const payload = [
        {
          userId: userId,
          missionId: "M1",
          pageNum: 6,
          activityId: "M1PainQuiz",
          activityValue: JSON.stringify({
            selectedOption: option,
            feedback: answerIsCorrect ? "Correct" : "Incorrect",
          }),
        },
      ];
      // console.log("PainQuiz.jsx .env = ",process.env.REACT_APP_API_BASE_URL);
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const handleTryAgain = () => {
    setSubmitted(false);
    setSelectedOption(null);
    setIsCorrect(null);
  };
  useEffect(() => {
    if (submitted) {
      if (selectedOption === 2) {
        handleSpeech(16.1);  // Play correct answer audio when correct
      } else if (selectedOption !== 2) {
        handleSpeech(16.2);  // Play incorrect answer audio when incorrect
      }
    }
  }, [submitted, selectedOption, handleSpeech]);

  if (submitted) {
    return isCorrect ? (
      // Correct Answer Component
      <div className="flex max-w-lg flex-col bounceIn font-gillSans">
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-4xl mt-5 text-center text-green-600">
            Correct Answer!
          </h2>
          <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
        </div>
        <ol className="mt-5 lg:text-xl">
          <li>Excellent Job Striker {carrier?.firstName}</li>
          <li className="mt-2">
            That's correct pain is when a body part hurts.
          </li>
        </ol>
      </div>
    ) : (
      // Incorrect Answer Component
      <div className="flex max-w-lg flex-col shake font-gillSans">
        <h2 className="text-3xl mt-5 text-center text-red-600">
          Incorrect Answer!
        </h2>
        <ol className="mt-3 lg:text-xl">
          <li>Let's Try Again.</li>
          <li className="mt-3">
            Let's watch the video again and find out what is pain.
          </li>
          <li className="mt-3">
            Remember if you need help, click the help button at the top of the
            page.
          </li>
          <li>
            <div className="flex pt-5 justify-center pr-6 gap-5 lg:pr-8">
              <video width="450" controls>
                <source src={whatIsPain} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </li>
        </ol>
        <div className="flex justify-center">
          <button
            className="px-4 py-2 mt-4 w-32 bg-red-800 text-white rounded-md hover:bg-red-600"
            onClick={handleTryAgain}
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Question Time
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <p className="mt-5 underline font-semibold lg:text-2xl text-center">
          What is Pain?
        </p>
        <ol className="list-decimal text-2xl flex justify-center flex-col items-center">
          <li
            className={`mt-5 cursor-pointer hover:scale-110 ${
              attempts >= 3 && 1 === 2
                ? "bg-yellow-200"
                : "hover:text-[#6BBF59]"
            } transform transition duration-300 ease-in-out`}
            onClick={() => handleOptionClick(1)}
          >
            It is when you are laughing
          </li>
          <li
            className={`mt-5 cursor-pointer hover:scale-110 ${
              attempts >= 3 && 2 === 2
                ? "bg-yellow-200"
                : "hover:text-[#6BBF59]"
            } transform transition duration-300 ease-in-out`}
            onClick={() => handleOptionClick(2)}
          >
            Pain is when a body part hurts.
          </li>
          <li
            className={`mt-5 cursor-pointer hover:scale-110 ${
              attempts >= 3 && 3 === 2
                ? "bg-yellow-200"
                : "hover:text-[#6BBF59]"
            } transform transition duration-300 ease-in-out`}
            onClick={() => handleOptionClick(3)}
          >
            It makes you feel happy
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PainQuiz;
