import React, { useState, useEffect, useRef } from 'react';
import pg30Q2 from '../../assets/audios/speech/Mission5/pg30Q2.mp3';

const Q2Component = ({ onCorrect }) => {
  const [selectedOption, setSelectedOption] = useState(null); // Tracks which option was clicked
  const audioRef = useRef(new Audio(pg30Q2));
  
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }

    // Cleanup function to pause and reset audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset to start
      }
    };
  }, [audioRef]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option); // Set the clicked option

    if (option === 'correct') {
      onCorrect(); // Trigger the correct handler if correct
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn text-center">
      <h2 className="text-2xl font-bold mb-4">Q2. What should Annie do to feel better?</h2>
      <ul className="text-lg">
        {/* Correct option */}
        <li
          onClick={() => handleOptionSelect('correct')}
          className={`cursor-pointer p-2 my-2 transition-transform duration-300 ease-in-out transform rounded-lg ${
            selectedOption === 'correct' ? 'bg-yellow-200' : ''
          }`}
        >
          <span className="font-bold">a)</span> Point to the face that shows how she is feeling.
        </li>

        {/* Incorrect option with shake effect */}
        <li
          onClick={() => handleOptionSelect('incorrect')}
          className={`cursor-pointer p-2 my-2 transition-transform duration-300 ease-in-out transform rounded-lg ${
            selectedOption === 'incorrect' ? 'bg-red-200 shake' : ''
          }`}
        >
          <span className="font-bold">b)</span> Keep it a secret and not tell anyone.
        </li>
      </ul>

      {/* CSS for shake effect */}
      <style>{`
        .shake {
          animation: shake 0.5s ease-in-out;
        }

        @keyframes shake {
          0% { transform: translateX(0); }
          25% { transform: translateX(-5px); }
          50% { transform: translateX(5px); }
          75% { transform: translateX(-5px); }
          100% { transform: translateX(0); }
        }
      `}</style>
    </div>
  );
};

export default Q2Component;
