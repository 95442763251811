import React from "react";
import MissionComplete from "../MissionComplete";

const Mission5Complete = ({ carrier }) => {
  const completedMissionId = 5;
  const rank = "Master at Arms";
  const coins = 21;
  const userId = carrier.userId;
  const currentMissionId = 6;
  const currentMissionPage = 44;
 
  return (
    <>
      <MissionComplete
        completedMissionId={completedMissionId}
        rank={rank}
        userId={userId}
        coins={coins}
        currentMissionId={currentMissionId}
        currentMissionPage={currentMissionPage}      
      />
    </>
  );
};

export default Mission5Complete;
