import React, { useEffect, useRef } from "react";
import doctorImage from "../../assets/miscellaneous/doc.png"; 
import painScaleImage from "../../assets/pain scale/Pain scale.png";
import pg27 from "../../assets/audios/speech/Mission5/pg27.mp3";

const NoMorePain = ({ onNext }) => {

  const audioRef = useRef(new Audio(pg27));
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.play();
        }

        // Cleanup function to pause and reset audio when the component unmounts
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset to start
            }
        };
    }, [audioRef]);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      {/* Title */}
      <h2 className="text-2xl font-bold mb-4">No More Pain</h2>

      {/* Text content */}
      <div className="text-lg text-left mb-6">
        <p className="mb-4">
          Using the pain scale helps doctors/nurses understand how to help you and make you feel better.
        </p>
        <p className="mb-4">
          Sometimes your pain may not go away but it won’t hurt as much.
        </p>
      </div>

      {/* Image Container - Place images side by side */}
      <div className="flex justify-center items-center mb-4 gap-10">
        {/* Doctors Image */}
        <img src={doctorImage} alt="Doctors can help" className="w-36 h-auto" />

        {/* Wong-Baker Faces Pain Rating Scale Image */}
        <img src={painScaleImage} alt="Wong-Baker Faces Pain Rating Scale" className="w-64 h-auto" />
      </div>

      {/* Next Button */}
      <div className="mt-4">
        <button
          onClick={onNext}
          className="px-6 py-2 text-xl rounded-md bg-green-500 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default NoMorePain;
