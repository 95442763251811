import React, { useEffect } from "react";
import Captain from "../../assets/cptCharlie.png";
import headacheVideo from "../../assets/videos/4D. Headache.mp4";
import pg46 from "../../assets/audios/speech/Mission6/pg46.mp3";

const TryAgain2 = ({ onTryAgain }) => {
  useEffect(() => {    
    const audio = new Audio(pg46);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-80 h-72 text-sm max-w-4xl flex flex-col items-center bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#991b1b] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Incorrect Answer
      </h2>
      <div className="flex justify-evenly items-center">
        <img src={Captain} className="w-72" alt="Captain" />
        <div className="text-gray-700 text-base lg:text-xl font-gillSans">
          <p>Let’s Try Again.</p>
          <p className="mt-4">
            Let's watch the video again and find out how Annie is feeling.
          </p>
          <video
            src={headacheVideo}
            alt="Headache"
            controls
            loop={false}
            className="w-60 mt-5"
          />
          <p className="mt-4">
            Remember if you need help, click the help button at the top of the
            page.
          </p>
        </div>        
      </div>
      <button
        className="px-4 py-2 mt-4 w-32 bg-red-800 items-center text-xl text-white rounded-md hover:bg-red-600"
        onClick={onTryAgain}
      >
        Try Again
      </button>
    </div>
  );
};

export default TryAgain2;
