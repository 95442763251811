import React from "react";
import pdf from "../../../assets/Icons/pdf.png";
import painPassportPdf from "../../../assets/PDF/painPassport.pdf";

const PainPassport = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-5xl bg-transparent md:w-[90%] lg:h-96 xl:h-1/2 fadeIn">
      <div>
        <h2 className="text-xl font-bold text-center text-[#267e8c] md:text-2xl lg:text-4xl font-gillSans">
          Pain Passport
        </h2>
        <div className="text-gray-700 flex justify-center text-base mt-10 font-gillSans">
          <div className="flex mt-4 justify-between gap-5 flex-wrap">
            <a
              href={painPassportPdf}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center flex-col w-28 cursor-pointer hover:scale-110 transform transition duration-300 ease-in-out"
            >
              <img src={pdf} alt="pdf" />
              <span className="mt-2 font-gillSans">Pain Passport</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainPassport;
