import React, {useEffect} from "react";
import drReily from "../../assets/Characters/Riley.png";
import medicineImage from "../../assets/pain diary/medicines.png";
import greenTick from "../../assets/green-tick-lime.png";
import pg23 from "../../assets/audios/speech/Mission6/pg23.mp3";
const Skill2Page2 = ({ completedOptions, onSelectOption }) => {
  useEffect(() => {    
    const audio = new Audio(pg23);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0 overflow-auto">
      <div className="flex flex-col items-center font-gillSans w-full max-w-4xl rounded-lg p-5 lg:p-10 gap-8">
        {/* Title and Instructions */}
        <h2 className="text-3xl font-bold mb-2">Doctor Tools: How They Help</h2>
        <p className="text-xl mb-2">
          When you have pain, you might go to the doctor to help you feel
          better.
        </p>
        <p className="lg:text-xl bg-green-500 p-4 rounded-lg">
          Click into each of the two pictures below to find out how the Doctor{" "}
          <br />
          can help you when you have pain.
        </p>

        {/* Options Section */}
        <div className="flex gap-10">
          {/* Ask Questions Option */}
          <div
            className="relative cursor-pointer"
            onClick={() => onSelectOption("askQuestions")}
          >
            
            <img
              src={drReily}
              alt="Ask Questions"
              className={`w-44 h-44 hover:scale-105 transition-transform duration-200 ${
                completedOptions.askQuestions ? "grayscale" : "border-4 border-[#9c6334] rounded p-1"
              }`}
            />
            {completedOptions.askQuestions && (
              <img
                src={greenTick}
                alt="Completed"
                className="absolute -top-2 -right-2 w-26 h-auto"
              />
            )}
            <p className="mt-2 text-xl font-semibold">Ask Questions</p>
          </div>

          {/* Magic Medicine Option */}
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => onSelectOption("magicMedicine")}
          >
            <img
              src={medicineImage}
              alt="Magic Medicine"
              className={`w-44 h-44 hover:scale-105 transition-transform duration-200 ${
                completedOptions.askQuestions ? "border-4 border-[#9c6334] rounded p-1" : ""
              }`}
            />
            {completedOptions.magicMedicine && (
              <img
                src={greenTick}
                alt="Completed"
                className="absolute -top-2 -right-2 w-26 h-auto"
              />
            )}
            <p className="mt-2 text-xl font-semibold">Magic Medicine</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skill2Page2;
