import React, { useState, useEffect } from "react";
import BodyMap from "../BodyMapNew";
import bikeFall from "../../assets/videos/Falling off a bike.mp4";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import cptCharlie from "../../assets/cptCharlie.png";
import axios from "axios";

const BonusQuestion = ({ carrier, handleSpeech, submitted, setSubmitted }) => {
  const [answer, setAnswer] = useState(null);
  const [feedback, setFeedback] = useState("");

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  const handleSubmit = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M4",
          pageNum: 28,
          activityId: "M4BonusQuestion",
          activityValue: JSON.stringify({
            question: "Quiz",
            result: "correct",
          }),
        },
      ];
      // console.log("BonusQuestion.jsx .env = ", process.env.REACT_APP_API_BASE_URL);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      // console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };
  const handleAreaClick = (areaTitle) => {
    if (areaTitle === "Legs") {
      setAnswer("correct");
      setFeedback("Correct Answer! Excellent Job, Keep up the hard work.");
      audioPlay();
      handleSubmit();
      sessionStorage.setItem("bonusQuizCompleted", "true");
    } else {
      setAnswer("incorrect");
      setFeedback("");
    }
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      if (answer === "correct") {
        handleSpeech(28.1);  // Play correct answer audio when correct
      } else {
        handleSpeech(28.2);  // Play incorrect answer audio when incorrect
      }
    }
  }, [submitted, answer, handleSpeech]);

  const handleTry = () => {
    setAnswer(null);
    setFeedback("");
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Bonus Question
      </h2>
      <div className="flex justify-evenly text-gray-700 text-base font-gillSans">
        {answer === null ? (
          <ol className="lg:text-xl mt-5">
            <li>What body part hurts Annie?</li>
            <li className="mt-2">Select the body part on the body map-</li>
            <li className="mt-2">If you need to watch the video again-</li>
            <li className="mt-2">
              <div className="  flex lg:mt-5 md:mt-2 justify-center pr-6 gap-5 lg:pr-8">
                <video className="lg:w-96 md:w-80" controls>
                  <source src={bikeFall} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </li>
          </ol>
        ) : answer === "correct" ? (
          <div className="flex max-w-lg flex-col bounceIn font-gillSans">
            <div className="flex flex-row items-center justify-center gap-2">
              <h2 className="text-xl font-bold mb-2 text-center text-green-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
                Correct Answer!
              </h2>
              <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
            </div>
            <div className="flex text-base flex-row mt-10">
              <img src={cptCharlie} className="w-56" alt="Captain" />
              <ol className="lg:text-xl mt-3">
                <li>Excellent Job Powder Monkey {carrier?.firstName}!</li>
                <li className="mt-3">That's correct!</li>
                <li className="mt-3">You have received a bonus coin!</li>
              </ol>
            </div>
          </div>
        ) : (
          <div className="flex max-w-lg flex-col shake font-gillSans">
            <h2 className="text-3xl mt-5 text-center text-red-600">
              Incorrect Answer!
            </h2>
            <div className="flex text-base flex-row mt-10">
              <img src={cptCharlie} className="w-56" alt="Captain" />
              <div className="flex flex-col">
                <ol className="lg:text-xl mt-3">
                  <li>Let's Try Again.</li>
                  <li className="mt-3">
                    Let's watch the video again to find out how Annie is
                    feeling.
                  </li>
                  <li className="mt-3 ml-20">
                    <div className="flex justify-center">
                      <button
                        className="px-4 py-2 mt-4 w-32 bg-red-800 text-white rounded-md hover:bg-red-600"
                        onClick={handleTry}
                      >
                        Try Again
                      </button>
                    </div>
                  </li>
                  <li className="mt-3">
                    Remember if you need help, click the help button at the top
                    of the page.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        )}
        <BodyMap onAreaClick={handleAreaClick} />
      </div>
      {feedback && (
        <div className="text-center text-gray-700 text-2xl animate-zoomIn font-gillSans"></div>
      )}
    </div>
  );
};

export default BonusQuestion;
